import yup from './yup-extended';
import { DATE_FORMAT } from '../../config';
import i18n from '../i18n/i18n';
import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';

const requiredMessage = i18n.t('validation:required');
const requiredSelectMessage = i18n.t('validation:requiredSelect');
const lettersOnlyMessage = i18n.t('validation:lettersOnly');
const zipCodeMessage = i18n.t('validation:zipCode');

const alphanumericRegExStartingLetter = /^\p{L}[_.\p{L}0-9\sß|\\/()`´'-]*$/u;
const alphanumericRegEx = /^[_.\p{L}0-9\sß|\\/()`´'-]*$/u;
const lettersOnlyRegEx = /^[_.\p{L}\s'-]*$/u;

import zipCodeLists from './zipCodeByStates.json';

const zipCodes = Object.entries(zipCodeLists).reduce((acc, [key, value]) => {
  acc[key] = new Set(value);
  return acc;
}, {});

export const BaseAddressFieldsValidatorMixin = {
  addressType: yup.mixed(),
  street: yup
    .string()
    .required(requiredMessage)
    .min(2, i18n.t('validation:minLength', { value: 2 }))
    .max(60, i18n.t('validation:maxLength', { value: 60 }))
    .matches(alphanumericRegExStartingLetter, lettersOnlyMessage),
  houseNumber: yup
    .string()
    .required(requiredMessage)
    .min(1, i18n.t('validation:minLength', { value: 1 }))
    .max(10, i18n.t('validation:maxLength', { value: 10 }))
    .matches(/^[\p{L}\d\\/-]*$/u, lettersOnlyMessage),
  optionalLine: yup
    .string()
    .transform((val) => val || null)
    .nullable()
    .max(10, i18n.t('validation:maxLength', { value: 10 }))
    .matches(alphanumericRegEx, lettersOnlyMessage)
    .matches(/[\d\p{L}][\d\p{L}\s]*/u, lettersOnlyMessage), // at least one number or letter
  zipCode: yup
    .string()
    .required(requiredMessage)
    .min(4, i18n.t('validation:minLength', { value: 4 }))
    .max(10, i18n.t('validation:maxLength', { value: 4 }))
    .matches(/^[a-zA-Z-\d ]+$/, zipCodeMessage)
    .test(
      'zipCode',
      zipCodeMessage,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      function checkZipCodeByState(value: string | undefined, ..._: any) {
        const state = this.parent?.state;
        if (!state) {
          return true;
        }
        return value?.length === 4 && zipCodes[state].has(value);
      }
    ),
  city: yup
    .string()
    .required(requiredMessage)
    .min(2, i18n.t('validation:minLength', { value: 2 }))
    .max(35, i18n.t('validation:maxLength', { value: 35 }))
    .matches(lettersOnlyRegEx, lettersOnlyMessage),
  state: yup.string().required(requiredSelectMessage),
};
export const BaseAddressFieldsetValidatorMixinWResidentSince = {
  ...BaseAddressFieldsValidatorMixin,
  residentSinceDate: yup
    .string()
    .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
    .test(
      'required',
      requiredMessage,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      function checkFirst(value: string | undefined, ..._: any) {
        return !this.path.includes('addresses[0]') || !!value;
      }
    ),
  addressType: yup.string().required(requiredMessage),
};

export const BaseAddressFieldsetValidatorMixinWCountrySelection = {
  ...BaseAddressFieldsValidatorMixin,
  countryCode: yup.string().required(requiredSelectMessage),
  state: yup.mixed().when('countryCode', {
    is: CountryCode.Australia,
    then: () => yup.string().required(requiredSelectMessage),
    otherwise: () => yup.mixed().notRequired(),
  }),
};
