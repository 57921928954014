import { ApplicationState } from '../../ApplicationState';
import { Session, UserType } from './session.model';

export const getSession: (state: ApplicationState) => Session | null = (state) => state.session;

export const getLoggedInUserType = (state: ApplicationState): UserType => {
  return state.session?.userType || UserType.ANONYMOUS;
};

export const getIsCoApplicantFlow = (state: ApplicationState): boolean => {
  return getLoggedInUserType(state) === UserType.CO_APPLICANT;
};
