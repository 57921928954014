import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { DatePicker, Input, Heading, Paragraph, Select } from '@vwfs-bronson/bronson-react';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { DATE_FORMAT } from '../../../../../config';
import { analytics } from '../../../../../services/analytics/analytics';
import { saveCompanyInformation } from '../../../../../services/redux/reducers/FormsData/formsData.actions';
import {
  EntityLegalForm,
  IndustryClassification,
  FormDataSection,
  CompanyInformation,
} from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { getEntityLegalForm } from '../../../../../services/redux/reducers/FormsData/formsData.selectors';
import { CompanyDataFormValidationFactory } from '../../../../../services/validators/CompanyDataForm.validation';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import ContinueBtn from '../../../ContinueBtn';
import { EmptyOption, numberInputProps } from '../../../formUtils';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';
import { BaseContactInformationFieldset } from '../Mixins/BaseContactInformationFieldset';
import { mobileCountryCodeEntries } from '../../../../../services/utils';

interface Props {
  formData: CompanyInformation;
  setFormData: (formData: CompanyInformation) => void;
  entityLegalForm: EntityLegalForm;
}

const CompanyDataComponent = (props: Props) => {
  const { formData, setFormData, entityLegalForm } = props;
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const dateInputs = document.querySelectorAll('.c-datepicker input');
      if (dateInputs?.length) {
        for (const dateInput of dateInputs) {
          dateInput.setAttribute('autocomplete', 'no');
        }
      }
    }, 1_000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const countryCodeOptions = Object.keys(CountryCode).map((key) => ({
    value: (CountryCode as any)[key],
    viewValue: key,
  }));
  const industryOptions = Object.values(IndustryClassification)
    .map((industryClass) => ({
      value: industryClass,
      viewValue: t(`formsData:formSections:companyInformation:fields:industry:options:${industryClass}`),
    }))
    .sort((a, b) => (a.viewValue > b.viewValue ? 1 : -1));

  const isOwnershipVisible = (entityLegalForm: EntityLegalForm) => entityLegalForm === EntityLegalForm.COMPANY;

  return (
    <>
      {!isReadonly && <Paragraph>{t('formsData:formSections:companyInformation:formHint1')}</Paragraph>}
      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={CompanyDataFormValidationFactory(entityLegalForm)}
        onSubmit={(values) => {
          setFormData(values);
        }}
      >
        {(formikProps: FormikProps<CompanyInformation>) => {
          const { setFieldValue, handleBlur } = formikProps;
          return (
            <Form noValidate>
              <Fieldset>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name="companyData.name"
                      type="input"
                      labelText={t('formsData:formSections:companyInformation:fields:businessName:label')}
                      notion
                      render={(fieldProps) => <Input {...fieldProps} />}
                    />
                  </Layout.Item>
                  {isOwnershipVisible(entityLegalForm) && (
                    <Layout.Item default="1/2" s="1/1">
                      <FormField
                        {...numberInputProps}
                        name="companyData.ownership"
                        type="input"
                        labelText={t('formsData:formSections:companyInformation:fields:ownership:label')}
                        notion
                        render={(fieldProps) => (
                          <Input {...fieldProps} {...numberInputProps} min={1} max={100} type="number" />
                        )}
                      />
                    </Layout.Item>
                  )}
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name="companyData.id"
                      type="input"
                      labelText={t('formsData:formSections:companyInformation:fields:companyId:label')}
                      notion={true}
                      render={(fieldProps) => <Input {...fieldProps} />}
                    />
                  </Layout.Item>
                </Layout>
              </Fieldset>
              <Fieldset>
                <Layout>
                  <Layout.Item default="1/3">
                    <FormField
                      name={`companyData.contactData.mobilePhoneCountryCode`}
                      type="select"
                      labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneCountryCode:label')}
                      notion
                      render={(fieldProps) => (
                        <Select {...fieldProps}>
                          <EmptyOption />
                          {mobileCountryCodeEntries.map(([code, label]) => (
                            <Select.Item key={code} value={label}>
                              {code} {label}
                            </Select.Item>
                          ))}
                        </Select>
                      )}
                    />
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name={`companyData.contactData.mobilePhoneNumber`}
                      type="input"
                      labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneNumber:label')}
                      notion
                      render={(fieldProps) => <Input {...fieldProps} />}
                    />
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name={`companyData.contactData.email`}
                      type="input"
                      labelText={t('formsData:formSections:contactInformation:fields:email:label')}
                      notion
                      render={(fieldProps) => <Input {...fieldProps} />}
                    />
                  </Layout.Item>
                </Layout>
              </Fieldset>
              <Fieldset>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name="companyData.industry"
                      type="input"
                      labelText={t('formsData:formSections:companyInformation:fields:industry:label')}
                      getReadonlyValue={(val: string) =>
                        t(`formsData:formSections:companyInformation:fields:industry:options:${val}`)
                      }
                      notion
                      render={(fieldProps) => (
                        <Select {...fieldProps}>
                          <EmptyOption />
                          {industryOptions.map((option) => (
                            <Select.Item key={option.value} value={option.value}>
                              {option.viewValue}
                            </Select.Item>
                          ))}
                        </Select>
                      )}
                    />
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name="companyData.tradeName"
                      type="input"
                      labelText={t('formsData:formSections:companyInformation:fields:tradeName:label')}
                      notion
                      render={(fieldProps) => <Input {...fieldProps} />}
                    />
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name="companyData.established"
                      type="select"
                      labelText={t('formsData:formSections:companyInformation:fields:established:label')}
                      notion
                      render={(fieldProps) => (
                        <Select {...fieldProps}>
                          <EmptyOption />
                          {countryCodeOptions.map((option) => (
                            <Select.Item key={option.value} value={option.value}>
                              {option.viewValue}
                            </Select.Item>
                          ))}
                        </Select>
                      )}
                    />
                  </Layout.Item>
                  <Layout.Item default="1/2" s="1/1">
                    <FormField
                      name="companyData.foundationDate"
                      type="input"
                      labelText={t('formsData:formSections:companyInformation:fields:foundationDate:label')}
                      notion
                      render={(fieldProps) => (
                        <DatePicker
                          {...fieldProps}
                          locale="en"
                          dateFormat={DATE_FORMAT}
                          onChange={(val: string) => {
                            setFieldValue('companyData.foundationDate', val, true);
                          }}
                          onBlur={(val: string) => {
                            setFieldValue('companyData.foundationDate', val);
                            handleBlur('companyData.foundationDate');
                          }}
                        />
                      )}
                    />
                  </Layout.Item>
                </Layout>
              </Fieldset>

              <Heading level={5}>{t('formsData:formSections:companyInformation:addressHint')}</Heading>
              <BaseContactInformationFieldset
                addressNamePrefix="companyData."
                contactDataNamePrefix=""
                formikProps={formikProps}
                isAddressOnly={true}
                noPreviousAddress={true}
              />

              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  formData: state.formsData[FormDataSection.companyInformation],
  entityLegalForm: getEntityLegalForm(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormData: (payload: CompanyInformation) => {
    dispatch(saveCompanyInformation(payload));
    analytics.trackFormSectionOnContinue(FormDataSection.companyInformation);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDataComponent);
