import { differenceInCalendarDays, differenceInYears, isValid, parse } from 'date-fns';
import * as yup from 'yup';
import { DATE_VALIDATION_FORMAT } from '../../config';
import i18n from '../i18n/i18n';

// It should be yup's StringSchema, but right after 1.0.0 it produces TS error.
// See https://github.com/jquense/yup/issues/1899
// TODO: Remove this workaround and set proper type once resolved or any reasonable workaround is found
type StringSchema = yup.Schema; // should be `yup.StringSchema`

yup.addMethod<StringSchema>(yup.string, 'nullableMin', function nullableMin(minLength, message) {
  return this.test('nullableLen', message, function testLen(value) {
    if (value) {
      return value.length >= minLength;
    }
    return true;
  });
});
yup.addMethod<StringSchema>(yup.string, 'dateFormat', function format(format, message) {
  // need to be string for custom error message
  return this.test('dateFormat', message, function testFormat(value) {
    return !value || isValid(parse(value as string, DATE_VALIDATION_FORMAT, new Date()));
  });
});
yup.addMethod<StringSchema>(yup.string, 'forbiddenCharacters', function format(message) {
  // need to be string for custom error message
  return this.test('forbiddenCharacters', message, function testForbiddenCharacters(value) {
    return !value || /^[^=+@]*$/.test(value);
  });
});
yup.addMethod<StringSchema>(yup.string, 'noFutureDate', function format(format, message) {
  // need to be string for custom error message
  return this.test('noFutureDate', message ?? i18n.t('validation:noFutureDate'), function testFormat(value) {
    if (value) {
      const diff = differenceInCalendarDays(new Date(), parse(value, DATE_VALIDATION_FORMAT, new Date()));
      return diff >= 0;
    }
    return true;
  });
});
yup.addMethod<StringSchema>(yup.string, 'futureDate', function testFutureDate(format, message) {
  // need to be string for custom error message
  return this.test('futureDate', message, function testFormat(value) {
    if (value) {
      const diff = differenceInCalendarDays(new Date(), parse(value, DATE_VALIDATION_FORMAT, new Date()));
      return diff < 0;
    }
    return true;
  });
});
yup.addMethod<StringSchema>(yup.string, 'fullAge', function fullAge(years, message) {
  // need to be string for custom error message
  return this.test('fullAge', message, function testAge(value) {
    if (value) {
      return differenceInYears(new Date(), parse(value, 'd-M-yyyy', new Date())) >= years;
    }
    return true;
  });
});
yup.addMethod<StringSchema>(yup.string, 'maxAge', function maxAge(years, message) {
  // need to be string for custom error message
  return this.test('maxAge', message, function testAge(value) {
    if (value) {
      return differenceInYears(new Date(), parse(value, 'd-M-yyyy', new Date())) <= years;
    }
    return true;
  });
});

declare module 'yup' {
  interface StringSchema<TType, TContext> {
    nullableMin(minLength: number, message: string): StringSchema<TType, TContext>;

    dateFormat(dateFormat: string, message: string): StringSchema<TType, TContext>;

    forbiddenCharacters(message?: string): StringSchema<TType, TContext>;

    noFutureDate(dateFormat?: string, message?: string): StringSchema<TType, TContext>;

    futureDate(dateFormat?: string, message?: string): StringSchema<TType, TContext>;

    fullAge(minimumAge: number, message: string): StringSchema<TType, TContext>;
    maxAge(maxAge: number, message: string): StringSchema<TType, TContext>;
  }
}

export default yup;
