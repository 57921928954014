import i18n from '../i18n/i18n';
import { AustralianStateKey } from '../redux/reducers/FormsData/formsData.model';

export const formatCurrency = (val: number | string) => i18n.t('app:currencyFormat', { value: val, currency: '$' });

interface Address {
  street: string;
  houseNumber: string;
  zipCode: string;
  state: AustralianStateKey;
  city: string;
  optionalLine?: string;
}
export const formatAssetAddress = (address: Address) => {
  return `${address.city} ${address.zipCode} ${address.street} ${address.houseNumber}`;
};
