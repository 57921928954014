import * as React from 'react';

import { Main } from '@vwfs-bronson/bronson-react';

interface ComponentProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<ComponentProps> = ({ children }) => {
  return <Main>{children}</Main>;
};

export default DefaultLayout;
