import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { AddressType } from '@bus/models/dist/enums/new/address-type.enum';
import { AssetType } from '@bus/models/dist/enums/new/asset-type.enum';
import { MaritalStatus } from '@bus/models/dist/enums/new/marital-status.enum';
import { Occupation } from '@bus/models/dist/enums/new/occupation.enum';
import { FinancialProductType } from './reducers/FinancialProduct/financialProduct.model';
import {
  AssetCustomType,
  AustralianResidencyStatus,
  AustralianStateKey,
  ContactInformationCurrentAddress,
  CreditType,
  CustomerRole,
  EmploymentStatus,
  EntityLegalForm,
  IdentificationType,
  IndustryClassification,
  LiabilityCustomType,
  PartnerType,
  Salutation,
} from './reducers/FormsData/formsData.model';
import { TransactionStatus } from './reducers/Transaction/transaction.model';
import { VehicleTradeState } from './reducers/VehicleData/vehicleData.model';
import { PhoneCountryCode } from '@bus/models/dist/enums/general/phone-country-code.enum';

export interface DataStorageObject {
  data: DataStorageData;
  id: string;
  creationDate: string;
  updateDate: string;
  realm: string;
}

export interface DataStorageData {
  transaction: Transaction;
  customerData: CustomerData;
  financialProduct: FinancialProduct;
  dealerData?: DealerData;
  vehicleData?: VehicleData;
  custom?: Custom;
  companyData?: CompanyData;
}

interface Transaction {
  id: string;
  country: string;
  status: TransactionStatus;
  product: string;
  brand?: string;
  storefrontCallbackURL?: string;
  multiApplicantJourney?: boolean;

  /** transaction identifier used by the market **/
  CupraID: string;
}

export interface CoApplicantData extends CustomerData {
  role: CustomerRole;
}

interface BaseDocument {
  identityDocumentType: IdentificationType | null;
  identityDocumentId: string;
  expiryDate: string;
}

export interface DriversLicenceDocument extends BaseDocument {
  identityDocumentVerificationNumber: string;
  issuingCountry: CountryCode;
  issuingState?: AustralianStateKey;
}

export interface PassportDocument extends BaseDocument {
  issuingCountry: CountryCode;
}

export type IdentityDocuments = [
  {
    identityDocumentType: AustralianResidencyStatus | null;
  },
  DriversLicenceDocument,
  PassportDocument?
];

export interface TrustInformation {
  role: CustomerRole.TRUST;
  trustAgentType: TrustAgentType;
  name: string;
  contactData: {
    mobilePhoneCountryCode: PhoneCountryCode;
    mobilePhoneNumber: string;
    email: string;
  };
  id: string;
  established: CountryCode;
  foundationDate: string;
  addresses: [ContactInformationCurrentAddress];
}

interface BasePersonalData {
  salutation?: Salutation;
  firstName: string;
  middleNames: string;
  lastName: string;
  dateOfBirth: string;
  contactData: ContactData;
  identityDocuments: IdentityDocuments;
  addresses?: [Address, Address | null, Address | null];
}

interface DataPrivacyStatement {
  idAmlCheckConsentAcceptance: boolean;
  allowAds: boolean;
  dataPrivacyConsentAcceptance: boolean;
  dataPrivacyConsentAcceptanceDate: string;
  personalDataAcceptance: boolean;
}

interface CustomerTransaction {
  userId: string;
  status: TransactionStatus;
}

export interface ReferenceContact {
  role: CustomerRole;
  salutation: Salutation | null;
  firstName: string;
  middleNames?: string;
  lastName: string;
  addresses: [
    {
      street: string;
      houseNumber: string;
      optionalLine: string;
      zipCode: string;
      city: string;
      state: AustralianStateKey;
      addressType: AddressType.PRIMARY;
    }
  ];
  contactData: {
    mobilePhoneCountryCode: PhoneCountryCode;
    mobilePhoneNumber: string;
    email: string;
  };
}

export interface CustomerData extends BasePersonalData {
  transaction: CustomerTransaction;
  /** The relations data format received from FES is different from the one stored in data storage for relations
   * Data storage stores all the relations as an array, FES for convenience returns them as a Role to Relation object map.
   */
  // customerRelations?: Record<CustomerRole, CustomerRelation>;
  customerRelations?: {
    [CustomerRole.CO_APPLICANT]: CoApplicantData;
    [CustomerRole.TRUST]: TrustInformation;
    [CustomerRole.REFERENCE_CONTACT_1]: ReferenceContact;
    [CustomerRole.REFERENCE_CONTACT_2]: ReferenceContact;
  };
  gender?: string;
  addresses: [Address, Address | null, Address | null];
  odsFinancialVerificationChoice?: OdsFinancialVerificationChoice;
  financialDisclosure: FinancialDisclosure;
  employment: Employment<Employer>;
  previousEmployments?: Employment<PreviousEmployer>[];
  partnerType?: PartnerType;
  tradeIn: boolean;
  maritalStatus?: MaritalStatus;
  numberOfDependentChildren: number | string;
  companyData?: CompanyData;
  dataPrivacyStatement?: DataPrivacyStatement;
}

interface FinancialProduct {
  calculation: Calculation;
  disclaimer: string;
  detailedName: FinancialProductType;
  totalAmount?: number;
  discountAmount?: number;
}

interface VehicleData {
  model: Model;
  tradeState: VehicleTradeState;
  retailPriceAmount: number;
}

interface DealerContactData {
  email: string;
  telephoneNumber: string;
  telephoneCountryCode: string;
}

interface DealerData {
  regionId?: string;
  companyId?: string;
  companyName?: string;
  contactData?: DealerContactData;
  addresses?: Address[];
}

interface BaseAddress {
  zipCode: string;
  street: string;
  city: string;
  houseNumber: string;
  residentSinceDate?: string;
  addressType?: AddressType;
  optionalLine?: string;
}

interface Address extends BaseAddress {
  state: string;
}
interface PreviousEmploymentAddress extends BaseAddress {
  countryCode: CountryCode;
  state?: string;
}

interface CompanyData {
  id: string;
  name: string;
  entityLegalForm?: EntityLegalForm | null;
  ownership?: number | null;
  tradeName: string;
  established?: string | null;
  foundationDate: string;
  industry: IndustryClassification;
  addresses: Address[];
}

interface ContactData {
  email: string;
  mobilePhoneNumber: string;
  mobilePhoneCountryCode: string;
}

interface Model {
  name?: string;
  description: string;
  transmissionType: string;
  descriptionLong: string;
  colorExterior: string;
  vehicleImageUrl: string;
  manufacturer?: string;
  bodyType?: string;
  accessories?: VehicleAccessories[];
}

interface VehicleAccessories {
  name: string;
  type: string;
  accessoryPriceAmount?: {
    currency?: string;
    amount?: number;
  };
}

interface Calculation {
  duration: number;
  grossLoanAmount: number;
  balloonPayment: number;
  guaranteedBuyBackPrice: number;
  netLoanAmount: number;
  yearlyMileage: number;
  downPaymentAmount: number;
  subsequentInstallmentAmount: {
    paymentFrequency: 'MONTHLY' | 'WEEKLY';
    amount: number;
    currency: string;
  };
  effectiveInterestRate: number;
  id?: string;
}

export enum AmlCheckStatus {
  APPROVED = 'Assess',
  REFERRED = 'Refer',
  DECLINED = 'Decline',
}

interface Custom {
  userData: {
    [key: string]: {
      fileIds?: string[];
      additionalFileIds?: string[];
      scrive?: {
        scriveIdentId?: string;
        privacyPolicyEsign?: {
          url: string;
        };
      };
    };
  };
  internal?: {
    portalUrl?: string;
    applicationSubmitted?: boolean;
  };
  continuationUrl?: string;
}

export enum OdsFinancialVerificationChoice {
  ODS = 'ODS',
  MANUAL = 'MANUAL',
}

interface FinancialDisclosure {
  monthlyExpenses?: MonthlyExpenses;
  annualInvestedIncome?: number;
  paymentContribution?: boolean;
  assets?: DataStorageAsset[];
  housingSituation: string;
  housingSituationOther: string | null;
  hasMortgage: boolean;
  monthlyIncome?: MonthlyIncome;
  rentAmount?: number | null;
}

interface MonthlyExpenses {
  hasMortgage: boolean;
  insuranceAmount?: number | null;
  alcoholTobaccoGambling?: number | null;
  educationFee?: number | null;
  privateHealthcareAmount?: number | null;
  alimonyAmount?: number | null;
  additionalBillsLivingExpenseAmount?: number | null;
  transportation?: number | null;
  sportActivities?: number | null;
  additionalExpenseAmount?: number | null;
  rentAmount?: number | null;
  remoteLivingExpenses?: number | null;
  vacation?: number | null;
  clothing?: number | null;
  entertainmentLeisure?: number | null;
  groceries?: number | null;
  foreseeableExpenseAmount?: number | null;
  phoneInternet?: number | null;
  credits: DataStorageCredit[];
}

interface MonthlyIncome {
  benefits?: number | null;
  netIncomeAmount?: number | null;
  commissions?: number | null;
  bonus?: number | null;
  netHouseholdIncomeAmount?: number | null;
  overtime?: number | null;
  additionalIncomeAmount?: number | null;
  rental?: number | null;
}

export interface DataStorageCredit {
  limit: number;
  balance: number;
  installmentAmount: number;
  customType: LiabilityCustomType;
  type: CreditType;
}

export interface DataStorageAsset {
  description: string;
  value: number;
  customType: AssetCustomType;
  type: AssetType;
}

export enum TrustAgentType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

interface Employment<Empl> {
  employer?: Empl;
  employmentStartDate?: string;
  employmentStatus: EmploymentStatus;
  occupation?: Occupation.OTHERS;
  occupationDescription?: string;
}

interface EmployerContactData {
  email: string;
  telephoneNumber: string;
  telephoneCountryCode: string;
}

interface Employer {
  contactData: EmployerContactData;
  address: Address;
  name: string;
  occupationDescription: string;
}

interface PreviousEmployer {
  contactData: EmployerContactData;
  address: PreviousEmploymentAddress;
  name: string;
  occupationDescription: string;
}
