import { HousingSituation } from '@bus/models/dist/enums/new/housing-situation.enum';
import yup from './yup-extended';
import i18n from '../i18n/i18n';
import {
  BaseAddressFieldsetValidatorMixinWCountrySelection,
  BaseAddressFieldsetValidatorMixinWResidentSince,
  BaseAddressFieldsValidatorMixin,
} from './BaseAddressFieldset.validator';

const requiredMessage = i18n.t('validation:required');
const requiredSelectMessage = i18n.t('validation:requiredSelect');
const lettersOnlyMessage = i18n.t('validation:lettersOnly');
const numericOnlyMessage = i18n.t('validation:numericOnly');
const invalidMailMessage = i18n.t('validation:invalidMail');
const duplicatedMailMessage = i18n.t('validation:duplicatedEmail');

const lettersOnlyRegEx = /^[.\p{L}\s -']+$/u;

export const contactDataFieldsValidation = {
  mobilePhoneCountryCode: yup.string().required(requiredSelectMessage),
  mobilePhoneNumber: yup
    .string()
    .required(requiredMessage)
    .min(9, i18n.t('validation:minLength', { value: 9 }))
    .max(11, i18n.t('validation:maxLength', { value: 11 }))
    .matches(/^[0-9]+$/, numericOnlyMessage),
  email: yup.string().required(requiredMessage).email(invalidMailMessage),
};

export const ContactInformationValidationFactory = (restrictedEmailAddresses: string[] = []) =>
  yup
    .object()
    .shape({
      financialDisclosure: yup
        .object({
          paymentContribution: yup.boolean().notRequired(),
          housingSituation: yup.string().required(requiredSelectMessage),
          housingSituationOther: yup.string().when('housingSituation', {
            is: HousingSituation.OTHER,
            then: (s) => s.required(requiredMessage).matches(lettersOnlyRegEx, lettersOnlyMessage),
            otherwise: (s) => s.notRequired(),
          }),
        })
        .required(requiredMessage),
      addresses: yup.tuple([
        yup.object().shape(BaseAddressFieldsetValidatorMixinWResidentSince),
        yup.object().shape(BaseAddressFieldsetValidatorMixinWCountrySelection).nullable(),
        yup.object().shape(BaseAddressFieldsValidatorMixin).nullable(),
      ]),
      contactData: yup.object({
        ...contactDataFieldsValidation,
        email: yup
          .string()
          .required(requiredMessage)
          .email(invalidMailMessage)
          .notOneOf(restrictedEmailAddresses || [], duplicatedMailMessage),
      }),
    })
    .required(requiredMessage);
