import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { PhoneCountryCode } from '@bus/models/dist/enums/general/phone-country-code.enum';
import { AddressType } from '@bus/models/dist/enums/new/address-type.enum';
import { Gender } from '@bus/models/dist/enums/new/gender.enum';
import { HousingSituation } from '@bus/models/dist/enums/new/housing-situation.enum';
import { MaritalStatus } from '@bus/models/dist/enums/new/marital-status.enum';
import { Occupation } from '@bus/models/dist/enums/new/occupation.enum';
import { IdentityDocuments, ReferenceContact, TrustInformation } from '../../dataStorage.model';
import { DataPrivacyStatement } from '../Transaction/transaction.model';

export type HousingSituationAu = HousingSituation;

export enum CustomerRole {
  CO_APPLICANT = 'CO_APPLICANT',
  REFERENCE_CONTACT_1 = 'REFERENCE_CONTACT_1',
  REFERENCE_CONTACT_2 = 'REFERENCE_CONTACT_2',
  TRUST = 'TRUST',
}

export enum PartnerType {
  PRIVATE = 'PRIVATE',
  BUSINESS = 'BUSINESS',
}

export enum EntityLegalForm {
  SOLE_TRADER = 'SOLE_TRADER',
  COMPANY = 'COMPANY',
  TRUST = 'TRUST',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum Salutation {
  MR = 'MR',
  MRS = 'MRS',
  MISS = 'MISS',
  MS = 'MS',
}

export enum AustralianResidencyStatus {
  ID_CARD = 'ID_CARD',
  TEMPORARY_RESIDENCE_CARD = 'TEMPORARY_RESIDENCE_CARD',
  PERMANENT_RESIDENCE_CARD = 'PERMANENT_RESIDENCE_CARD',
}

export enum IdentificationType {
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  PASSPORT = 'PASSPORT',
}

export enum AustralianState {
  ACT = 'ACT - Australian Capital Territory',
  NSW = 'NSW - New South Wales',
  NT = 'NT - Northern Territory',
  QLD = 'QLD - Queensland',
  SA = 'SA - South Australia',
  TAS = 'TAS - Tasmania',
  VIC = 'VIC - Victoria',
  WA = 'WA - Western Australia',
}

export enum IndustryClassification {
  AGRICULTURE_FORESTRY_FISHING = 'A',
  MINING_QUARRYING = 'B',
  MANUFACTURING = 'C',
  ELECTRICITY_GAS_STEAM_AIR_CONDITIONING_SUPPLY = 'D',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_REMEDIATION_ACTIVITIES = 'E',
  CONSTRUCTION = 'F',
  WHOLESALE_RETAIL_TRADE_REPAIR_OF_MOTOR_VEHICLES_MOTORCYCLES = 'G',
  TRANSPORTATION_STORAGE = 'H',
  ACCOMMODATION_FOOD_SERVICE_ACTIVITIES = 'I',
  INFORMATION_COMMUNICATION = 'J',
  FINANCIAL_INSURANCE_ACTIVITIES = 'K',
  REAL_ESTATE_ACTIVITIES = 'L',
  PROFESSIONAL_SCIENTIFIC_TECHNICAL_ACTIVITIES = 'M',
  ADMINISTRATIVE_SUPPORT_SERVICE_ACTIVITIES = 'N',
  PUBLIC_ADMINISTRATION_DEFENCE_COMPULSORY_SOCIAL_SECURITY = 'O',
  EDUCATION = 'P',
  HUMAN_HEALTH_SOCIAL_WORK_ACTIVITIES = 'Q',
  ARTS_ENTERTAINMENT_RECREATION = 'R',
  OTHER_SERVICE_ACTIVITIES = 'S',
}

type AnyEnumKeysAsStrings<TEnumType> = keyof TEnumType;
export type AustralianStateKey = AnyEnumKeysAsStrings<typeof AustralianState>;

export interface BasePersonalCredentials {
  salutation: Salutation | null;
  firstName: string;
  middleNames?: string;
  lastName: string;
  gender: Gender;
  dateOfBirth?: string;
}

export interface BasePersonalData extends BasePersonalCredentials {
  maritalStatus: MaritalStatus;
  numberOfDependentChildren: number;
  identityDocuments: IdentityDocuments;
}

export interface CustomerData extends BasePersonalData {
  partnerType: PartnerType | null;
  tradeIn: 'yes' | 'no';
  customerRelations?: {
    [CustomerRole.TRUST]?: TrustInformation;
  };
}

export interface PersonalData extends CustomerData {
  companyData: {
    entityLegalForm: EntityLegalForm | null;

    // Only for Individual businesses:
    id?: string;
  };
}

export type ContactInformationCurrentAddress = {
  street: string;
  houseNumber: string;
  optionalLine: string;
  zipCode: string;
  city: string;
  state: AustralianStateKey;
  addressType: AddressType.PRIMARY;
  residentSinceDate: string;
};

export type ContactInformationPreviousAddress = {
  street: string;
  houseNumber: string;
  optionalLine: string;
  zipCode: string;
  city: string;
  countryCode: CountryCode;
  state: AustralianStateKey;
  addressType: AddressType.PRIMARY;
};

export type ContactInformationShippingAddress = {
  street: string;
  houseNumber: string;
  optionalLine: string;
  zipCode: string;
  city: string;
  state: AustralianStateKey;
  addressType: AddressType.SHIPPING;
};

export type Addresses = [
  ContactInformationCurrentAddress,
  ContactInformationPreviousAddress | null,
  ContactInformationShippingAddress | null
];

interface BaseContactData {
  mobilePhoneCountryCode?: PhoneCountryCode;
  mobilePhoneNumber?: string;
  email: string;
}

export interface ContactInformation {
  financialDisclosure: {
    housingSituation: HousingSituationAu;
    housingSituationOther: string | null;
    paymentContribution: boolean;
    // custom field to indicate housing situation select option is "Own house (with mortgage)"
    hasMortgage: boolean;
  };
  addresses: Addresses;
  contactData: BaseContactData;
}

export enum EmploymentStatus {
  PERMANENT = 'PERMANENT',
  SEASONAL = 'SEASONAL',
  CONTRACTOR = 'CONTRACTOR',
  PART_TIME = 'PART_TIME',
  CENTRALLINK = 'CENTRALLINK',
  HOMEMAKER = 'HOMEMAKER',
  PENSIONER = 'PENSIONER',
  CASUAL = 'CASUAL',
  SELF_FUNDER = 'SELF_FUNDER',
  WORK_COMP = 'WORK_COMP',
  UNEMPLOYED = 'UNEMPLOYED',
}

export interface CompanyInformation {
  companyData: {
    id: string;
    name: string;
    ownership: number | null;
    tradeName: string;
    established: CountryCode | null;
    foundationDate: string;
    industry: IndustryClassification;
    addresses: Addresses;
    contactData: {
      email: string;
      mobilePhoneCountryCode: PhoneCountryCode;
      mobilePhoneNumber: string;
    };
  };
}

export const noEmploymentStatuses = [
  EmploymentStatus.CENTRALLINK,
  EmploymentStatus.HOMEMAKER,
  EmploymentStatus.PENSIONER,
  EmploymentStatus.SELF_FUNDER,
  EmploymentStatus.UNEMPLOYED,
];

export interface EmploymentInformation {
  employment: {
    employer: {
      name: string;
      address: {
        street: string;
        houseNumber: string;
        optionalLine: string;
        zipCode: string;
        city: string;
        state: AustralianStateKey;
        addressType: AddressType;
      };
      contactData: {
        telephoneNumber: string;
        telephoneCountryCode: string;
      };
    };
    employmentStartDate: string;
    occupation: Occupation.OTHERS;
    occupationDescription: string;
    employmentStatus: EmploymentStatus;
  };
  previousEmployments:
    | {
        employer: {
          name: string;
          address: {
            street: string;
            houseNumber: string;
            optionalLine: string;
            zipCode: string;
            city: string;
            addressType: AddressType;
            countryCode: CountryCode;
            // only for Australia:
            state?: AustralianStateKey;
          };
          contactData: {
            telephoneNumber: string;
            telephoneCountryCode: string;
          };
        };
        employmentStartDate: string;
        occupation: Occupation.OTHERS;
        occupationDescription: string;
        employmentStatus: EmploymentStatus;
      }[]
    | null;
}

export interface SingleAsset {
  description: string;
  value: number;
  customType: AssetCustomType;
  type: AssetType;
}

/**
 * Represents BFF asset type
 */
enum AssetType {
  FINANCIAL = 'FINANCIAL',
  FIXED = 'FIXED',
  INTANGIBLE = 'INTANGIBLE',
  OTHER = 'OTHER',
}

/**
 * Represents "local", application's asset type, which are mapped many-to-one to BFF to asset types
 */
export enum AssetCustomType {
  SAVINGS = 'SAVINGS',
  HOME = 'HOME',
  INVESTMENT_PROPERTIES = 'INVESTMENT_PROPERTIES',
  LAND = 'LAND',
  MOTOR_VEHICLES = 'MOTOR_VEHICLES',
  SUPERANNUATION = 'SUPERANNUATION',
  OTHER = 'OTHER',
}

export const customAssetToAsset: Record<AssetCustomType, AssetType> = Object.freeze({
  [AssetCustomType.SAVINGS]: AssetType.FINANCIAL,
  [AssetCustomType.HOME]: AssetType.FIXED,
  [AssetCustomType.INVESTMENT_PROPERTIES]: AssetType.FINANCIAL,
  [AssetCustomType.LAND]: AssetType.FIXED,
  [AssetCustomType.MOTOR_VEHICLES]: AssetType.FIXED,
  [AssetCustomType.SUPERANNUATION]: AssetType.FINANCIAL,
  [AssetCustomType.OTHER]: AssetType.OTHER,
});

export interface Assets {
  financialDisclosure: {
    assets: SingleAsset[];
  };
}

/**
 * Represents BFF credit type
 */
export enum CreditType {
  CAR_CREDIT = 'CAR_CREDIT',
  CREDIT_CARD = 'CREDIT_CARD',
  LOAN = 'LOAN',
}

/**
 * Represents "local", application's liability type, which are mapped many-to-one to BFF to asset types
 */
export enum LiabilityCustomType {
  HOME_LOAN = 'HOME_LOAN',
  OTHER_PROPERTY_LOAN = 'OTHER_PROPERTY_LOAN',
  VEHICLE_LOANS_REFINANCED = 'VEHICLE_LOANS_REFINANCED',
  OTHER_VEHICLE_LOANS = 'OTHER_VEHICLE_LOANS',
  CREDIT_CARD_1 = 'CREDIT_CARD_1',
  CREDIT_CARD_2 = 'CREDIT_CARD_2',
  CREDIT_CARD_3 = 'CREDIT_CARD_3',
  OTHER_CREDIT_CARDS = 'OTHER_CREDIT_CARDS',
  OTHER_LOANS = 'OTHER_LOANS',
}

export const customLiabilityToCreditType: Record<LiabilityCustomType, CreditType | undefined> = Object.freeze({
  [LiabilityCustomType.HOME_LOAN]: CreditType.LOAN,
  [LiabilityCustomType.OTHER_PROPERTY_LOAN]: CreditType.LOAN,
  [LiabilityCustomType.VEHICLE_LOANS_REFINANCED]: undefined,
  [LiabilityCustomType.OTHER_VEHICLE_LOANS]: CreditType.CAR_CREDIT,
  [LiabilityCustomType.CREDIT_CARD_1]: CreditType.CREDIT_CARD,
  [LiabilityCustomType.CREDIT_CARD_2]: CreditType.CREDIT_CARD,
  [LiabilityCustomType.CREDIT_CARD_3]: CreditType.CREDIT_CARD,
  [LiabilityCustomType.OTHER_CREDIT_CARDS]: CreditType.CREDIT_CARD,
  [LiabilityCustomType.OTHER_LOANS]: CreditType.LOAN,
});

export interface SingleCredit {
  installmentAmount: number;
  balance: number;
  limit: number;
  customType: LiabilityCustomType;
  type: CreditType | undefined;
}

export interface Liabilities {
  financialDisclosure: {
    monthlyExpenses: {
      credits: SingleCredit[];
    };
  };
}

export interface ReferenceContacts {
  customerRelations: {
    // Note: data storage customerRelations are an array, this object needs to be flattened
    [CustomerRole.REFERENCE_CONTACT_1]: ReferenceContact;
    [CustomerRole.REFERENCE_CONTACT_2]: ReferenceContact;
  };
}

export interface CoApplicantAmlData extends BasePersonalCredentials {
  contactData: BaseContactData;
  dataPrivacyStatement: {
    coaInitialDataConsent: boolean;
  };
}

export enum FormDataSection {
  personalData = 'personalData',
  contactInformation = 'contactInformation',
  companyInformation = 'companyInformation',
  referenceContacts = 'referenceContacts',
  employmentInformation = 'employmentInformation',
  assets = 'assets',
  liabilities = 'liabilities',
}

export interface FormsData {
  [FormDataSection.personalData]: PersonalData;
  [FormDataSection.contactInformation]: ContactInformation;
  [FormDataSection.companyInformation]: CompanyInformation;
  [FormDataSection.referenceContacts]: ReferenceContacts;
  [FormDataSection.employmentInformation]: EmploymentInformation;
  [FormDataSection.assets]: Assets;
  [FormDataSection.liabilities]: Liabilities;
  dataPrivacyStatement: DataPrivacyStatement;
  coApplicantAmlData: CoApplicantAmlData;
}
