import React from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../../../../components';
import { AustralianState, AustralianStateKey } from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { EmptyOption, FORM_EMPTY_VALUE } from '../../../formUtils';
import { Input, Select } from '@vwfs-bronson/bronson-react';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { useFormikContext } from 'formik';

const stateOptionEntries = Object.entries(AustralianState);

interface Props {
  namePrefix: string;
  withCountrySelection?: boolean;
}

export const countryCodeOptions = Object.keys(CountryCode).map((key) => ({
  value: (CountryCode as any)[key],
  viewValue: key,
}));

const BaseAddressFieldset = (props: Props): JSX.Element => {
  const { namePrefix, withCountrySelection = false } = props;
  const { t } = useTranslation();

  const formikProps = useFormikContext();

  const addressCountryCode = get(formikProps.values, `${namePrefix}countryCode`);
  const state = get(formikProps.values, `${namePrefix}state`);

  React.useEffect(() => {
    if (!withCountrySelection) {
      return;
    }
    if (addressCountryCode) {
      formikProps.setFieldValue(`${namePrefix}state`, state || FORM_EMPTY_VALUE);
    } else {
      formikProps.setFieldValue(`${namePrefix}state`, null, true);
    }
  }, [addressCountryCode]);

  return (
    <Fieldset>
      <Layout>
        <Layout.Item default="1/2" s="1/1">
          <FormField
            name={`${namePrefix}street`}
            type="input"
            labelText={t('formsData:formSections:contactInformation:fields:street:label')}
            notion
            render={(fieldProps) => <Input {...fieldProps} />}
          />
        </Layout.Item>
        <Layout.Item default="1/4" s="1/1">
          <FormField
            name={`${namePrefix}houseNumber`}
            type="input"
            labelText={t('formsData:formSections:contactInformation:fields:houseNumber:label')}
            notion
            render={(fieldProps) => <Input {...fieldProps} />}
          />
        </Layout.Item>
        <Layout.Item default="1/4" s="1/1">
          <FormField
            name={`${namePrefix}optionalLine`}
            type="input"
            labelText={t('formsData:formSections:contactInformation:fields:optionalLine:label')}
            notion={false}
            render={(fieldProps) => <Input {...fieldProps} />}
          />
        </Layout.Item>
        <Layout.Item default="1/2" s="1/1">
          <FormField
            name={`${namePrefix}zipCode`}
            type="input"
            labelText={t('formsData:formSections:contactInformation:fields:zipCode:label')}
            notion
            render={(fieldProps) => <Input {...fieldProps} />}
          />
        </Layout.Item>
        <Layout.Item default="1/2" s="1/1">
          <FormField
            name={`${namePrefix}city`}
            type="input"
            labelText={t('formsData:formSections:contactInformation:fields:city:label')}
            notion
            render={(fieldProps) => <Input {...fieldProps} />}
          />
        </Layout.Item>
        {withCountrySelection && (
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${namePrefix}countryCode`}
              type="select"
              labelText={t('formsData:formSections:contactInformation:fields:countryCode:label')}
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {countryCodeOptions.map((option) => (
                    <Select.Item key={option.value} value={option.value}>
                      {option.viewValue}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
        )}
        {!withCountrySelection || addressCountryCode === CountryCode.Australia ? (
          <Layout.Item default="1/2">
            <FormField
              name={`${namePrefix}state`}
              type="select"
              labelText={t('formsData:formSections:contactInformation:fields:state:label')}
              getReadonlyValue={(val: string) => (val ? AustralianState[val as AustralianStateKey] : '')}
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {stateOptionEntries.map(([code, label]) => (
                    <Select.Item key={code} value={code}>
                      {label}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
        ) : (
          <></>
        )}
      </Layout>
    </Fieldset>
  );
};

export default BaseAddressFieldset;
