import { SessionAction, SessionActionType } from './session.actions';
import { Session } from './session.model';

export const SESSION_CACHE_KEY = 'app:session';

const getSessionInitialState = (): Session => {
  const isNew = window.location.search.includes('cid=');
  if (isNew) {
    sessionStorage.removeItem(SESSION_CACHE_KEY);
  }
  const sessionJSON = sessionStorage.getItem(SESSION_CACHE_KEY) as string;
  return isNew || !sessionJSON
    ? {
        id: null,
        userId: null,
        token: null,
        pin: null,
        completed: false,
      }
    : (JSON.parse(sessionJSON) as Session);
};

// eslint-disable-next-line default-param-last
export default function (state = getSessionInitialState(), action: SessionAction): Session {
  switch (action.type) {
    case SessionActionType.SET:
      return {
        ...state,
        ...action.payload,
      };
    case SessionActionType.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    default:
      return state;
  }
}
