import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { PhoneCountryCode } from '@bus/models/dist/enums/general/phone-country-code.enum';
import { AddressType } from '@bus/models/dist/enums/new/address-type.enum';
import { Gender } from '@bus/models/dist/enums/new/gender.enum';
import { MaritalStatus } from '@bus/models/dist/enums/new/marital-status.enum';
import { Occupation } from '@bus/models/dist/enums/new/occupation.enum';
import { SetDataAction } from '../../utils';
import { FormsDataActionType } from './formsData.actions';
import {
  AustralianResidencyStatus,
  AustralianStateKey,
  ContactInformation,
  CustomerRole,
  EmploymentStatus,
  EntityLegalForm,
  FormDataSection,
  FormsData,
  HousingSituationAu,
  IdentificationType,
  IndustryClassification,
  PartnerType,
  Salutation,
} from './formsData.model';
import { FORM_EMPTY_VALUE } from '../../../../screens/FormPage/formUtils';

export const getEmptyBaseAddress = (addressType: AddressType) => ({
  street: FORM_EMPTY_VALUE,
  houseNumber: FORM_EMPTY_VALUE,
  optionalLine: FORM_EMPTY_VALUE,
  zipCode: FORM_EMPTY_VALUE,
  countryCode: FORM_EMPTY_VALUE,
  city: FORM_EMPTY_VALUE,
  state: FORM_EMPTY_VALUE as AustralianStateKey,
  addressType: addressType,
});

const contactInformationInitialState: ContactInformation = {
  financialDisclosure: {
    housingSituation: FORM_EMPTY_VALUE as HousingSituationAu,
    housingSituationOther: FORM_EMPTY_VALUE,
    paymentContribution: false,
    hasMortgage: false,
  },
  addresses: [
    {
      street: FORM_EMPTY_VALUE,
      houseNumber: FORM_EMPTY_VALUE,
      optionalLine: FORM_EMPTY_VALUE,
      zipCode: FORM_EMPTY_VALUE,
      city: FORM_EMPTY_VALUE,
      state: FORM_EMPTY_VALUE as AustralianStateKey,
      addressType: AddressType.PRIMARY,
      residentSinceDate: FORM_EMPTY_VALUE,
    },
    null,
    null,
  ],
  contactData: {
    mobilePhoneCountryCode: PhoneCountryCode.Australia,
    mobilePhoneNumber: FORM_EMPTY_VALUE,
    email: FORM_EMPTY_VALUE,
  },
};

// Empty strings since formik requires "some" initial value for its controlled components
export const formsDataInitialState: FormsData = {
  dataPrivacyStatement: {
    dataPrivacyConsentAcceptance: false,
    dataPrivacyConsentAcceptanceDate: '',
    personalDataAcceptance: false,
    allowAds: false,
  },
  [FormDataSection.personalData]: {
    firstName: FORM_EMPTY_VALUE,
    middleNames: FORM_EMPTY_VALUE,
    lastName: FORM_EMPTY_VALUE,
    dateOfBirth: FORM_EMPTY_VALUE,
    partnerType: FORM_EMPTY_VALUE as PartnerType,
    tradeIn: FORM_EMPTY_VALUE as any,
    salutation: FORM_EMPTY_VALUE as Salutation,
    gender: FORM_EMPTY_VALUE as Gender,
    maritalStatus: FORM_EMPTY_VALUE as MaritalStatus,
    numberOfDependentChildren: FORM_EMPTY_VALUE as any,
    customerRelations: {
      [CustomerRole.TRUST]: {
        role: CustomerRole.TRUST,
        trustAgentType: FORM_EMPTY_VALUE as any,
        name: FORM_EMPTY_VALUE,
        id: FORM_EMPTY_VALUE,
        established: CountryCode.Australia,
        foundationDate: FORM_EMPTY_VALUE,
        contactData: {
          mobilePhoneCountryCode: PhoneCountryCode.Australia,
          mobilePhoneNumber: FORM_EMPTY_VALUE,
          email: FORM_EMPTY_VALUE,
        },
        addresses: [contactInformationInitialState.addresses[0]],
      },
    },
    identityDocuments: [
      {
        identityDocumentType: FORM_EMPTY_VALUE as AustralianResidencyStatus,
      },
      {
        identityDocumentType: IdentificationType.DRIVER_LICENSE,
        identityDocumentId: FORM_EMPTY_VALUE,
        identityDocumentVerificationNumber: FORM_EMPTY_VALUE,
        expiryDate: FORM_EMPTY_VALUE,
        issuingCountry: CountryCode.Australia,
        issuingState: FORM_EMPTY_VALUE as AustralianStateKey,
      },
      {
        identityDocumentType: IdentificationType.PASSPORT,
        identityDocumentId: FORM_EMPTY_VALUE,
        expiryDate: FORM_EMPTY_VALUE,
        issuingCountry: CountryCode.Australia,
      },
    ],
    companyData: {
      entityLegalForm: FORM_EMPTY_VALUE as EntityLegalForm,
    },
  },
  [FormDataSection.contactInformation]: contactInformationInitialState,
  [FormDataSection.referenceContacts]: {
    customerRelations: {
      [CustomerRole.REFERENCE_CONTACT_1]: {
        role: CustomerRole.REFERENCE_CONTACT_1,
        salutation: FORM_EMPTY_VALUE as Salutation,
        firstName: FORM_EMPTY_VALUE,
        middleNames: FORM_EMPTY_VALUE,
        lastName: FORM_EMPTY_VALUE,
        addresses: [
          {
            street: FORM_EMPTY_VALUE,
            houseNumber: FORM_EMPTY_VALUE,
            optionalLine: FORM_EMPTY_VALUE,
            zipCode: FORM_EMPTY_VALUE,
            city: FORM_EMPTY_VALUE,
            state: FORM_EMPTY_VALUE as AustralianStateKey,
            addressType: AddressType.PRIMARY,
          },
        ],
        contactData: {
          mobilePhoneCountryCode: PhoneCountryCode.Australia,
          mobilePhoneNumber: FORM_EMPTY_VALUE,
          email: FORM_EMPTY_VALUE,
        },
      },
      [CustomerRole.REFERENCE_CONTACT_2]: {
        role: CustomerRole.REFERENCE_CONTACT_2,
        salutation: FORM_EMPTY_VALUE as Salutation,
        firstName: FORM_EMPTY_VALUE,
        middleNames: FORM_EMPTY_VALUE,
        lastName: FORM_EMPTY_VALUE,
        addresses: [
          {
            street: FORM_EMPTY_VALUE,
            houseNumber: FORM_EMPTY_VALUE,
            optionalLine: FORM_EMPTY_VALUE,
            zipCode: FORM_EMPTY_VALUE,
            city: FORM_EMPTY_VALUE,
            state: FORM_EMPTY_VALUE as AustralianStateKey,
            addressType: AddressType.PRIMARY,
          },
        ],
        contactData: {
          mobilePhoneCountryCode: PhoneCountryCode.Australia,
          mobilePhoneNumber: FORM_EMPTY_VALUE,
          email: FORM_EMPTY_VALUE,
        },
      },
    },
  },
  [FormDataSection.companyInformation]: {
    companyData: {
      id: FORM_EMPTY_VALUE,
      name: FORM_EMPTY_VALUE,
      ownership: FORM_EMPTY_VALUE as any,
      established: FORM_EMPTY_VALUE as CountryCode,
      foundationDate: FORM_EMPTY_VALUE,
      tradeName: FORM_EMPTY_VALUE,
      industry: FORM_EMPTY_VALUE as IndustryClassification,
      contactData: {
        email: FORM_EMPTY_VALUE,
        mobilePhoneCountryCode: FORM_EMPTY_VALUE as PhoneCountryCode,
        mobilePhoneNumber: FORM_EMPTY_VALUE,
      },
      addresses: [
        {
          street: FORM_EMPTY_VALUE,
          houseNumber: FORM_EMPTY_VALUE,
          optionalLine: FORM_EMPTY_VALUE,
          zipCode: FORM_EMPTY_VALUE,
          city: FORM_EMPTY_VALUE,
          state: FORM_EMPTY_VALUE as AustralianStateKey,
          addressType: AddressType.PRIMARY,
          residentSinceDate: FORM_EMPTY_VALUE,
        },
        null,
        null,
      ],
    },
  },
  [FormDataSection.employmentInformation]: {
    employment: {
      employer: {
        name: FORM_EMPTY_VALUE,
        address: {
          street: FORM_EMPTY_VALUE,
          houseNumber: FORM_EMPTY_VALUE,
          optionalLine: FORM_EMPTY_VALUE,
          zipCode: FORM_EMPTY_VALUE,
          city: FORM_EMPTY_VALUE,
          state: FORM_EMPTY_VALUE as AustralianStateKey,
          addressType: AddressType.PRIMARY,
        },
        contactData: {
          telephoneNumber: FORM_EMPTY_VALUE,
          telephoneCountryCode: PhoneCountryCode.Australia,
        },
      },
      employmentStartDate: FORM_EMPTY_VALUE,
      occupation: Occupation.OTHERS,
      occupationDescription: FORM_EMPTY_VALUE as string,
      employmentStatus: FORM_EMPTY_VALUE as EmploymentStatus,
    },
    previousEmployments: [
      {
        employer: {
          name: FORM_EMPTY_VALUE,
          address: {
            street: FORM_EMPTY_VALUE,
            houseNumber: FORM_EMPTY_VALUE,
            optionalLine: FORM_EMPTY_VALUE,
            zipCode: FORM_EMPTY_VALUE,
            city: FORM_EMPTY_VALUE,
            countryCode: CountryCode.Australia,
            state: FORM_EMPTY_VALUE as AustralianStateKey,
            addressType: AddressType.PRIMARY,
          },
          contactData: {
            telephoneNumber: FORM_EMPTY_VALUE,
            telephoneCountryCode: PhoneCountryCode.Australia,
          },
        },
        employmentStartDate: FORM_EMPTY_VALUE,
        occupation: Occupation.OTHERS,
        occupationDescription: FORM_EMPTY_VALUE as string,
        employmentStatus: FORM_EMPTY_VALUE as EmploymentStatus,
      },
    ],
  },
  [FormDataSection.assets]: {
    financialDisclosure: {
      assets: [],
    },
  },
  [FormDataSection.liabilities]: {
    financialDisclosure: {
      monthlyExpenses: {
        credits: [],
      },
    },
  },
  coApplicantAmlData: {
    firstName: FORM_EMPTY_VALUE,
    middleNames: FORM_EMPTY_VALUE,
    lastName: FORM_EMPTY_VALUE,
    dateOfBirth: FORM_EMPTY_VALUE,
    gender: FORM_EMPTY_VALUE as Gender,
    salutation: FORM_EMPTY_VALUE as Salutation,
    contactData: {
      mobilePhoneCountryCode: PhoneCountryCode.Australia,
      mobilePhoneNumber: FORM_EMPTY_VALUE,
      email: FORM_EMPTY_VALUE,
    },
    dataPrivacyStatement: {
      coaInitialDataConsent: FORM_EMPTY_VALUE as any,
    },
  },
};

// eslint-disable-next-line default-param-last
export default function (state = formsDataInitialState, action: SetDataAction<FormsDataActionType, any>): FormsData {
  switch (action.type) {
    case FormsDataActionType.UPDATE_DATA_PRIVACY_STATEMENT:
      return {
        ...state,
        dataPrivacyStatement: {
          ...(state.dataPrivacyStatement || {}),
          ...action.payload,
        },
      };
    case FormsDataActionType.SET_PERSONAL_DATA:
      return {
        ...state,
        [FormDataSection.personalData]: action.payload,
      };
    case FormsDataActionType.SET_CONTACT_INFORMATION:
      return {
        ...state,
        [FormDataSection.contactInformation]: action.payload,
      };
    case FormsDataActionType.SET_REFERENCE_CONTACTS:
      return {
        ...state,
        [FormDataSection.referenceContacts]: action.payload,
      };
    case FormsDataActionType.SET_COMPANY_DATA:
      return {
        ...state,
        [FormDataSection.companyInformation]: action.payload,
      };
    case FormsDataActionType.SET_EMPLOYMENT_INFORMATION:
      return {
        ...state,
        [FormDataSection.employmentInformation]: action.payload,
      };
    case FormsDataActionType.SET_ASSETS:
      return {
        ...state,
        [FormDataSection.assets]: action.payload,
      };
    case FormsDataActionType.SET_LIABILITIES:
      return {
        ...state,
        [FormDataSection.liabilities]: action.payload,
      };
    case FormsDataActionType.SET_CO_APPLICANT_DATA:
      return {
        ...state,
        coApplicantAmlData: action.payload,
      };

    default:
      return state;
  }
}
