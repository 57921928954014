import { Paragraph, RadioButton, RadioButtonGroup, Select } from '@vwfs-bronson/bronson-react';
import { Field, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { analytics } from '../../../../../services/analytics/analytics';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';
import { savePersonalData } from '../../../../../services/redux/reducers/FormsData/formsData.actions';
import {
  CustomerData,
  CustomerRole,
  EntityLegalForm,
  FormDataSection,
  PartnerType,
  PersonalData,
} from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { UserType } from '../../../../../services/redux/reducers/Session/session.model';
import { getLoggedInUserType } from '../../../../../services/redux/reducers/Session/session.selectors';
import {
  CoApplicantPersonalDataFormValidation,
  PersonalDataFormValidation,
} from '../../../../../services/validators/PersonalDataForm.validation';
import ContinueBtn from '../../../ContinueBtn';
import { EmptyOption, FORM_EMPTY_VALUE } from '../../../formUtils';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import { BasePersonalDataFieldset } from '../Mixins/BasePersonalDataFieldset';
import { TrusteeFieldset } from '../Mixins/TrusteeFieldset';
import { getIsPrivatePartnerTypeDisabled } from '../../../../../services/redux/reducers/FinancialProduct/financialProduct.selectors';

const partnerTypeOptions = [PartnerType.PRIVATE, PartnerType.BUSINESS];
const tradeInOptions: Array<CustomerData['tradeIn']> = ['yes', 'no'];
const entityLegalFormOptions = [
  EntityLegalForm.SOLE_TRADER,
  EntityLegalForm.COMPANY,
  EntityLegalForm.TRUST,
  EntityLegalForm.INDIVIDUAL,
];

interface Props {
  formData: PersonalData;
  userType: UserType;
  isPrivatePartnerTypeDisabled: boolean;

  setFormData: (formData: PersonalData) => void;
}

const PersonalDataComponent = (props: Props): JSX.Element => {
  const { formData, userType, isPrivatePartnerTypeDisabled, setFormData } = props;
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);

  const onEntityLegalFormChange = (
    entityLegalForm: EntityLegalForm | null,
    setFieldValue: FormikProps<PersonalData>['setFieldValue']
  ) => {
    if (entityLegalForm !== EntityLegalForm.TRUST) {
      setFieldValue(`customerRelations.${CustomerRole.TRUST}`, null, true);
    }
  };

  const onPartnerTypeChange = (partnerType: PartnerType, setFieldValue: FormikProps<PersonalData>['setFieldValue']) => {
    if (partnerType === PartnerType.PRIVATE) {
      setFieldValue('companyData.entityLegalForm', FORM_EMPTY_VALUE, true);
      onEntityLegalFormChange(null, setFieldValue);
    }
  };

  return (
    <>
      {!isReadonly && <Paragraph>{t('formsData:formSections:personalData:formHint1')}</Paragraph>}
      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={
          userType === UserType.PRIMARY ? PersonalDataFormValidation : CoApplicantPersonalDataFormValidation
        }
        onSubmit={(values) => {
          setFormData(values);
        }}
      >
        {(formikProps: FormikProps<PersonalData>) => {
          const { values } = formikProps;
          return (
            <Form noValidate>
              {userType === UserType.PRIMARY ? (
                <>
                  <Fieldset>
                    <Layout>
                      <Layout.Item default="1/1" s="1/1">
                        <FormField
                          id="partnerType"
                          type="other"
                          labelText={t('formsData:formSections:personalData:fields:partnerType:label')}
                          getReadonlyValue={(val: string) =>
                            t(`formsData:formSections:personalData:fields:partnerType:options:${val}`)
                          }
                          notion
                          name="partnerType"
                          render={() => (
                            <>
                              <RadioButtonGroup
                                labelledby="partnerType"
                                onChange={(e) => onPartnerTypeChange(e.target.value, formikProps.setFieldValue)}
                              >
                                {partnerTypeOptions.map((option) => (
                                  <Field
                                    key={option}
                                    as={RadioButton}
                                    type="radio"
                                    name="partnerType"
                                    value={option}
                                    disabled={option === PartnerType.PRIVATE && isPrivatePartnerTypeDisabled}
                                  >
                                    {t(
                                      `formsData:formSections:personalData:fields:partnerType:options:${option.toString()}`
                                    )}
                                  </Field>
                                ))}
                              </RadioButtonGroup>
                            </>
                          )}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/1" s="1/1">
                        <FormField
                          id="tradeIn"
                          type="other"
                          labelText={t('formsData:formSections:personalData:fields:tradeIn:label')}
                          getReadonlyValue={(val: string) =>
                            t(`formsData:formSections:personalData:fields:tradeIn:options:${val}`)
                          }
                          notion
                          name="tradeIn"
                          render={() => (
                            <>
                              <RadioButtonGroup labelledby="tradeIn">
                                {tradeInOptions.map((option) => (
                                  <Field key={option} as={RadioButton} type="radio" name="tradeIn" value={option}>
                                    {t(`formsData:formSections:personalData:fields:tradeIn:options:${option}`)}
                                  </Field>
                                ))}
                              </RadioButtonGroup>
                            </>
                          )}
                        />
                      </Layout.Item>
                    </Layout>
                  </Fieldset>
                  {values.partnerType === PartnerType.BUSINESS && (
                    <>
                      <Fieldset>
                        <Layout>
                          <Layout.Item default="1/3" s="1/1">
                            <FormField
                              name="companyData.entityLegalForm"
                              type="select"
                              labelText={t('formsData:formSections:personalData:fields:entityLegalForm:label')}
                              getReadonlyValue={(val: string) =>
                                t(`formsData:formSections:personalData:fields:entityLegalForm:options:${val}`)
                              }
                              notion
                              render={(fieldProps) => (
                                <Select
                                  {...fieldProps}
                                  onChange={(e) => {
                                    onEntityLegalFormChange(
                                      e.target.value as EntityLegalForm,
                                      formikProps.setFieldValue
                                    );
                                    formikProps.setFieldValue(
                                      'companyData.entityLegalForm',
                                      e.target.value as EntityLegalForm
                                    );
                                  }}
                                >
                                  <EmptyOption />
                                  {entityLegalFormOptions.map((option) => (
                                    <Select.Item key={option} value={option}>
                                      {t(
                                        `formsData:formSections:personalData:fields:entityLegalForm:options:${option}`
                                      )}
                                    </Select.Item>
                                  ))}
                                </Select>
                              )}
                            />
                          </Layout.Item>
                        </Layout>
                      </Fieldset>
                      {values.companyData.entityLegalForm === EntityLegalForm.TRUST && (
                        <TrusteeFieldset namePrefix={`customerRelations.${CustomerRole.TRUST}.`} />
                      )}
                    </>
                  )}
                </>
              ) : (
                ''
              )}

              <BasePersonalDataFieldset
                fieldPrefix=""
                entityType={values.companyData.entityLegalForm}
                formikProps={formikProps}
              />

              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
const mapStateToProps = (state: ApplicationState) =>
  ({
    formData: state.formsData[FormDataSection.personalData],
    userType: getLoggedInUserType(state),
    isPrivatePartnerTypeDisabled: getIsPrivatePartnerTypeDisabled(state),
  } as Partial<Props>);
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormData: (payload: PersonalData) => {
    dispatch(savePersonalData(payload));
    analytics.trackFormSectionOnContinue(FormDataSection.personalData);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDataComponent);
