import { SetDataActionCreator } from '../../utils';
import { BillsAndLivingExpenses, Expenses, ExpensesDataSection, Income } from './expenses.model';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import { getSession } from '../Session/session.selectors';
import { DataLoadErrorType, setDataLoadError, setIsDataLoading } from '../UI/UI.reducer';
import { openNextExpensesSection } from '../../../../screens/FormPage/formUtils';
import { Session } from '../Session/session.model';

import { saveExpensesDataToDataStorage } from '../../../api/expensesData/expensesData.service';

export enum ExpensesDataActionType {
  SET_INCOME = 'personalData/SET_INCOME',
  SET_EXPENSES = 'personalData/SET_EXPENSES',
  SET_BILLS_AND_LIVING_EXPENSES = 'personalData/SET_BILLS_AND_LIVING_EXPENSES',
}

export const setIncome: SetDataActionCreator<ExpensesDataActionType.SET_INCOME, Income> = (payload) => {
  return { type: ExpensesDataActionType.SET_INCOME, payload };
};

export const setExpenses: SetDataActionCreator<ExpensesDataActionType.SET_EXPENSES, Expenses> = (payload) => {
  return { type: ExpensesDataActionType.SET_EXPENSES, payload };
};

export const setBillsAndLivingExpenses: SetDataActionCreator<
  ExpensesDataActionType.SET_BILLS_AND_LIVING_EXPENSES,
  BillsAndLivingExpenses
> = (payload) => {
  return {
    type: ExpensesDataActionType.SET_BILLS_AND_LIVING_EXPENSES,
    payload,
  };
};

export const saveIncome: SetDataActionCreator<ExpensesDataActionType.SET_INCOME, Income> =
  (payload: Income): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveExpensesSectionData(dispatch, getState(), ExpensesDataSection.income, setIncome, payload);
  };

export const saveExpenses: SetDataActionCreator<ExpensesDataActionType.SET_EXPENSES, Expenses> =
  (payload: Expenses): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveExpensesSectionData(dispatch, getState(), ExpensesDataSection.expenses, setExpenses, payload);
  };

export const saveBillsAndLivingExpenses: SetDataActionCreator<
  ExpensesDataActionType.SET_BILLS_AND_LIVING_EXPENSES,
  BillsAndLivingExpenses
> =
  (payload: BillsAndLivingExpenses): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveExpensesSectionData(
      dispatch,
      getState(),
      ExpensesDataSection.billsAndLivingExpenses,
      setBillsAndLivingExpenses,
      payload
    );
  };

const saveExpensesSectionData = (
  dispatch: Dispatch,
  state: ApplicationState,
  section: ExpensesDataSection,
  actionCreator: SetDataActionCreator<ExpensesDataActionType, any>,
  payload: Income | Expenses | BillsAndLivingExpenses
) => {
  const session = getSession(state) as Session;
  dispatch(setIsDataLoading(true));

  return saveExpensesDataToDataStorage(payload, session, section)
    .then(() => {
      dispatch(actionCreator(payload));
      openNextExpensesSection(section, state);
    })
    .catch((e) => dispatch(setDataLoadError({ type: DataLoadErrorType.LOAD_ERROR, error: e })))
    .finally(() => dispatch(setIsDataLoading(false)));
};
