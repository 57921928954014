import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../../../../components';
import { EmptyOption, FORM_EMPTY_VALUE, isPreviousAddressActive } from '../../../formUtils';
import { Checkbox, DatePicker, Heading, Input, Paragraph, Select } from '@vwfs-bronson/bronson-react';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import BaseAddressFieldset from './BaseAddressFieldset';
import { FormikProps } from 'formik';
import { AustralianStateKey } from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { AddressType } from '@bus/models/dist/enums/new/address-type.enum';
import { DATE_FORMAT } from '../../../../../config';
import { get } from 'lodash';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import { mobileCountryCodeEntries } from '../../../../../services/utils';

export const BaseContactDataFieldset = ({ contactDataNamePrefix = '' }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Fieldset>
        <Layout>
          <Layout.Item default="1/3">
            <FormField
              name={`${contactDataNamePrefix}mobilePhoneCountryCode`}
              type="select"
              labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneCountryCode:label')}
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {mobileCountryCodeEntries.map(([code, label]) => (
                    <Select.Item key={code} value={label}>
                      {code} {label}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${contactDataNamePrefix}mobilePhoneNumber`}
              type="input"
              labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneNumber:label')}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${contactDataNamePrefix}email`}
              type="input"
              labelText={t('formsData:formSections:contactInformation:fields:email:label')}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>
    </>
  );
};

interface Props {
  addressNamePrefix: string;
  contactDataNamePrefix: string;
  formikProps: FormikProps<any>;
  residentSinceLabel?: boolean;
  isAddressOnly?: boolean;
  withCountrySelection?: boolean;
  noPreviousAddress?: boolean;
}

export const BaseContactInformationFieldset = (props: Props): JSX.Element => {
  const {
    addressNamePrefix = '',
    contactDataNamePrefix = '',
    formikProps,
    isAddressOnly = false,
    withCountrySelection = false,
    noPreviousAddress = false,
    residentSinceLabel = false,
  } = props;
  const { t } = useTranslation();

  const isReadonly = useContext(IsReadonlyFormContext);

  const onResidentSinceDateChange = (date: string, values: FormikProps<any>['values']) => {
    if (noPreviousAddress) {
      formikProps.setFieldValue(`${addressNamePrefix}addresses[0].residentSinceDate`, date);
      return;
    }
    if (isPreviousAddressActive(date)) {
      const addresses = [
        { ...get(values, `${addressNamePrefix}addresses[0]`), residentSinceDate: date },
        {
          street: FORM_EMPTY_VALUE,
          houseNumber: FORM_EMPTY_VALUE,
          optionalLine: FORM_EMPTY_VALUE,
          zipCode: FORM_EMPTY_VALUE,
          city: FORM_EMPTY_VALUE,
          state: FORM_EMPTY_VALUE as AustralianStateKey,
          addressType: AddressType.PRIMARY,
        },
        get(values, `${addressNamePrefix}addresses[2]`, null),
      ];
      formikProps.setFieldValue(`${addressNamePrefix}addresses`, addresses);
    } else {
      const addresses = [
        { ...get(values, `${addressNamePrefix}addresses[0]`), residentSinceDate: date },
        null,
        get(values, `${addressNamePrefix}addresses[2]`, null),
      ];
      formikProps.setFieldValue(`${addressNamePrefix}addresses`, addresses);
    }
  };
  const isShippingAddressChecked = () => {
    const shippingAddress = get(formikProps.values, `${addressNamePrefix}addresses[2]`);
    return Boolean(shippingAddress);
  };

  const toggleShippingAddress = (checked: boolean) => {
    const currentAddresses = get(formikProps.values, `${addressNamePrefix}addresses`);
    if (checked) {
      formikProps.setFieldValue(`${addressNamePrefix}addresses`, [
        currentAddresses[0],
        currentAddresses[1],
        {
          street: FORM_EMPTY_VALUE,
          houseNumber: FORM_EMPTY_VALUE,
          optionalLine: FORM_EMPTY_VALUE,
          zipCode: FORM_EMPTY_VALUE,
          city: FORM_EMPTY_VALUE,
          state: FORM_EMPTY_VALUE as AustralianStateKey,
          addressType: AddressType.SHIPPING,
        },
      ]);
    } else {
      formikProps.setFieldValue(`${addressNamePrefix}addresses`, [currentAddresses[0], currentAddresses[1], null]);
    }
  };

  return (
    <>
      <BaseAddressFieldset namePrefix={`${addressNamePrefix}addresses[0].`} />
      {!isAddressOnly && <BaseContactDataFieldset contactDataNamePrefix={contactDataNamePrefix} />}
      <Fieldset>
        <Layout>
          <Layout.Item default="1/3" s="1/1">
            <FormField
              name={`${addressNamePrefix}addresses[0].residentSinceDate`}
              type="input"
              labelText={
                residentSinceLabel
                  ? t('formsData:formSections:contactInformation:fields:residentSinceDate:label')
                  : t('formsData:formSections:contactInformation:fields:durationAtAddress:label')
              }
              notion
              render={(fieldProps) => (
                <DatePicker
                  {...fieldProps}
                  id="resident-since-date"
                  dateFormat={DATE_FORMAT}
                  locale="en"
                  onChange={(val: string) => {
                    onResidentSinceDateChange(val, formikProps.values);
                  }}
                  onBlur={(val: string) => {
                    formikProps.handleBlur(`${addressNamePrefix}addresses[0].residentSinceDate`);
                    onResidentSinceDateChange(val, formikProps.values);
                  }}
                />
              )}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>

      {!noPreviousAddress &&
        isPreviousAddressActive(get(formikProps.values, `${addressNamePrefix}addresses[0].residentSinceDate`)) && (
          <>
            {!isReadonly && (
              <Heading level={6}>{t('formsData:formSections:contactInformation:formPreviousResidencyHint')}</Heading>
            )}
            {isReadonly && (
              <Heading level={6}>
                {t('formsData:formSections:contactInformation:formPreviousResidencyHintReadonly')}
              </Heading>
            )}

            <BaseAddressFieldset
              namePrefix={`${addressNamePrefix}addresses[1].`}
              withCountrySelection={withCountrySelection}
            />
          </>
        )}

      <Fieldset>
        {!isReadonly && (
          <Fieldset.Row>
            <Checkbox
              className="shipping-address-checkbox u-text-left"
              checked={isShippingAddressChecked()}
              onChange={(event: Event) => toggleShippingAddress((event.target as any).checked)}
            >
              {t('formsData:formSections:contactInformation:fields:hasShippingAddress:label')}
            </Checkbox>
          </Fieldset.Row>
        )}
        {isShippingAddressChecked() && (
          <>
            {isReadonly && (
              <Paragraph>
                {t('formsData:formSections:contactInformation:fields:hasShippingAddress:labelReadonly')}
              </Paragraph>
            )}
            <BaseAddressFieldset namePrefix={`${addressNamePrefix}addresses[2].`} />
          </>
        )}
      </Fieldset>
    </>
  );
};
