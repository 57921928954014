import { analytics } from '../../../analytics/analytics';
import { ApplicationState } from '../../ApplicationState';

export const enum DataLoadErrorType {
  LOAD_ERROR = 'LOAD_ERROR',
  SAVE_ERROR = 'SAVE_ERROR',
}

export interface UIState {
  dataLoadError: {
    type: DataLoadErrorType;
    error?: any;
  } | null;
  pendingDataRequests: number;
  isStorefrontDataLoaded: boolean;
}

const initialState: UIState = {
  dataLoadError: null,
  pendingDataRequests: 0,
  isStorefrontDataLoaded: false,
};

enum UIActionType {
  SET_DATA_LOAD_ERROR = 'UI/SET_DATA_LOAD_ERROR',
  SET_IS_DATA_LOADING = 'UI/SET_IS_DATA_LOADING',
  SET_IS_STOREFRONT_DATA_LOADED = 'UI/SET_IS_STOREFRONT_DATA_LOADED',
}

export const setDataLoadError = (payload: UIState['dataLoadError']) => {
  analytics.trackAPIError(payload?.error);
  return {
    type: UIActionType.SET_DATA_LOAD_ERROR,
    payload,
  };
};
export const setIsDataLoading = (payload: boolean) => ({
  type: UIActionType.SET_IS_DATA_LOADING,
  payload,
});
export const setIsStorefrontDataLoaded = () => ({
  type: UIActionType.SET_IS_STOREFRONT_DATA_LOADED,
});

export const getDataLoadError = (state: ApplicationState) => state.UI.dataLoadError;
export const getIsDataLoading = (state: ApplicationState) => state.UI.pendingDataRequests > 0;
export const getIsStorefrontDataLoaded = (state: ApplicationState) => state.UI.isStorefrontDataLoaded;

export default function (state = initialState, action: { type: UIActionType; payload?: any }): UIState {
  switch (action.type) {
    case UIActionType.SET_DATA_LOAD_ERROR:
      return {
        ...state,
        dataLoadError: action.payload,
      };
    case UIActionType.SET_IS_DATA_LOADING:
      return {
        ...state,
        pendingDataRequests: action.payload ? state.pendingDataRequests + 1 : state.pendingDataRequests - 1,
      };
    case UIActionType.SET_IS_STOREFRONT_DATA_LOADED:
      return {
        ...state,
        isStorefrontDataLoaded: true,
      };

    default:
      return state;
  }
}
