import { UserType } from '../Session/session.model';

export enum TransactionStatus {
  INITIALIZED = 'INITIALIZED',
  APPLICATIONFORM_STARTED = 'APPLICATIONFORM_STARTED',
  COA_CHECK_COMPLETED = 'COA_CHECK_COMPLETED',
  INITIAL_COA_DATA_SUBMITTED = 'INITIAL_COA_DATA_SUBMITTED',
  APPLICATIONFORM_COMPLETED = 'APPLICATIONFORM_COMPLETED',

  PRIVACY_ESIGN_STARTED = 'PRIVACY_ESIGN_STARTED',
  PRIVACY_ESIGN_ASSUMED_SUBMITTED = 'PRIVACY_ESIGN_ASSUMED_SUBMITTED',

  ID_AML_PENDING = 'ID_AML_PENDING',
  ID_AML_DECLINED = 'ID_AML_DECLINED',
  ID_AML_REFERRED = 'ID_AML_REFERRED',
  ID_AML_SUCCESSFUL = 'ID_AML_SUCCESSFUL',
  ODS_ASSUMED_SUBMITTED = 'ODS_ASSUMED_SUBMITTED',
  INCOME_EXPENSE_SUBMITTED = 'INCOME_EXPENSE_SUBMITTED',

  CREDIT_APPROVED = 'CREDIT_APPROVED',
  CREDIT_APPROVED_OBLIGATIONS = 'CREDIT_APPROVED_OBLIGATIONS',
  CREDIT_PENDING = 'CREDIT_PENDING',
  CREDIT_REFUSED = 'CREDIT_REFUSED',
  CREDIT_PENDING_CUSTOM_EMAIL = 'CREDIT_PENDING_CUSTOM_EMAIL',
  DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
  LEGITIMATION_REFUSED = 'LEGITIMATION_REFUSED',
  LEGITIMATION_CONDUCTED = 'LEGITIMATION_CONDUCTED',
  BOP_COMPLETED = 'BOP_COMPLETED',
  E_SIGN_READY = 'ESIGN_READY',
  E_SIGN_REFUSED = 'ESIGN_REFUSED',
  E_SIGN_COMPLETED = 'ESIGN_COMPLETED',
}

export interface LandingPageConsents {
  personalDataAcceptance?: boolean;
  allowAds?: boolean;
}

export interface DataPrivacyStatement {
  idAmlCheckConsentAcceptance?: boolean;
  odsCheckConsentAcceptance?: boolean;
  dataPrivacyConsentAcceptance?: boolean;
  dataPrivacyConsentAcceptanceDate?: string;
  personalDataAcceptance?: boolean;
  allowAds?: boolean;
}

interface UserTransaction {
  status: TransactionStatus;
  userId: string;
}

export interface Transaction {
  brand?: string;
  product?: string;
  status: TransactionStatus | null;
  storefrontCallbackURL?: string;
  multiApplicantJourney?: boolean;
  userTransactions: {
    [key in UserType]?: UserTransaction;
  };
}
