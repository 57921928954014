import { FinancialProduct } from './financialProduct.model';
import { SetDataActionCreator } from '../../utils';

export enum FinancialProductActionType {
  SET = 'financialProduct/SET',
  GET = 'financialProduct/GET',
}

export interface SetFinancialProductAction {
  type: FinancialProductActionType.SET;
  payload: FinancialProduct;
}
export interface GetFinancialProductAction {
  type: FinancialProductActionType.GET;
  payload: FinancialProduct;
}

export const setFinancialProduct: SetDataActionCreator<FinancialProductActionType.SET, FinancialProduct> = (
  payload
) => {
  return { type: FinancialProductActionType.SET, payload };
};

export type FinancialProductAction = SetFinancialProductAction | GetFinancialProductAction;
