export enum FlauRoute {
  authenticate = '/authenticate',
  landingPage = '/landing',

  formsData = `/form/forms-data`,
  formsDataSummary = `/form/forms-data/summary`,

  coApplicantCheck = '/form/coapplicant/check',
  coApplicantAmlData = '/form/coapplicant/data',

  odsChoice = '/ods-choice',
  odsLanding = '/ods',

  expenses = '/expenses',
  expensesSummary = '/expenses/summary',

  applicationSubmit = '/application/submit',

  processAmlDeclined = '/form/amlCheck/declined',

  upload = '/form/upload',
  uploadAdditional = '/form/upload/additional',

  legitimationLanding = '/form/legitimation',
  legitimationCallback = '/form/legitimation/redirect',
  processAppSubmittedSuccess = '/form/formsData/submitted',
  processLegitimationSuccessful = '/form/legitimation/success',
  processLegitimationRefused = '/form/legitimation/failure',
  processCreditApproved = '/form/creditCheck/success',
  processCreditPreliminaryApproved = '/form/creditCheck/preliminary',
  processCreditRefused = '/form/creditCheck/fail',
  processAppSubmittedDocs = '/form/upload/success',

  esignLanding = '/esign-start', // such path required in FLGFT-6038
  esignRefused = '/esign_refused', // such path required in FLGFT-6039
  esignSuccessful = '/esign_successful',

  error = '/error',
  validationFailed = '/failed',
  linkExpired = '/expired',
  amlCheck = '/aml-check',
}

export const noAuthRoutes = new Set([
  FlauRoute.legitimationCallback,
  FlauRoute.esignLanding,
  FlauRoute.esignRefused,
  FlauRoute.esignSuccessful,
  FlauRoute.linkExpired,
]);
