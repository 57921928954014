import { PhoneCountryCode } from '@bus/models/dist/enums/general/phone-country-code.enum';

const numbersThousandsFormatSeparator = ',';

export const capitalize = (phrase: string): string => {
  return phrase.charAt(0).toUpperCase() + phrase.slice(1);
};

const formatInteger = (number: number, toFixed?: boolean): string | null => {
  if (number !== undefined) {
    return parseFloat(String(number))
      .toFixed(parseInt(String(number), 10) !== number || toFixed ? 2 : 0)
      .toString()
      .replace(/\./g, '.')
      .replace(/\D,+/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${numbersThousandsFormatSeparator}`);
  }
  return null;
};

export const formatPrice = (val: number, unit = '$'): string => {
  if (val !== undefined) {
    return formatInteger(val) ? `${unit}${formatInteger(val)}` : '';
  }
  return '';
};

export const formatMonths = (val: number): string => {
  if (val !== undefined) {
    return `${val} months`;
  }
  return '';
};

export const formatRate = (val: number): string => {
  if (val !== undefined) {
    return formatInteger(val) ? `${formatInteger(val)}% p.a` : '';
  }
  return '';
};

export const formatMileage = (val: number): string => {
  if (val !== undefined) {
    return parseFloat(String(val))
      .toString()
      .replace(/\./g, '.')
      .replace(/\D,+/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${' '}`);
  }
  return '';
};

export const resetURLHash = () => {
  window.history.pushState('', document.title, window.location.pathname);
};

export const mobileCountryCodeEntries = Object.entries(PhoneCountryCode);
