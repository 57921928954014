import { SetDataAction } from '../../utils';
import { ExpensesData, ExpensesDataSection } from './expenses.model';
import { ExpensesDataActionType } from './expenses.actions';
import { FORM_EMPTY_VALUE } from '../../../../screens/FormPage/formUtils';

// Empty strings since formik requires "some" initial value for its controlled components
export const expensesDataInitialState: ExpensesData = {
  [ExpensesDataSection.income]: {
    financialDisclosure: {
      annualInvestedIncome: FORM_EMPTY_VALUE as any,
      monthlyIncome: {
        netIncomeAmount: FORM_EMPTY_VALUE as any,
        commissions: FORM_EMPTY_VALUE as any,
        additionalIncomeAmount: FORM_EMPTY_VALUE as any,
        rental: FORM_EMPTY_VALUE as any,
        benefits: FORM_EMPTY_VALUE as any,
        bonus: FORM_EMPTY_VALUE as any,
        overtime: FORM_EMPTY_VALUE as any,
        netHouseholdIncomeAmount: FORM_EMPTY_VALUE as any,
      },
    },
  },
  [ExpensesDataSection.expenses]: {
    financialDisclosure: {
      monthlyExpenses: {
        remoteLivingExpenses: FORM_EMPTY_VALUE as any,
        educationFee: FORM_EMPTY_VALUE as any,
        alimonyAmount: FORM_EMPTY_VALUE as any,
        rentAmount: FORM_EMPTY_VALUE as any,
      },
    },
  },
  [ExpensesDataSection.billsAndLivingExpenses]: {
    financialDisclosure: {
      monthlyExpenses: {
        groceries: FORM_EMPTY_VALUE as any,
        transportation: FORM_EMPTY_VALUE as any,
        clothing: FORM_EMPTY_VALUE as any,
        privateHealthcareAmount: FORM_EMPTY_VALUE as any,
        insuranceAmount: FORM_EMPTY_VALUE as any,
        phoneInternet: FORM_EMPTY_VALUE as any,
        vacation: FORM_EMPTY_VALUE as any,
        sportActivities: FORM_EMPTY_VALUE as any,
        entertainmentLeisure: FORM_EMPTY_VALUE as any,
        alcoholTobaccoGambling: FORM_EMPTY_VALUE as any,
        additionalBillsLivingExpenseAmount: FORM_EMPTY_VALUE as any,
        additionalExpenseAmount: FORM_EMPTY_VALUE as any,
      },
    },
  },
};

// eslint-disable-next-line default-param-last
export default function (
  state = expensesDataInitialState,
  action: SetDataAction<ExpensesDataActionType, any>
): ExpensesData {
  switch (action.type) {
    case ExpensesDataActionType.SET_INCOME:
      return {
        ...state,
        [ExpensesDataSection.income]: action.payload,
      };
    case ExpensesDataActionType.SET_EXPENSES:
      return {
        ...state,
        [ExpensesDataSection.expenses]: action.payload,
      };
    case ExpensesDataActionType.SET_BILLS_AND_LIVING_EXPENSES:
      return {
        ...state,
        [ExpensesDataSection.billsAndLivingExpenses]: action.payload,
      };

    default:
      return state;
  }
}
