import { FinancialProduct } from './financialProduct.model';
import { FinancialProductAction, FinancialProductActionType } from './financialProduct.actions';

const financialProductInitialState: FinancialProduct = {
  calculation: {
    duration: 0,
    yearlyMileage: 0,
    effectiveInterestRate: 0,
    balloonPayment: 0,
    netLoanAmount: 0,
    grossLoanAmount: 0,
    guaranteedBuyBackPrice: 0,
    subsequentInstallmentAmount: {
      amount: 0,
      paymentFrequency: '',
      currency: '',
    },
    downPaymentAmount: 0,
  },
  detailedName: null,
  disclaimer: '',
};

// eslint-disable-next-line default-param-last
export default function (state = financialProductInitialState, action: FinancialProductAction): FinancialProduct {
  switch (action.type) {
    case FinancialProductActionType.SET:
      return {
        ...state,
        ...action.payload,
      };
    case FinancialProductActionType.GET:
      return state;
    default:
      return state;
  }
}
