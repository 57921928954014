import { Dispatch } from 'redux';
import { openNextFormSection } from '../../../../screens/FormPage/formUtils';
import { analytics } from '../../../analytics/analytics';
import * as formsDataService from '../../../api/formsData/formsData.service';
import { ApplicationState } from '../../ApplicationState';
import { SetDataActionCreator } from '../../utils';
import { Session, UserType } from '../Session/session.model';
import { getSession } from '../Session/session.selectors';
import { setTransaction, setUserTransactionStatus } from '../Transaction/transaction.actions';
import { DataPrivacyStatement, LandingPageConsents, TransactionStatus } from '../Transaction/transaction.model';
import { DataLoadErrorType, setDataLoadError, setIsDataLoading } from '../UI/UI.reducer';
import {
  Assets,
  CoApplicantAmlData,
  CompanyInformation,
  ContactInformation,
  EmploymentInformation,
  EntityLegalForm,
  FormDataSection,
  Liabilities,
  PartnerType,
  PersonalData,
  ReferenceContacts,
} from './formsData.model';
import { getTransactionDataFromDataStorage } from '../Transaction/transaction.mapper';
import { DataStorageData, OdsFinancialVerificationChoice } from '../../dataStorage.model';
import { setDataStorageRawData } from '../DataStorageRawData/dataStorageRawData.reducer';
import { saveDataPrivacyStatementToDS } from '../../../api/formsData/formsData.service';

export enum FormsDataActionType {
  SET_PERSONAL_DATA = 'formsData/SET_PERSONAL_DATA',
  SET_CONTACT_INFORMATION = 'formsData/SET_CONTACT_INFORMATION',
  SET_COMPANY_DATA = 'formsData/SET_COMPANY_DATA',
  SET_EMPLOYMENT_INFORMATION = 'formsData/SET_EMPLOYMENT_INFORMATION',
  SET_ASSETS = 'formsData/SET_ASSETS',
  SET_LIABILITIES = 'formsData/SET_LIABILITIES',
  SET_INITIAL_CONSENTS = 'formsData/SET_INITIAL_CONSENTS',
  UPDATE_DATA_PRIVACY_STATEMENT = 'formsData/SET_DATA_PRIVACY_STATEMENT',
  SET_CO_APPLICANT_DATA = 'formsData/SET_CO_APPLICANT_DATA',
  PERFORM_AML_CHECK = 'formsData/PERFORM_AML_CHECK',
  CHECK_AML_STATUS = 'formsData/CHECK_AML_STATUS',
  SET_REFERENCE_CONTACTS = 'formsData/SET_REFERENCE_CONTACTS',
  REMOVE_COAPPLICANT = 'formsData/REMOVE_COAPPLICANT',
}

export const setInitialConsents: SetDataActionCreator<FormsDataActionType.SET_INITIAL_CONSENTS, LandingPageConsents> = (
  payload
) => {
  analytics.extendTrackingData({
    dataPrivacyStatement: {
      allowPostalAds: payload.allowAds,
      allowPhoneAds: payload.allowAds,
      allowElectronicAds: payload.allowAds,
      allowElectronicInvoices: payload.allowAds,
      allowMailAds: payload.allowAds,
    },
  });
  return { type: FormsDataActionType.SET_INITIAL_CONSENTS, payload };
};

export const updateDataPrivacyStatement: SetDataActionCreator<
  FormsDataActionType.UPDATE_DATA_PRIVACY_STATEMENT,
  DataPrivacyStatement
> = (payload) => {
  return { type: FormsDataActionType.UPDATE_DATA_PRIVACY_STATEMENT, payload };
};

export const setPersonalData: SetDataActionCreator<FormsDataActionType.SET_PERSONAL_DATA, PersonalData> = (payload) => {
  let maturityLevel: string | null = null;
  if (payload.partnerType) {
    if (
      payload.partnerType === PartnerType.PRIVATE ||
      (payload.partnerType === PartnerType.BUSINESS &&
        ![EntityLegalForm.TRUST].includes(payload.companyData?.entityLegalForm as EntityLegalForm))
    ) {
      maturityLevel = 'Online contract';
    } else {
      maturityLevel = 'Lead';
    }
  }
  analytics.extendTrackingData({
    core: {
      category: {
        maturityLevel: maturityLevel as any,
      },
    },
    customerData: {
      gender: payload.gender,
      loggedInUserGroup: payload.partnerType as PartnerType,
      yearOfBirth: (payload.dateOfBirth || '').split('.')[2] || null,
    },
  });
  return { type: FormsDataActionType.SET_PERSONAL_DATA, payload };
};

export const setContactInformation: SetDataActionCreator<
  FormsDataActionType.SET_CONTACT_INFORMATION,
  ContactInformation
> = (payload) => {
  analytics.extendTrackingData({
    customerData: {
      address: {
        zipCode: payload.addresses?.[0]?.zipCode || null,
      },
    },
  });
  return { type: FormsDataActionType.SET_CONTACT_INFORMATION, payload };
};

export const setCompanyData: SetDataActionCreator<FormsDataActionType.SET_COMPANY_DATA, CompanyInformation> = (
  payload
) => {
  return { type: FormsDataActionType.SET_COMPANY_DATA, payload };
};

export const setEmploymentInformation: SetDataActionCreator<
  FormsDataActionType.SET_EMPLOYMENT_INFORMATION,
  EmploymentInformation
> = (payload) => {
  return { type: FormsDataActionType.SET_EMPLOYMENT_INFORMATION, payload };
};

export const setAssets: SetDataActionCreator<FormsDataActionType.SET_ASSETS, Assets> = (payload) => {
  return { type: FormsDataActionType.SET_ASSETS, payload };
};

export const setLiabilities: SetDataActionCreator<FormsDataActionType.SET_LIABILITIES, Liabilities> = (payload) => {
  return { type: FormsDataActionType.SET_LIABILITIES, payload };
};

export const setCoApplicantAmlData: SetDataActionCreator<
  FormsDataActionType.SET_CO_APPLICANT_DATA,
  CoApplicantAmlData
> = (payload) => {
  return { type: FormsDataActionType.SET_CO_APPLICANT_DATA, payload };
};

export const setReferenceContacts: SetDataActionCreator<
  FormsDataActionType.SET_REFERENCE_CONTACTS,
  ReferenceContacts
> = (payload) => {
  return {
    type: FormsDataActionType.SET_REFERENCE_CONTACTS,
    payload,
  };
};

export const saveDataPrivacyStatement =
  (dataPrivacyStatement: Partial<DataPrivacyStatement>): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    dispatch(setIsDataLoading(true));

    return saveDataPrivacyStatementToDS(dataPrivacyStatement, getSession(state) as Session)
      .then((dataStorageData: DataStorageData) => {
        dispatch(setDataStorageRawData(dataStorageData));
        dispatch(updateDataPrivacyStatement(dataPrivacyStatement));
      })
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
        throw e;
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };

const saveFormSectionData = (
  dispatch: Dispatch,
  state: ApplicationState,
  section: FormDataSection,
  actionCreator: SetDataActionCreator<FormsDataActionType, any>,
  payload: any
) => {
  const session = getSession(state) as Session;
  dispatch(setIsDataLoading(true));

  return formsDataService
    .saveFormDataToDataStorage(payload, session, section)
    .then(() => {
      dispatch(actionCreator(payload));
      openNextFormSection(section, state);
    })
    .catch((e) => dispatch(setDataLoadError({ type: DataLoadErrorType.LOAD_ERROR, error: e })))
    .finally(() => dispatch(setIsDataLoading(false)));
};
export const submitLandingPage =
  (consents: LandingPageConsents): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    dispatch(setIsDataLoading(true));
    const session = getSession(state) as Session;

    return formsDataService
      .saveInitialData(consents, session)
      .then(() => {
        dispatch(setInitialConsents(consents));
        dispatch(
          setUserTransactionStatus({
            status: TransactionStatus.APPLICATIONFORM_STARTED,
            userType: session.userType as UserType,
          })
        );
      })
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
        throw e;
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };

export const submitForms = (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
  const state = getState();
  dispatch(setIsDataLoading(true));
  const session = getSession(state) as Session;

  return formsDataService
    .saveDataPrivacyAndSubmit(session)
    .then(() => {
      dispatch(
        setUserTransactionStatus({
          status: TransactionStatus.APPLICATIONFORM_COMPLETED,
          userType: session.userType as UserType,
        })
      );
    })
    .catch((e) => {
      dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
      throw e;
    })
    .finally(() => {
      dispatch(setIsDataLoading(false));
    });
};

export const savePersonalData: SetDataActionCreator<FormsDataActionType.SET_PERSONAL_DATA, PersonalData> =
  (payload: PersonalData): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(dispatch, getState(), FormDataSection.personalData, setPersonalData, payload);
  };

export const saveCompanyInformation: SetDataActionCreator<FormsDataActionType.SET_COMPANY_DATA, CompanyInformation> =
  (payload: CompanyInformation): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(dispatch, getState(), FormDataSection.companyInformation, setCompanyData, payload);
  };

export const saveReferenceContacts: SetDataActionCreator<
  FormsDataActionType.SET_REFERENCE_CONTACTS,
  ReferenceContacts
> =
  (payload: ReferenceContacts): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(dispatch, getState(), FormDataSection.referenceContacts, setReferenceContacts, payload);
  };

export const saveContactInformation: SetDataActionCreator<
  FormsDataActionType.SET_CONTACT_INFORMATION,
  ContactInformation
> =
  (payload: ContactInformation): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(
      dispatch,
      getState(),
      FormDataSection.contactInformation,
      setContactInformation,
      payload
    );
  };

export const saveEmploymentInformation: SetDataActionCreator<
  FormsDataActionType.SET_EMPLOYMENT_INFORMATION,
  EmploymentInformation
> =
  (payload: EmploymentInformation): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(
      dispatch,
      getState(),
      FormDataSection.employmentInformation,
      setEmploymentInformation,
      payload
    );
  };

export const saveAssets: SetDataActionCreator<FormsDataActionType.SET_ASSETS, Assets> =
  (payload: Assets): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(dispatch, getState(), FormDataSection.assets, setAssets, payload);
  };

export const saveLiabilities: SetDataActionCreator<FormsDataActionType.SET_LIABILITIES, Liabilities> =
  (payload: Liabilities): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    return saveFormSectionData(dispatch, getState(), FormDataSection.liabilities, setLiabilities, payload);
  };

export const createCoApplicant: SetDataActionCreator<FormsDataActionType.SET_CO_APPLICANT_DATA, CoApplicantAmlData> =
  (payload): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    dispatch(setIsDataLoading(true));
    return formsDataService
      .saveInitialCoApplicantData(payload, state.session)
      .then(() => {
        dispatch(setCoApplicantAmlData(payload));
        dispatch(
          setUserTransactionStatus({ status: TransactionStatus.INITIAL_COA_DATA_SUBMITTED, userType: UserType.PRIMARY })
        );
      })
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.LOAD_ERROR, error: e }));
        throw e;
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };

export const performAmlCheck: SetDataActionCreator<FormsDataActionType.PERFORM_AML_CHECK, void> =
  (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    return formsDataService.startAmlCheck(state.session).catch((e) => {
      if (e?.code === 'ECONNABORTED' || e?.response?.status === 504) {
        // ignore the timeout in case illion needed more time to respond
        console.log(e);
        return;
      }
      dispatch(setDataLoadError({ type: DataLoadErrorType.LOAD_ERROR, error: e }));
    });
  };

export const removeCoApplicantData: SetDataActionCreator<FormsDataActionType.REMOVE_COAPPLICANT, void> = () => {
  return {
    type: FormsDataActionType.REMOVE_COAPPLICANT,
    payload: undefined,
  };
};

export const checkAmlStatus: SetDataActionCreator<FormsDataActionType.CHECK_AML_STATUS, void> =
  (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    return formsDataService
      .loadDataStorageData(state.session.id as string, state.session.userId as string, state.session.token as string)
      .then((dataStorage) => {
        dispatch(setTransaction(getTransactionDataFromDataStorage(dataStorage.data)));
      })
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.LOAD_ERROR, error: e }));
      });
  };

export const saveOdsFinancialVerificationChoice =
  (odsFinancialVerificationChoice: OdsFinancialVerificationChoice) =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    dispatch(setIsDataLoading(true));
    const session = getSession(state) as Session;
    return formsDataService
      .saveOdsVerificationChoice({ odsFinancialVerificationChoice }, session)
      .then((dataStorageData) => {
        dispatch(setDataStorageRawData(dataStorageData));
      })
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
        throw e;
      })
      .finally(() => {
        dispatch(setIsDataLoading(false));
      });
  };
