import { SetDataAction } from '../../utils';
import { UploadActionType } from './upload.actions';
import { MediaStorageFile } from './upload.model';

const initialDocumentsState: MediaStorageFile[] = [];

export default function (
  state = initialDocumentsState,
  action: SetDataAction<UploadActionType, any>
): MediaStorageFile[] {
  switch (action.type) {
    case UploadActionType.SAVE_FILE_METADATA:
      return [...state, action.payload];
    case UploadActionType.DELETE_FILE_METADATA:
      return state.filter((file) => file.id !== action.payload);
    case UploadActionType.SAVE_FILES:
      return [...action.payload];
    default:
      return state;
  }
}
