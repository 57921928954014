import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface ComponentProps {
  children: string;
  props?: HTMLSpanElement;
}

const HtmlContent: React.FC<ComponentProps> = function ({ children, ...props }) {
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    const href = e.target.getAttribute('href');
    if (href && href.startsWith('/')) {
      e.preventDefault();
      navigate(href);
    }
  };
  return <span {...props} role="presentation" onClick={handleClick} dangerouslySetInnerHTML={{ __html: children }} />;
};

export default HtmlContent;
