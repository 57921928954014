import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LogoComponentProps {
  href: string;
  alt: string | undefined;
  logoSrc: string | undefined;
  sLogoSrc: string | undefined;
}

export const Logo: React.FC<LogoComponentProps> = function ({ href, alt, logoSrc, sLogoSrc }) {
  const content = (
    <picture>
      <source srcSet={sLogoSrc} media="(max-width: 768px)" />
      <img src={logoSrc} alt={alt} className="c-logo__image" />
    </picture>
  );
  if (href) {
    return (
      <div className="c-vertical-bar__logo">
        <RouterLink className="c-logo  c-logo--has-image" to={href}>
          {content}
        </RouterLink>
      </div>
    );
  }
  return <div className="c-vertical-bar__logo">{content}</div>;
};

interface ButtonComponentProps {
  ariaHidden: boolean;
  onClick: () => void;
}

const Button: React.FC<ButtonComponentProps> = function ({ ariaHidden, onClick }) {
  return (
    <button
      className="c-vertical-bar__toggle  js-vertical-bar__toggle"
      aria-expanded={!ariaHidden}
      onClick={onClick}
      type="button"
      title="Show navigation"
    >
      <i className="c-icon  c-icon--[menu]" aria-hidden={ariaHidden} />
    </button>
  );
};

interface VerticalBarItemComponentProps {
  href: string;
  icon: string;
  children: React.ReactNode;
}

const VerticalBarItem: React.FC<VerticalBarItemComponentProps> = function ({ icon, href, children }) {
  return (
    <RouterLink className="c-vertical-bar__nav-link" to={href}>
      <i className={`c-vertical-bar__nav-link-icon  c-icon  c-icon--[${icon}]`} aria-hidden="true" />
      <span className="c-vertical-bar__nav-link-label">{children}</span>
    </RouterLink>
  );
};

interface VerticalBarComponentProps {
  href: string;
  children?: React.ReactNode;
  alt?: string | undefined;
  logoSrc: string | undefined;
  sLogoSrc: string | undefined;
}

const VerticalBar: React.FC<VerticalBarComponentProps> & {
  Item: typeof VerticalBarItem;
} = function ({ href = '#', children = null, alt = undefined, logoSrc = undefined, sLogoSrc = undefined }) {
  const [mobileMenuHidden, toggleMobileMenu] = React.useState(true);
  return (
    <nav className="c-vertical-bar  js-vertical-bar">
      <Logo href={href} alt={alt} logoSrc={logoSrc} sLogoSrc={sLogoSrc} />
      {children && <Button onClick={() => toggleMobileMenu(!mobileMenuHidden)} ariaHidden={mobileMenuHidden} />}
      {children && (
        <ul className="c-vertical-bar__nav  js-vertical-bar__nav" aria-hidden={mobileMenuHidden}>
          {React.Children.map(children, (child, index) => (
            // eslint-disable-next-line
            <li key={`${index}`} className="c-vertical-bar__nav-item">
              {child}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

VerticalBar.Item = VerticalBarItem;

export default VerticalBar;
