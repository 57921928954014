/* eslint-disable import/extensions */
import i18n from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';
import appContent from '../../resources/base/content/en/app-content.json';
import errorContent from '../../resources/base/content/en/error-content.json';
import formPageContent from '../../resources/base/content/en/form-page-content.json';
import landingPageContent from '../../resources/base/content/en/landing-page-content.json';
import formsDataContent from '../../resources/base/content/en/forms-data-content.json';
import coApplicantDataContent from '../../resources/base/content/en/coapplicant-data-content.json';
import amlCheckContent from '../../resources/base/content/en/aml-check-content.json';
import odsContent from '../../resources/base/content/en/ods-content.json';
import processPageContent from '../../resources/base/content/en/process-page-content.json';
import validationContent from '../../resources/base/content/en/validation-content.json';
import contactContent from '../../resources/base/content/en/contact-content.json';
import shoppingCartContent from '../../resources/base/content/en/shopping-cart-content.json';
import uploadPageContent from '../../resources/base/content/en/upload-page-content.json';
import esignContent from '../../resources/base/content/en/esign-content.json';

import { continueLaterLocalization } from '@vwfs-its/sf-sac-frontend';

const BRAND_NAME = process.env.REACT_APP_WEBSITE_BRAND;

const localization = continueLaterLocalization({
  locale: 'au',
  brandName: { BRAND_NAME },
  dataProtectionLink: 'https://www.vwfs.ie/LegalNotice.html',
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        app: appContent,
        error: errorContent,
        landingPage: landingPageContent,
        formPage: formPageContent,
        formsData: formsDataContent,
        coApplicantData: merge({}, formsDataContent, coApplicantDataContent),
        amlCheck: amlCheckContent,
        ods: odsContent,
        processPage: processPageContent,
        validation: validationContent,
        contact: contactContent,
        shoppingCart: shoppingCartContent,
        uploadPage: uploadPageContent,
        esign: esignContent,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.addResourceBundle('en', 'sf-sac', localization, true, true);

export default i18n;
