import { Select } from '@vwfs-bronson/bronson-react';
import { differenceInYears, parse } from 'date-fns';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormDataSection } from '../../services/redux/reducers/FormsData/formsData.model';
import { areSectionsActive } from '../../services/redux/reducers/FormsData/formsData.selectors';
import { ApplicationState } from '../../services/redux/ApplicationState';
import { expensesDataSections, formDataSections } from './formDataSections';
import { ExpensesDataSection, expensesSections } from '../../services/redux/reducers/Expenses/expenses.model';

export const isPreviousAddressActive = (date: string | null, minYears = 3): boolean => {
  if (!date) {
    return false;
  }
  const parsedResidentSince = parse(date, 'd-M-yyyy', new Date());
  return differenceInYears(new Date(), parsedResidentSince) < minYears;
};

export const FORM_EMPTY_VALUE = '';

export const EmptyOption: React.FC = () => {
  const { t } = useTranslation();
  return <Select.Item value={FORM_EMPTY_VALUE}>{t('formPage:empty-option-label')}</Select.Item>;
};

export const ScrollToFirstFieldError: React.FC = () => {
  const formikContext = useFormikContext();
  if (!formikContext) {
    return <></>;
  }
  const { submitCount, isSubmitting, isValidating, isValid, errors } = formikContext;
  const ref = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!isValid && !isSubmitting && !isValidating && submitCount > 0) {
      const errorElems = ref.current?.closest('.form-tile-anchor')?.getElementsByClassName('is-error');
      if (errorElems?.length) {
        setTimeout(() => {
          errorElems[0]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 0);
      }
    }
  }, [submitCount, isValidating, isSubmitting, errors]);

  return <input type="hidden" ref={ref} />;
};

export const FormFieldIdPrefixContext = React.createContext<string>('');

export const withFormFieldIdPrefixContext =
  (fieldIdPrefix: string) =>
  (WrappedComponent: React.FC<any>): React.FC<any> => {
    const wrapped: React.FC = (props) => (
      <FormFieldIdPrefixContext.Provider value={fieldIdPrefix}>
        <WrappedComponent {...props} />
      </FormFieldIdPrefixContext.Provider>
    );
    return wrapped;
  };

export const openNextFormSection = (currentSection: FormDataSection, state: ApplicationState) => {
  const activeSections = areSectionsActive(state);
  const configSections = formDataSections.filter((section) => activeSections[section.key]);
  const currentSectionIndex = configSections.findIndex((configSection) => configSection.key === currentSection);
  if (currentSectionIndex === configSections.length - 1) {
    window.location.hash = 'next-btn';
    return;
  }
  setTimeout(() => {
    window.location.hash = configSections[currentSectionIndex + 1].key;
  }, 200);
};

export const openNextExpensesSection = (currentSection: ExpensesDataSection, state: ApplicationState) => {
  const currentSectionIndex = expensesDataSections.findIndex((configSection) => configSection.key === currentSection);
  if (currentSectionIndex === expensesSections.length - 1) {
    window.location.hash = 'next-btn';
    return;
  }
  setTimeout(() => {
    window.location.hash = expensesDataSections[currentSectionIndex + 1].key;
  }, 200);
};

export const numberInputProps = {
  type: 'number',
  onKeyDown: (e: KeyboardEvent) => {
    if (e.key === 'e') {
      e.preventDefault();
    }
  },
};
