import { DataStorageData, DataStorageObject, OdsFinancialVerificationChoice } from '../../redux/dataStorage.model';
import { CoApplicantAmlData, FormDataSection } from '../../redux/reducers/FormsData/formsData.model';
import { Session } from '../../redux/reducers/Session/session.model';
import { DataPrivacyStatement, TransactionStatus } from '../../redux/reducers/Transaction/transaction.model';
import { axios } from '../index';

const { REACT_APP_WEBSITE_BRAND, REACT_APP_API_GATEWAY } = process.env;

export async function loadDataStorageData(id: string, userId: string, token: string): Promise<DataStorageObject> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/load/${id}/${userId}`;
  const res = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return res.data;
}

export function saveFormDataToDataStorage(
  data: any,
  session: Session,
  section: FormDataSection | 'dataPrivacy'
): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/${section}`;
  return axios.patch(url, data, {
    headers: {
      Authorization: session.token as string,
    },
  });
}

export function saveInitialData(data: DataPrivacyStatement, session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/initial-data`;
  return axios.put(
    url,
    { dataPrivacyStatement: data },
    {
      headers: {
        Authorization: session.token as string,
      },
    }
  );
}

export function saveDataPrivacyAndSubmit(session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/forms-submit`;
  return axios.post(
    url,
    {},
    {
      headers: {
        Authorization: session.token as string,
      },
    }
  );
}

export function saveDataPrivacyStatementToDS(
  dataPrivacyStatement: Partial<DataPrivacyStatement>,
  session: Session
): Promise<DataStorageData> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/data-privacy`;

  return axios
    .patch(
      url,
      { dataPrivacyStatement },
      {
        headers: {
          Authorization: session.token as string,
        },
      }
    )
    .then((res) => res.data);
}

export function changeDSTransactionStatus(status: TransactionStatus, session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/set-status/${status}`;
  return axios.put(url, null, {
    headers: {
      Authorization: session.token as string,
    },
  });
}

export function saveCoApplicantChoiceInDs(data: { isCoApplicantSelected: boolean }, session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/co-applicant-choice`;
  return axios.put(url, data, {
    headers: {
      Authorization: session.token as string,
    },
  });
}

export function saveInitialCoApplicantData(data: CoApplicantAmlData, session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/coapplicant`;
  return axios.patch(url, data, {
    headers: {
      Authorization: session.token as string,
    },
  });
}

export function saveOdsVerificationChoice(
  data: { odsFinancialVerificationChoice: OdsFinancialVerificationChoice },
  session: Session
): Promise<DataStorageData> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/ods-choice`;
  return axios
    .post(url, data, {
      headers: {
        Authorization: session.token as string,
      },
    })
    .then((res) => res.data);
}

export function startAmlCheck(session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/aml-check`;
  return axios.post(url, null, {
    headers: {
      Authorization: session.token as string,
    },
  });
}

export function removeCoApplicant(session: Session): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/co-applicant`;
  return axios.delete(url, {
    headers: {
      Authorization: session.token as string,
    },
  });
}
