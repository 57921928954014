import i18n from '../i18n/i18n';
import { AssetCustomType } from '../redux/reducers/FormsData/formsData.model';
import yup from './yup-extended';
import { FORM_EMPTY_VALUE } from '../../screens/FormPage/formUtils';

const requiredMessage = i18n.t('validation:required');
const minLengthMsg = i18n.t('validation:minLength', { value: 2 });
const maxLengthMsg = i18n.t('validation:maxLength', { value: 35 });
const maxLengthNoMsg = i18n.t('validation:maxLength', { value: 15 });
const lettersOnlyMsg = i18n.t('validation:lettersOnly');

const lettersOnlyRegEx = /^[\p{L}\s -.']+$/u;
const lettersAndNumbersOnlyRegEx = /^[0-9\p{L}\s -.,']+$/u;

const maxNumber = 999_999_999_999_999;
const minDescriptionLen = 2;
const maxDescriptionLen = 35;

const isAssetTypeRequired: Record<AssetCustomType, (hasOwnHouse?: boolean) => boolean> = {
  [AssetCustomType.SAVINGS]: () => true,
  [AssetCustomType.HOME]: (hasOwnHouse) => !!hasOwnHouse,
  [AssetCustomType.LAND]: () => false,
  [AssetCustomType.MOTOR_VEHICLES]: () => false,
  [AssetCustomType.SUPERANNUATION]: () => false,
  [AssetCustomType.INVESTMENT_PROPERTIES]: () => false,
  [AssetCustomType.OTHER]: () => false,
};

const assetValidator = (hasOwnHouse: boolean, assetType: AssetCustomType) => {
  if (isAssetTypeRequired[assetType](hasOwnHouse)) {
    return yup
      .object()
      .shape({
        type: yup.mixed(),
        customType: yup.mixed(),
        description: yup
          .string()
          .required(requiredMessage)
          .min(minDescriptionLen, minLengthMsg)
          .max(maxDescriptionLen, maxLengthMsg)
          .forbiddenCharacters(lettersOnlyMsg)
          .matches(assetType === AssetCustomType.HOME ? lettersAndNumbersOnlyRegEx : lettersOnlyRegEx, lettersOnlyMsg),
        value: yup.number().min(0, requiredMessage).max(maxNumber, maxLengthNoMsg).required(requiredMessage),
      })
      .required(requiredMessage);
  } else {
    return yup.object().shape({
      type: yup.mixed(),
      customType: yup.mixed(),
      description: yup
        .string()
        .transform((val) => val || null)
        .nullable()
        .min(minDescriptionLen, minLengthMsg)
        .max(maxDescriptionLen, maxLengthMsg)
        .forbiddenCharacters(lettersOnlyMsg)
        .matches(assetType === AssetCustomType.HOME ? lettersAndNumbersOnlyRegEx : lettersOnlyRegEx, lettersOnlyMsg),
      value: yup.lazy((val) =>
        val === FORM_EMPTY_VALUE
          ? yup.string().notRequired()
          : yup.number().notRequired().nullable().min(0).max(maxNumber, maxLengthNoMsg)
      ),
    });
  }
};

const storedAssetsValidator = (hasOwnHouse: boolean) =>
  yup.array().test({
    message: requiredMessage,
    test: (assets) => {
      if (!assets?.length) {
        return false;
      }
      for (const assetCustomType of Object.keys(AssetCustomType)) {
        const asset = assets.find((a) => a.customType === assetCustomType) || {
          customType: assetCustomType,
        };
        if (!assetValidator(hasOwnHouse, asset.customType).isValidSync(asset)) {
          return false;
        }
      }
      return true;
    },
  });
const internalFormAssetsValidator = (hasOwnHouse: boolean) =>
  yup.object().shape({
    [AssetCustomType.SAVINGS]: assetValidator(hasOwnHouse, AssetCustomType.SAVINGS),
    [AssetCustomType.HOME]: assetValidator(hasOwnHouse, AssetCustomType.HOME),
    [AssetCustomType.LAND]: assetValidator(hasOwnHouse, AssetCustomType.LAND),
    [AssetCustomType.MOTOR_VEHICLES]: assetValidator(hasOwnHouse, AssetCustomType.MOTOR_VEHICLES),
    [AssetCustomType.INVESTMENT_PROPERTIES]: assetValidator(hasOwnHouse, AssetCustomType.INVESTMENT_PROPERTIES),
    [AssetCustomType.SUPERANNUATION]: assetValidator(hasOwnHouse, AssetCustomType.SUPERANNUATION),
    [AssetCustomType.OTHER]: assetValidator(hasOwnHouse, AssetCustomType.OTHER),
  });

export const AssetsFormValidationFactory = (validatorType: 'form' | 'storedValue', hasOwnHouse: boolean) =>
  validatorType === 'form'
    ? internalFormAssetsValidator(hasOwnHouse)
    : yup.object().shape({
        financialDisclosure: yup.object({
          assets: storedAssetsValidator(hasOwnHouse),
        }),
      });
