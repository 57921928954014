import { Dispatch } from 'redux';
import { changeDSTransactionStatus, saveCoApplicantChoiceInDs } from '../../../api/formsData/formsData.service';
import { ApplicationState } from '../../ApplicationState';
import { SetDataActionCreator } from '../../utils';
import { UserType } from '../Session/session.model';
import { DataLoadErrorType, setDataLoadError, setIsDataLoading } from '../UI/UI.reducer';
import { Transaction, TransactionStatus } from './transaction.model';

export enum TransactionActionType {
  SET_DATA_TRANSACTION = 'transaction/SET_DATA_TRANSACTION',
  SET_MAIN_STATUS = 'transaction/SET_MAIN_STATUS',
  SET_USER_STATUS = 'transaction/SET_USER_STATUS',
  SET_MULTI_APPLICANT_JOURNEY = 'transaction/SET_MULTI_APPLICANT_JOURNEY',
}

export const setTransaction: SetDataActionCreator<TransactionActionType.SET_DATA_TRANSACTION, Transaction> = (
  payload
) => {
  return { type: TransactionActionType.SET_DATA_TRANSACTION, payload };
};

export const setMainTransactionStatus: SetDataActionCreator<
  TransactionActionType.SET_MAIN_STATUS,
  TransactionStatus
> = (payload) => {
  return { type: TransactionActionType.SET_MAIN_STATUS, payload: payload };
};

export const setMultiApplicantJourney: SetDataActionCreator<
  TransactionActionType.SET_MULTI_APPLICANT_JOURNEY,
  boolean
> = (payload) => {
  return { type: TransactionActionType.SET_MULTI_APPLICANT_JOURNEY, payload: payload };
};

export const setUserTransactionStatus: SetDataActionCreator<
  TransactionActionType.SET_USER_STATUS,
  { status: TransactionStatus; userType: UserType }
> = (payload) => {
  return { type: TransactionActionType.SET_USER_STATUS, payload: payload };
};

export const saveTransactionStatus =
  (status: TransactionStatus): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const session = getState().session;
    dispatch(setIsDataLoading(true));

    return changeDSTransactionStatus(status, session)
      .then(() => dispatch(setUserTransactionStatus({ status: status, userType: session.userType as UserType })))
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
        throw e;
      })
      .finally(() => dispatch(setIsDataLoading(false)));
  };

export const saveCoApplicantChoice =
  (isCoApplicantAdded: boolean): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const session = getState().session;
    dispatch(setIsDataLoading(true));

    return saveCoApplicantChoiceInDs({ isCoApplicantSelected: isCoApplicantAdded }, session)
      .then(() => {
        dispatch(setMultiApplicantJourney(isCoApplicantAdded));
        dispatch(
          setUserTransactionStatus({ status: TransactionStatus.COA_CHECK_COMPLETED, userType: UserType.PRIMARY })
        );
      })
      .catch((e) => {
        dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
        throw e;
      })
      .finally(() => dispatch(setIsDataLoading(false)));
  };
