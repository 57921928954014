import React from 'react';

interface Props {
  referenceSymbol: string;
  isAnchor: boolean;
}
export const ReferenceLink: React.FC<Props> = (props) => {
  const { isAnchor, referenceSymbol } = props;

  return isAnchor ? (
    <span className="reference reference-anchor" id={referenceSymbol}>
      [{referenceSymbol}]
    </span>
  ) : (
    <span className="reference reference-link">
      [<a href={`#${referenceSymbol}`}>{referenceSymbol}</a>]
    </span>
  );
};
