import i18n from '../i18n/i18n';
import { LiabilityCustomType } from '../redux/reducers/FormsData/formsData.model';
import yup from './yup-extended';
import { FORM_EMPTY_VALUE } from '../../screens/FormPage/formUtils';

const requiredMessage = i18n.t('validation:required');
const maxLengthNoMsg = i18n.t('validation:maxLength', { value: 15 });

const maxNumber = 999_999_999_999_999;

const isAssetTypeRequired: Record<LiabilityCustomType, (hasMortgage?: boolean) => boolean> = {
  [LiabilityCustomType.HOME_LOAN]: (hasMortgage) => !!hasMortgage,
  [LiabilityCustomType.OTHER_PROPERTY_LOAN]: () => false,
  [LiabilityCustomType.VEHICLE_LOANS_REFINANCED]: () => false,
  [LiabilityCustomType.OTHER_VEHICLE_LOANS]: () => false,
  [LiabilityCustomType.CREDIT_CARD_1]: () => false,
  [LiabilityCustomType.CREDIT_CARD_2]: () => false,
  [LiabilityCustomType.CREDIT_CARD_3]: () => false,
  [LiabilityCustomType.OTHER_CREDIT_CARDS]: () => false,
  [LiabilityCustomType.OTHER_LOANS]: () => false,
};

const liabilityValidator = (hasMortgage: boolean, liabilityType: LiabilityCustomType) => {
  if (isAssetTypeRequired[liabilityType](hasMortgage)) {
    return yup
      .object()
      .shape({
        installmentAmount: yup
          .number()
          .min(0, requiredMessage)
          .max(maxNumber, maxLengthNoMsg)
          .required(requiredMessage),
        balance: yup.number().min(0, requiredMessage).max(maxNumber, maxLengthNoMsg).required(requiredMessage),
        limit: yup.number().min(0, requiredMessage).max(maxNumber, maxLengthNoMsg).required(requiredMessage),
      })
      .required(requiredMessage);
  } else {
    return yup.object().shape({
      installmentAmount: yup.lazy((val) =>
        val === FORM_EMPTY_VALUE
          ? yup.string().notRequired()
          : yup.number().notRequired().nullable().min(0).max(maxNumber, maxLengthNoMsg)
      ),
      balance: yup.lazy((val) =>
        val === FORM_EMPTY_VALUE
          ? yup.string().notRequired()
          : yup.number().notRequired().nullable().min(0).max(maxNumber, maxLengthNoMsg)
      ),
      limit: yup.lazy((val) =>
        val === FORM_EMPTY_VALUE
          ? yup.string().notRequired()
          : yup.number().notRequired().nullable().min(0).max(maxNumber, maxLengthNoMsg)
      ),
    });
  }
};

const storedLiabilitiesValidator = (hasOwnHouse: boolean) =>
  yup.array().test({
    message: requiredMessage,
    test: (liabilities) => {
      if (!liabilities?.length) {
        return false;
      }
      for (const liabilityType of Object.keys(LiabilityCustomType)) {
        const liability = liabilities.find((a) => a.customType === liabilityType) || {
          customType: liabilityType,
        };
        if (!liabilityValidator(hasOwnHouse, liability.customType).isValidSync(liability)) {
          return false;
        }
      }
      return true;
    },
  });

const internalFormLiabilitiesValidator = (hasMortgage: boolean) =>
  yup.object().shape({
    ...Object.keys(LiabilityCustomType).reduce((agg, next) => {
      agg[next as LiabilityCustomType] = liabilityValidator(hasMortgage, next as LiabilityCustomType);
      return agg;
    }, {}),
  });

export const LiabilitiesFormValidationFactory = (validatorType: 'form' | 'storedValue', hasMortgage: boolean) =>
  validatorType === 'form'
    ? internalFormLiabilitiesValidator(hasMortgage)
    : yup.object().shape({
        financialDisclosure: yup.object({
          monthlyExpenses: yup.object({
            credits: storedLiabilitiesValidator(hasMortgage),
          }),
        }),
      });
