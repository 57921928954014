export interface FinancialProduct {
  calculation: {
    duration: number;
    yearlyMileage: number;
    effectiveInterestRate: number;
    balloonPayment: number;
    netLoanAmount: number;
    grossLoanAmount: number;
    guaranteedBuyBackPrice: number;
    subsequentInstallmentAmount: {
      amount: number;
      paymentFrequency: string;
      currency: string;
    };
    downPaymentAmount: number;
  };
  detailedName: FinancialProductType | null;
  disclaimer: string;
}

export enum FinancialProductType {
  CONSUMER_LOAN = 'CONSUMER_LOAN',
  CHATTEL_MORTGAGE = 'CHATTEL_MORTGAGE',
  GFV_CONSUMER_LOAN = 'GFV_CONSUMER_LOAN',
  GFV_CHATTEL_MORTGAGE = 'GFV_CHATTEL_MORTGAGE',
}
