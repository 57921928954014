import { DATE_FORMAT } from '../../config';
import i18n from '../i18n/i18n';
import { EntityLegalForm } from '../redux/reducers/FormsData/formsData.model';
import { BaseAddressFieldsValidatorMixin } from './BaseAddressFieldset.validator';
import yup from './yup-extended';
import { contactDataFieldsValidation } from './ContactInformationForm.validation';

const requiredMessage = i18n.t('validation:required');
const requiredSelectMessage = i18n.t('validation:requiredSelect');
const lettersOnlyMessage = i18n.t('validation:lettersOnly');

const lettersRegEx = /^[.\p{L}][.\p{L} '-]+$/u;

export const foundationDateValidator = yup
  .string()
  .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
  .noFutureDate()
  .required(requiredMessage);

export const taxIdentificationFieldValidators = {
  id: yup
    .string()
    .transform((value) => value || null)
    .required(requiredMessage)
    .matches(/^[0-9]*$/, i18n.t('validation:numericOnly'))
    .nullableMin(9, i18n.t('validation:minLength', { value: 9 }))
    .max(11, i18n.t('validation:maxLength', { value: 11 })),
};

export const CompanyDataFormValidationFactory = (entityLegalForm: EntityLegalForm) =>
  yup.object().shape({
    companyData: yup.object().shape({
      ...taxIdentificationFieldValidators,
      contactData: yup.object().shape(contactDataFieldsValidation),
      name: yup
        .string()
        .forbiddenCharacters(lettersOnlyMessage)
        .matches(lettersRegEx, lettersOnlyMessage)
        .required(i18n.t('validation:required')),
      ownership: yup.mixed().when([], {
        is: () => entityLegalForm === EntityLegalForm.COMPANY,
        then: () =>
          yup.number().required(i18n.t('validation:required')).min(1, requiredMessage).max(100, requiredMessage),
        otherwise: () => yup.mixed().nullable(),
      }),
      tradeName: yup
        .string()
        .matches(lettersRegEx, lettersOnlyMessage)
        .required(requiredMessage)
        .min(2, i18n.t('validation:minLength', { value: 2 }))
        .max(35, i18n.t('validation:maxLength', { value: 35 })),
      established: yup.string().required(requiredMessage),
      foundationDate: foundationDateValidator,
      industry: yup.string().required(requiredSelectMessage),
      addresses: yup
        .array()
        .of(
          yup
            .object()
            .notRequired()
            .shape({
              ...BaseAddressFieldsValidatorMixin,
              residentSinceDate: yup
                .string()
                .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
                .noFutureDate()
                .test(
                  'required',
                  requiredMessage,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  function checkFirst(value: string | undefined, ..._: any) {
                    return !this.path.includes('addresses[0]') || !!value;
                  }
                ),
            })
        )
        .required(requiredMessage),
    }),
  });
