import { ApplicationState } from '../../ApplicationState';
import { FinancialProduct, FinancialProductType } from './financialProduct.model';

export const businessOnlyDetailedNames = [
  FinancialProductType.CHATTEL_MORTGAGE,
  FinancialProductType.GFV_CHATTEL_MORTGAGE,
];

export const getFinancialProduct: (state: ApplicationState) => FinancialProduct | null = (state) =>
  state.financialProduct;

export const getDisclaimer = (state: ApplicationState) => state?.financialProduct?.disclaimer ?? '';

export const getDetailedName = (state: ApplicationState) => state?.financialProduct?.detailedName ?? null;

export const getIsPrivatePartnerTypeDisabled = (state: ApplicationState) =>
  businessOnlyDetailedNames.includes(getDetailedName(state) as FinancialProductType);
