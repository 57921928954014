import * as yup from 'yup';
import { DATE_FORMAT } from '../../config';
import i18n from '../i18n/i18n';
import { EmploymentStatus, noEmploymentStatuses } from '../redux/reducers/FormsData/formsData.model';
import { isPreviousAddressActive } from '../../screens/FormPage/formUtils';
import {
  BaseAddressFieldsetValidatorMixinWCountrySelection,
  BaseAddressFieldsetValidatorMixinWResidentSince,
} from './BaseAddressFieldset.validator';

const alphanumericRegEx = /^[_.\p{L}0-9\sß|\\/()`´'-]*$/u;

const requiredMsg = i18n.t('validation:required');
const requiredSelectMsg = i18n.t('validation:requiredSelect');
const lettersOnlyMessage = i18n.t('validation:lettersOnly');
const numericOnlyMessage = i18n.t('validation:numericOnly');

const employmentValidatorFactory = (isPreviousEmployer = false) =>
  yup
    .object({
      occupation: yup.mixed(),
      occupationDescription: yup.string().when('employmentStatus', {
        is: (employmentStatus: EmploymentStatus) => noEmploymentStatuses.includes(employmentStatus),
        then: (s) => s.notRequired(),
        otherwise: (s) => s.required(requiredSelectMsg),
      }),
      employmentStatus: yup.string().required(requiredSelectMsg),
      employmentStartDate: yup.string().when('employmentStatus', {
        is: (employmentStatus: EmploymentStatus) => noEmploymentStatuses.includes(employmentStatus),
        then: (s) => s.notRequired(),
        otherwise: (s) =>
          s.dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat')).noFutureDate().required(requiredMsg),
      }),
      employer: yup.object().when('employmentStatus', {
        is: (employmentStatus: EmploymentStatus) => noEmploymentStatuses.includes(employmentStatus),
        then: (s) => s.nullable(),
        otherwise: (s) =>
          s.shape({
            name: yup.string().matches(alphanumericRegEx, lettersOnlyMessage).required(requiredMsg),
            address: yup.object(
              isPreviousEmployer
                ? BaseAddressFieldsetValidatorMixinWCountrySelection
                : BaseAddressFieldsetValidatorMixinWResidentSince
            ),
            contactData: yup.object({
              telephoneNumber: yup
                .string()
                .required(requiredMsg)
                .min(9, i18n.t('validation:minLength', { value: 9 }))
                .max(11, i18n.t('validation:maxLength', { value: 11 }))
                .matches(/^[0-9]+$/, numericOnlyMessage),
              telephoneCountryCode: yup.string().required(requiredSelectMsg),
            }),
          }),
      }),
    })
    .nullable();

export const EmploymentInformationValidation = yup.object({
  employment: employmentValidatorFactory(false),
  previousEmployments: yup.array().when('employment.employmentStartDate', {
    is: (date: string) => isPreviousAddressActive(date),
    then: (s) => s.required(requiredMsg).of(employmentValidatorFactory(true).required(requiredMsg)),
    otherwise: (s) => s.nullable().notRequired(),
  }),
});
