import { Session } from './session.model';
import { SetDataActionCreator } from '../../utils';
import { SESSION_CACHE_KEY } from './session.reducer';

export enum SessionActionType {
  SET = 'session/SET',
  GET = 'session/GET',
  SET_USER_TYPE = 'session/SET_USER_TYPE',
}

interface SetSessionAction {
  type: SessionActionType.SET;
  payload: Session;
}
interface GetSessionAction {
  type: SessionActionType.GET;
  payload: Session;
}
interface SetUserType {
  type: SessionActionType.SET_USER_TYPE;
  payload: Session['userType'];
}

export const setSession: SetDataActionCreator<SessionActionType.SET, Session> = (payload) => {
  if (payload.token) {
    sessionStorage.setItem(SESSION_CACHE_KEY, JSON.stringify(payload));
  }
  return { type: SessionActionType.SET, payload };
};
export const setUserType: SetDataActionCreator<SessionActionType.SET_USER_TYPE, Session['userType']> = (payload) => {
  return { type: SessionActionType.SET_USER_TYPE, payload };
};

export type SessionAction = SetSessionAction | GetSessionAction | SetUserType;
