import { createStore, combineReducers, Store, applyMiddleware, compose, Reducer } from 'redux';
import thunk from 'redux-thunk';
import dataStorageRawDataReducer from './reducers/DataStorageRawData/dataStorageRawData.reducer';
import customReducer from './reducers/Custom/custom.reducer';
import formsDataReducer from './reducers/FormsData/formsData.reducer';
import expensesDataReducer from './reducers/Expenses/expenses.reducer';
import sessionReducer from './reducers/Session/session.reducer';
import transactionReducer from './reducers/Transaction/transaction.reducer';
import UIReducer from './reducers/UI/UI.reducer';
import vehicleDataReducer from './reducers/VehicleData/vehicleData.reducer';
import financialProductReducer from './reducers/FinancialProduct/financialProduct.reducer';
import uploadReducer from './reducers/Upload/upload.reducer';
import { ApplicationState } from './ApplicationState';

const getStore = () => {
  return createStore(
    combineReducers(<Record<keyof ApplicationState, Reducer>>{
      dataStorageRawData: dataStorageRawDataReducer,
      formsData: formsDataReducer,
      expensesData: expensesDataReducer,
      transaction: transactionReducer,
      session: sessionReducer,
      vehicleData: vehicleDataReducer,
      financialProduct: financialProductReducer,
      UI: UIReducer,
      documents: uploadReducer,
      custom: customReducer,
    }),
    compose(
      applyMiddleware(thunk),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(!process.env.JEST_WORKER_ID && (window as any).__REDUX_DEVTOOLS_EXTENSION__
        ? [(window as any).__REDUX_DEVTOOLS_EXTENSION__()]
        : [])
    )
  );
};

/* eslint-enable */
const store: Store<ApplicationState> = getStore();

export { store, getStore };
