import { HousingSituation } from '@bus/models/dist/enums/new/housing-situation.enum';
import { Checkbox, FormFieldLabel, Input, Paragraph, Select } from '@vwfs-bronson/bronson-react';
import { Formik, FormikProps } from 'formik';
import React, { createRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { analytics } from '../../../../../services/analytics/analytics';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';
import { saveContactInformation } from '../../../../../services/redux/reducers/FormsData/formsData.actions';
import {
  ContactInformation,
  EntityLegalForm,
  FormDataSection,
  HousingSituationAu,
  PartnerType,
} from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { ContactInformationValidationFactory } from '../../../../../services/validators/ContactInformationForm.validation';
import ContinueBtn from '../../../ContinueBtn';
import { EmptyOption } from '../../../formUtils';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import { BaseContactInformationFieldset } from '../Mixins/BaseContactInformationFieldset';
import { getLoggedInUserType } from '../../../../../services/redux/reducers/Session/session.selectors';
import { UserType } from '../../../../../services/redux/reducers/Session/session.model';
import {
  getCustomerPartnerType,
  getEntityLegalForm,
} from '../../../../../services/redux/reducers/FormsData/formsData.selectors';
import { getPrimaryUserEmailAddress } from '../../../../../services/redux/reducers/DataStorageRawData/dataStorageRawData.selectors';

const housingSituationOptions: Array<HousingSituationAu | 'MORTGAGED'> = [
  HousingSituation.RENT,
  HousingSituation.HOUSE_OWNERSHIP,
  'MORTGAGED',
  HousingSituation.HOUSED_PARENTS,
  HousingSituation.OTHER,
];

interface Props {
  formData: ContactInformation;
  userType: UserType;
  primaryUserEmailAddress: string | null;
  partnerType: PartnerType | null;
  entityLegalForm: EntityLegalForm | null;
  setFormData: (formData: ContactInformation) => void;
}

export const ContactInformationFormToConnect: React.FC<Props> = ({
  formData,
  userType,
  primaryUserEmailAddress,
  partnerType,
  entityLegalForm,
  setFormData,
}) => {
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);
  const formikRef = createRef<FormikProps<ContactInformation>>();

  const [validationSchema, setValidationSchema] = React.useState(ContactInformationValidationFactory());
  const [housingSituationValue, setHousingSituationValue] = React.useState(
    formData.financialDisclosure.hasMortgage ? 'MORTGAGED' : formData.financialDisclosure.housingSituation
  );

  const [isPaymentContributionRelevant, setIsPaymentContributionRelevant] = React.useState(false);

  React.useEffect(() => {
    if (userType === UserType.PRIMARY) {
      setValidationSchema(ContactInformationValidationFactory());
    } else {
      setValidationSchema(ContactInformationValidationFactory([primaryUserEmailAddress as string]));
    }
  }, [userType, primaryUserEmailAddress]);

  React.useEffect(() => {
    const financialDisclosure = formData.financialDisclosure;
    if (financialDisclosure.hasMortgage && financialDisclosure.housingSituation === HousingSituation.HOUSE_OWNERSHIP) {
      formikRef.current?.setFieldValue('financialDisclosure.housingSituation', 'MORTGAGED');
    }
  }, [formData]);

  const isHousingSituationOtherActive = (housingSituation: HousingSituationAu) =>
    housingSituation === HousingSituation.OTHER;

  React.useEffect(() => {
    setIsPaymentContributionRelevant(
      userType === UserType.PRIMARY &&
        (partnerType === PartnerType.PRIVATE ||
          [EntityLegalForm.INDIVIDUAL, EntityLegalForm.SOLE_TRADER].includes(entityLegalForm as EntityLegalForm)) &&
        (housingSituationValue === HousingSituation.RENT || housingSituationValue === ('MORTGAGED' as HousingSituation))
    );
  }, [userType, partnerType, entityLegalForm, housingSituationValue]);

  React.useEffect(() => {
    if (!['MORTGAGED', HousingSituation.RENT].includes(housingSituationValue)) {
      formikRef.current?.setFieldValue('financialDisclosure.paymentContribution', false);
    }
  }, [housingSituationValue]);

  return (
    <>
      {!isReadonly && <Paragraph>{t('formsData:formSections:contactInformation:formHint1')}</Paragraph>}

      <Formik
        innerRef={formikRef}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={validationSchema}
        initialValues={formData}
        onSubmit={(values) => {
          const formHousingSituation = values.financialDisclosure.housingSituation;
          setFormData({
            // Please mind the peculiar HousingSituation handling
            // If 'MORTGAGED' option is selected in the form,
            // then we set it to HOUSE_OWNERSHIP and set hasMortgage flag to false
            // Decided in FLGFT-6001
            ...values,
            financialDisclosure: {
              ...values.financialDisclosure,
              housingSituation:
                formHousingSituation === ('MORTGAGED' as HousingSituation)
                  ? HousingSituation.HOUSE_OWNERSHIP
                  : formHousingSituation,
              hasMortgage: formHousingSituation === ('MORTGAGED' as HousingSituation),
            },
          });
        }}
      >
        {(formikProps: FormikProps<ContactInformation>) => {
          setHousingSituationValue(formikProps.values.financialDisclosure.housingSituation);
          return (
            <Form noValidate>
              <Fieldset>
                <Fieldset.Row>
                  <Layout>
                    <Layout.Item default="1/2">
                      <FormField
                        name="financialDisclosure.housingSituation"
                        type="select"
                        labelText={t('formsData:formSections:contactInformation:fields:housingSituation:label')}
                        getReadonlyValue={(val: string) =>
                          t(`formsData:formSections:contactInformation:fields:housingSituation:options:${val}`)
                        }
                        notion
                        render={(fieldProps) => (
                          <Select {...fieldProps}>
                            <EmptyOption />
                            {housingSituationOptions.map((option) => (
                              <Select.Item key={option} value={option}>
                                {t(
                                  `formsData:formSections:contactInformation:fields:housingSituation:options:${option}`
                                )}
                              </Select.Item>
                            ))}
                          </Select>
                        )}
                      />
                    </Layout.Item>
                    {isHousingSituationOtherActive(formikProps.values.financialDisclosure.housingSituation) && (
                      <Layout.Item default="1/2" s="1/1">
                        <FormField
                          name="financialDisclosure.housingSituationOther"
                          type="input"
                          labelText={t('formsData:formSections:contactInformation:fields:housingSituationOther:label')}
                          notion
                          render={(fieldProps) => <Input {...fieldProps} />}
                        />
                      </Layout.Item>
                    )}
                    {isPaymentContributionRelevant && (
                      <Layout.Item default="1/2" s="1/1">
                        {!isReadonly && (
                          <>
                            {/* Position the checkbox vertically */}
                            <div>
                              <FormFieldLabel> </FormFieldLabel>
                            </div>
                          </>
                        )}
                        <Checkbox
                          className="u-text-left"
                          disabled={isReadonly}
                          checked={(formikProps.values as ContactInformation).financialDisclosure.paymentContribution}
                          onChange={(event: Event) =>
                            formikProps.setFieldValue(
                              'financialDisclosure.paymentContribution',
                              Boolean((event.target as any).checked)
                            )
                          }
                        >
                          {t('formsData:formSections:contactInformation:fields:paymentContribution:label')}
                        </Checkbox>
                      </Layout.Item>
                    )}
                  </Layout>
                </Fieldset.Row>
              </Fieldset>

              <BaseContactInformationFieldset
                addressNamePrefix=""
                contactDataNamePrefix="contactData."
                residentSinceLabel={true}
                formikProps={formikProps}
                withCountrySelection={true}
              />

              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: ApplicationState): Partial<Props> => ({
  formData: state.formsData[FormDataSection.contactInformation],
  userType: getLoggedInUserType(state),
  primaryUserEmailAddress: getPrimaryUserEmailAddress(state),
  partnerType: getCustomerPartnerType(state),
  entityLegalForm: getEntityLegalForm(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormData: (payload: ContactInformation) => {
    dispatch(saveContactInformation(payload));
    analytics.trackFormSectionOnContinue(FormDataSection.contactInformation);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformationFormToConnect);
