import React, { useContext } from 'react';
import { Fieldset } from '@vwfs-bronson/bronson-react';
import IsReadonlyFormContext from '../../screens/FormPage/isReadonlyFormContext';

interface Props {
  children: React.ReactNode;
  [key: string]: any;
}
type Comp = React.FC<Props> & {
  Row: React.FC<Props>;
};
export const ReadonlyAwareFieldset: Comp = ({ children, ...props }) => {
  return useContext(IsReadonlyFormContext) ? <>{children}</> : <Fieldset {...props}>{children}</Fieldset>;
};

const Row: React.FC<Props> = ({ children, ...props }) => {
  return useContext(IsReadonlyFormContext) ? <>{children}</> : <Fieldset.Row {...props}>{children}</Fieldset.Row>;
};
ReadonlyAwareFieldset.Row = Row;
