export enum UserType {
  PRIMARY = 'PRIMARY',
  CO_APPLICANT = 'CO_APPLICANT',
  ANONYMOUS = 'ANONYMOUS',
}
export interface Session {
  id?: string | null;
  userId?: string | null;
  userType?: UserType | null;
  token?: string | null;
  pin?: string | null;
  completed?: boolean;
}
