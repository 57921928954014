import i18n from '../i18n/i18n';
import yup from './yup-extended';
import { FORM_EMPTY_VALUE } from '../../screens/FormPage/formUtils';
import { BillsAndLivingExpenses } from '../redux/reducers/Expenses/expenses.model';

const requiredMessage = i18n.t('validation:required');
const maxLengthNoMsg = i18n.t('validation:maxLength', { value: 15 });

const maxNumber = 999_999_999_999_999;

const requiredNumberValidator = yup.lazy((value) =>
  (value === FORM_EMPTY_VALUE ? yup.string() : yup.number())
    .required(requiredMessage)
    .min(0, requiredMessage)
    .max(maxNumber, maxLengthNoMsg)
);

type MonthlyLivingExpenses = keyof BillsAndLivingExpenses['financialDisclosure']['monthlyExpenses'];
const fieldsValidators: Record<MonthlyLivingExpenses, any> = {
  groceries: requiredNumberValidator,
  transportation: requiredNumberValidator,
  clothing: requiredNumberValidator,
  privateHealthcareAmount: requiredNumberValidator,
  insuranceAmount: requiredNumberValidator,
  phoneInternet: requiredNumberValidator,
  vacation: requiredNumberValidator,
  sportActivities: requiredNumberValidator,
  entertainmentLeisure: requiredNumberValidator,
  alcoholTobaccoGambling: requiredNumberValidator,
  additionalBillsLivingExpenseAmount: requiredNumberValidator,
  additionalExpenseAmount: requiredNumberValidator,
};

export const BillsAndLivingExpensesFormValidation = yup.object().shape({
  financialDisclosure: yup
    .object({
      monthlyExpenses: yup.object(fieldsValidators).required(requiredMessage),
    })
    .required(requiredMessage),
});
