import React, { useContext } from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import IsReadonlyFormContext from '../../screens/FormPage/isReadonlyFormContext';

interface Props {
  children: React.ReactNode;
  [key: string]: any;
}
type LayoutComp = React.FC<Props> & {
  Item: React.FC<Props>;
};
export const ReadonlyAwareLayout: LayoutComp = ({ children, ...props }) => {
  return useContext(IsReadonlyFormContext) ? <>{children}</> : <Layout {...props}>{children}</Layout>;
};

const Item: React.FC<Props> = ({ children, ...props }) => {
  return useContext(IsReadonlyFormContext) ? <>{children}</> : <Layout.Item {...props}>{children}</Layout.Item>;
};
ReadonlyAwareLayout.Item = Item;
