import i18n from '../i18n/i18n';
import yup from './yup-extended';
import { FORM_EMPTY_VALUE } from '../../screens/FormPage/formUtils';

const requiredMessage = i18n.t('validation:required');
const maxLengthNoMsg = i18n.t('validation:maxLength', { value: 15 });

const maxNumber = 999_999_999_999_999;

const numberValidator = yup.lazy((value) =>
  (value === FORM_EMPTY_VALUE ? yup.string() : yup.number())
    .nullable()
    .min(0, requiredMessage)
    .max(maxNumber, maxLengthNoMsg)
);

const requiredNumberValidator = yup.lazy((value) =>
  (value === FORM_EMPTY_VALUE ? yup.string() : yup.number())
    .required(requiredMessage)
    .min(0, requiredMessage)
    .max(maxNumber, maxLengthNoMsg)
);

export const ExpensesFormValidationFactory = (isLivingSituationRent: boolean) =>
  yup.object().shape({
    financialDisclosure: yup
      .object({
        monthlyExpenses: yup
          .object({
            rentAmount: isLivingSituationRent ? requiredNumberValidator : numberValidator,
            educationFee: numberValidator,
            alimonyAmount: numberValidator,
            remoteLivingExpenses: numberValidator,
          })
          .required(requiredMessage),
      })
      .required(requiredMessage),
  });
