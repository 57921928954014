import { Dispatch } from 'redux';
import {
  verifyScriveStatus,
  requestScrivePrivacyPolicyEsign,
  startScriveIdentification,
} from '../../../api/legitimation/legitimation.service';
import { ApplicationState } from '../../ApplicationState';
import { DataLoadErrorType, setDataLoadError, setIsDataLoading } from '../UI/UI.reducer';
import { setTransaction } from '../Transaction/transaction.actions';
import { getTransactionDataFromDataStorage } from '../Transaction/transaction.mapper';
import { CustomState } from './custom.reducer';
import { FlauRoute } from '../../../../routes';

export enum CustomActionType {
  SET_SCRIVE_ID = 'Custom/SET_SCRIVE_ID',
  SET_PRIVACY_POLICY_ESIGN = 'Custom/SET_PRIVACY_POLICY_ESIGN',
}

export const setScriveId = (scriveId: string | null) => ({
  type: CustomActionType.SET_SCRIVE_ID,
  payload: scriveId,
});
export const setScrivePrivacyPolicyEsign = (privacyPolicyEsign: CustomState['scrive']['privacyPolicyEsign']) => ({
  type: CustomActionType.SET_PRIVACY_POLICY_ESIGN,
  payload: privacyPolicyEsign,
});

export const fetchAndSetIdentificationStatus = (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
  const state = getState();
  const session = state.session;
  const scriveIdentId = state.custom.scrive.scriveIdentId;
  dispatch(setIsDataLoading(true));

  return verifyScriveStatus(session, scriveIdentId as string)
    .then((responseBody) => {
      dispatch(setTransaction(getTransactionDataFromDataStorage(responseBody.data)));
      return responseBody;
    })
    .finally(() => dispatch(setIsDataLoading(false)));
};

export const startIdentification = (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
  const state = getState();
  dispatch(setIsDataLoading(true));

  return startScriveIdentification(state.session)
    .then((scriveStartResponse) => {
      dispatch(setScriveId(scriveStartResponse.scriveIdentId));
      return scriveStartResponse;
    })
    .catch((e) => {
      dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
      throw e;
    })
    .finally(() => dispatch(setIsDataLoading(false)));
};

export const startScrivePrivacyPolicyEsign = (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
  const state = getState();
  dispatch(setIsDataLoading(true));

  const continuationUrls = {
    success: `${window.location.origin}${FlauRoute.formsDataSummary}?policy_esign=success`,
    fail: `${window.location.origin}${FlauRoute.formsDataSummary}?policy_esign=fail`,
  };

  return requestScrivePrivacyPolicyEsign(state.session, continuationUrls)
    .then((dataStorage) => {
      dispatch(setTransaction(getTransactionDataFromDataStorage(dataStorage)));
      const esignLink = dataStorage.custom!.userData[state.session.userId as string].scrive!
        .privacyPolicyEsign as CustomState['scrive']['privacyPolicyEsign'];
      dispatch(setScrivePrivacyPolicyEsign(esignLink));
      return esignLink.url;
    })
    .catch((e) => {
      dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e }));
      throw e;
    })
    .finally(() => dispatch(setIsDataLoading(false)));
};
