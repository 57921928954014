import i18n from 'i18next';
import { merge } from 'lodash';
import { getBrandName } from '../../config';
import { ApplicationState } from '../redux/ApplicationState';
import { getBrowserResolutionBreakpointString } from './analytics.utils';
import { AnalyticsEvent } from './analyticsEvent';

window.du_digitalData = window.du_digitalData ?? {};

if (!window._satellite) {
  // set up a mock if no satellite object set
  window._satellite = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    track: () => {},
  };
}

export enum AnalyticsPageName {
  landing,
  mainApplicantForm,
  mainApplicantSummary,
  coApplicantCheck,
  processPageSubmitted,
  processCreditRefused,
  documentUpload,
  legitimationLanding,
  legitimationSuccess,
  legitimationFailure,
  additionalDocumentUpload,
  esignLanding,
  esignSuccess,
  esignFailure,
  contactModal,
  saveContinueModal,
  tanInputModal,
  linkExpired,
  error,
  processAdditionalDocsSubmitted,
}

class Analytics {
  private trackingData: DeepPartial<AnalyticsEvent> = {
    core: {
      stagingEnvironment: process.env.STAGE as any,
      dataLayerVersion: '2.10',
      category: {
        primaryCategory: 'Leasing',
        secondaryCategory: 'FinanceLease',
        contractType: 'New',
        maturityLevel: null,
        inventoryType: 'Asset-based products',
        productVariants: [],
        siteType: 'Checkout journey',
        processType: null,
      },
      pageInfo: {
        intendedCustomerDeviceType: 'all',
        version: 'R1.0',
        releaseDate: '2022-04-15',
        language: 'en',
        market: 'AU',
        publisher: 'DU',
      },
      attributes: {
        brand: getBrandName(),
        journeyType: 'customer-facing-product-journey',
      },
    },
    product: [
      {
        category: null,
        name: null,
        attributes: {
          typeOfSale: 'Online sales',
          durationUnit: 'MONTHS',
          mileageUnit: 'MILES',
        },
      },
    ],
    design: {
      browserResolutionBreakpoint: getBrowserResolutionBreakpointString(document.documentElement.clientWidth),
    },
  };

  public pageNameToEvent: Record<AnalyticsPageName, DeepPartial<AnalyticsEvent>> = {
    [AnalyticsPageName.landing]: {
      core: {
        pageInfo: {
          pageName: 'home',
        },
        attributes: {
          viewChange: 'home',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Product journey',
            eventAction: 'Start',
          },
        },
        {
          eventInfo: {
            eventType: 'Product configuration',
            eventAction: 'Completed',
          },
        },
        {
          eventInfo: {
            eventType: 'Product price',
            eventAction: 'Calculated',
          },
        },
        {
          eventInfo: {
            eventType: 'ProductInformation:VehicleInformation',
            eventAction: 'Provided',
          },
        },
      ],
    },
    [AnalyticsPageName.mainApplicantForm]: {
      core: {
        pageInfo: {
          pageName: 'Application form (main applicant)',
        },
        attributes: {
          viewChange: 'Application form (main applicant)',
        },
      },
      form: {
        name: 'Personal data',
        type: 'Personal data',
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.mainApplicantSummary]: {
      core: {
        pageInfo: {
          pageName: 'Summary page (main applicant)',
        },
        attributes: {
          viewChange: 'Summary page (main applicant)',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'SummaryPage',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.coApplicantCheck]: {
      core: {
        pageInfo: {
          pageName: 'Co applicant check',
        },
        attributes: {
          viewChange: 'Co applicant check',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.documentUpload]: {
      core: {
        pageInfo: {
          pageName: 'Document upload',
        },
        attributes: {
          viewChange: 'Document upload',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.additionalDocumentUpload]: {
      core: {
        pageInfo: {
          pageName: 'Document upload (additional)',
        },
        attributes: {
          viewChange: 'Document upload (additional)',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.legitimationLanding]: {
      core: {
        pageInfo: {
          pageName: 'Legitimation landing page',
        },
        attributes: {
          viewChange: 'Legitimation landing page',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.legitimationSuccess]: {
      core: {
        pageInfo: {
          pageName: 'Legitimation successful',
        },
        attributes: {
          viewChange: 'Legitimation successful',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Legitimation',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Product journey',
            eventAction: 'End',
          },
        },
      ],
    },
    [AnalyticsPageName.legitimationFailure]: {
      core: {
        pageInfo: {
          pageName: 'Legitimation failed',
        },
        attributes: {
          viewChange: 'Legitimation failed',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Legitimation',
            eventAction: 'Failed',
          },
        },
        {
          eventInfo: {
            eventType: 'Product journey',
            eventAction: 'End',
          },
        },
      ],
    },
    [AnalyticsPageName.esignLanding]: {
      core: {
        pageInfo: {
          pageName: 'eSignature landing page',
        },
        attributes: {
          viewChange: 'eSignature landing page',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Journey supplement',
            eventAction: 'Start',
          },
        },
      ],
    },
    [AnalyticsPageName.esignSuccess]: {
      core: {
        pageInfo: {
          pageName: 'eSignature success',
        },
        attributes: {
          viewChange: 'eSignature success',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'e-signature',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Sale',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Journey supplement',
            eventAction: 'End',
          },
        },
      ],
    },
    [AnalyticsPageName.esignFailure]: {
      core: {
        pageInfo: {
          pageName: 'eSignature failed',
        },
        attributes: {
          viewChange: 'eSignature failed',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'e-signature',
            eventAction: 'Failed',
          },
        },
        {
          eventInfo: {
            eventType: 'Journey supplement',
            eventAction: 'End',
          },
        },
      ],
    },
    [AnalyticsPageName.processPageSubmitted]: {
      core: {
        pageInfo: {
          pageName: 'Application form submitted',
        },
        attributes: {
          viewChange: 'Application form submitted',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Sale',
            eventAction: 'Success',
          },
        },
        {
          eventInfo: {
            eventType: 'Product journey',
            eventAction: 'End',
          },
        },
      ],
    },
    [AnalyticsPageName.processAdditionalDocsSubmitted]: {
      core: {
        pageInfo: {
          pageName: 'Document upload (additional)',
        },
        attributes: {
          viewChange: 'Document upload (additional)',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.processCreditRefused]: {
      core: {
        pageInfo: {
          pageName: 'Credit refused',
        },
        attributes: {
          viewChange: 'Credit refused',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.saveContinueModal]: {
      core: {
        attributes: {
          viewChange: 'Save application form',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.tanInputModal]: {
      core: {
        pageInfo: {
          pageName: 'Continue: Authentication',
        },
        attributes: {
          viewChange: 'TAN input',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.linkExpired]: {
      core: {
        pageInfo: {
          pageName: 'Continue: Link expired',
        },
        attributes: {
          viewChange: 'Continue: Link expired',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.contactModal]: {
      core: {
        pageInfo: {
          pageName: 'Contact',
        },
        attributes: {
          viewChange: 'Contact',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'pageView',
            eventAction: 'Success',
          },
        },
      ],
    },
    [AnalyticsPageName.error]: {
      core: {
        pageInfo: {
          pageName: 'Technical error',
        },
        attributes: {
          viewChange: 'Technical error',
        },
      },
      event: [
        {
          eventInfo: {
            eventType: 'Error',
            eventAction: 'Technical error',
          },
        },
      ],
    },
  };

  public extendTrackingDataWithStorefront(state: ApplicationState): DeepPartial<AnalyticsEvent> {
    const { vehicleData, financialProduct } = state;
    let contractAmount =
      financialProduct.calculation.subsequentInstallmentAmount.amount * financialProduct.calculation.duration;
    if (financialProduct.calculation?.subsequentInstallmentAmount?.paymentFrequency?.toLowerCase() === 'weekly') {
      contractAmount = contractAmount * 4;
    }
    const storefrontBasedDefaults: DeepPartial<AnalyticsEvent> = {
      core: {
        attributes: {
          transactionID: state.session.id as string,
        },
      },
      product: [
        {
          category: 'Leasing',
          name: 'FinanceLease',
          vehicleModel: {
            colorExterior: vehicleData.model.colorExterior,
            condition: 'new',
            descriptionLong: vehicleData.model.descriptionLong || vehicleData.model.description,
            engine: {
              transmission: vehicleData.model.transmissionType,
            },
            manufacturer: vehicleData.model.manufacturer,
          },
          attributes: {
            balloonPayment: financialProduct.calculation.balloonPayment,
            currency: financialProduct.calculation.subsequentInstallmentAmount.currency,
            downPaymentAmount: financialProduct.calculation.downPaymentAmount,
            duration: financialProduct.calculation.duration,
            effectiveInterestRate: financialProduct.calculation.effectiveInterestRate,
            grossLoanAmount: financialProduct.calculation.grossLoanAmount,
            recurringPayment: financialProduct.calculation.subsequentInstallmentAmount.amount,
            contractAmount: contractAmount,
            paymentFrequency: financialProduct.calculation.subsequentInstallmentAmount.paymentFrequency,
            yearlyMileage: financialProduct.calculation.yearlyMileage,
          },
        },
      ],
    };
    this.trackingData = merge({}, this.trackingData, storefrontBasedDefaults);
    return this.trackingData;
  }

  public extendTrackingData(data: DeepPartial<AnalyticsEvent>): DeepPartial<AnalyticsEvent> {
    this.trackingData = merge({}, this.trackingData, data);
    return this.trackingData;
  }

  public trackPage(pageName: AnalyticsPageName) {
    const eventData = this.pageNameToEvent[pageName];

    window.du_digitalData = merge({}, this.trackingData, eventData);
    window._satellite.track('page');

    if (pageName === AnalyticsPageName.saveContinueModal) {
      return;
    }
    this.extendTrackingData({
      core: {
        pageInfo: eventData?.core?.pageInfo,
        attributes: eventData?.core?.attributes,
      },
    });
  }

  public trackInteraction(eventInfos: Array<AnalyticsEvent['event']['0']['eventInfo']>) {
    const eventData: DeepPartial<AnalyticsEvent> = {
      event: eventInfos.map((singleInfo) => ({
        eventInfo: singleInfo,
      })),
    };
    window.du_digitalData = merge({}, this.trackingData, eventData);
    window._satellite.track('interaction');
  }

  public trackCustomEvent(
    eventType: 'page' | 'interaction',
    eventData: DeepPartial<AnalyticsEvent>,
    extendTrackingData = false
  ) {
    window.du_digitalData = merge({}, this.trackingData, eventData);
    window._satellite.track(eventType);
    if (extendTrackingData) {
      this.extendTrackingData({
        core: eventData.core,
      });
    }
  }

  public trackFormSectionOnContinue(formSection: string) {
    const sectionLabel = i18n.t(`formsData:formSections:${formSection}:analytics:label`, { defaultValue: null });
    const linkInformation = i18n.t(`formsData:formSections:${formSection}:analytics:linkInformation`, {
      defaultValue: null,
    });
    const eventType = i18n.t(`formsData:formSections:${formSection}:analytics:eventType`, { defaultValue: null });
    if (!sectionLabel) {
      return;
    }
    const eventData: DeepPartial<AnalyticsEvent> = {
      form: {
        name: 'Personal data',
        type: 'Personal data',
      },
      event: [
        {
          eventInfo: {
            linkInformation: linkInformation,
            eventType: `Product Information: ${eventType}`,
            eventAction: 'Provided',
          },
        },
      ],
    };
    window.du_digitalData = merge({}, this.trackingData, eventData);
    window._satellite.track('interaction');
  }

  public trackAPIError(error?: any) {
    const eventData: DeepPartial<AnalyticsEvent> = {
      core: {
        pageInfo: {
          pageName: 'API error',
        },
        attributes: {
          viewChange: 'API error',
        },
      },
      error: {
        errorCode: error?.response?.status ?? 'API Error',
        errorMessage: error?.response?.data?.message ?? null,
      },
      event: [
        {
          eventInfo: {
            eventType: 'API',
            eventAction: 'Error',
          },
        },
      ],
    };
    window.du_digitalData = merge({}, this.trackingData, eventData);
    window._satellite.track('page');
  }
}

export const analytics = new Analytics();
