import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { get as _get } from 'lodash';
import { Gender } from '@bus/models/dist/enums/new/gender.enum';
import { MaritalStatus } from '@bus/models/dist/enums/new/marital-status.enum';
import { DatePicker, Input, Paragraph, Select } from '@vwfs-bronson/bronson-react';
import { FormikProps, useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { DATE_FORMAT } from '../../../../../config';

import {
  EntityLegalForm,
  AustralianResidencyStatus,
  Salutation,
  BasePersonalData,
  AustralianState,
  IdentificationType,
} from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { EmptyOption, FORM_EMPTY_VALUE, numberInputProps } from '../../../formUtils';
import { PassportDocument } from '../../../../../services/redux/dataStorage.model';

import { getIsIndividualBusiness } from '../../../../../services/redux/reducers/FormsData/formsData.helpers';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';

const australianResidencyStatusDocuments = [
  AustralianResidencyStatus.ID_CARD,
  AustralianResidencyStatus.TEMPORARY_RESIDENCE_CARD,
  AustralianResidencyStatus.PERMANENT_RESIDENCE_CARD,
];
const salutations = [Salutation.MR, Salutation.MRS, Salutation.MISS, Salutation.MS];
const genderOptions = [Gender.MALE, Gender.FEMALE, Gender.OTHER, Gender.UNKNOWN];
const maritalStatusOptions = [
  MaritalStatus.SINGLE,
  MaritalStatus.MARRIED,
  MaritalStatus.LIVING_TOGETHER,
  MaritalStatus.DIVORCED,
  MaritalStatus.SEPARATED,
  MaritalStatus.WIDOWED,
  MaritalStatus.ENGAGED,
  MaritalStatus.OTHER,
];
const countryEntries = Object.entries(CountryCode);
const stateOptionEntries = Object.entries(AustralianState);

export const BasePersonalCredentialsFieldset = ({ fieldPrefix = '' }): JSX.Element => {
  const { t } = useTranslation();
  const formikProps = useFormikContext();

  return (
    <>
      <Fieldset>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2">
              <FormField
                name={`${fieldPrefix}salutation`}
                type="select"
                labelText={t(`formsData:formSections:personalData:fields:salutation:label`)}
                getReadonlyValue={(val: string) =>
                  t(`formsData:formSections:personalData:fields:salutation:options:${val}`)
                }
                notion
                render={(fieldProps) => (
                  <Select {...fieldProps}>
                    <EmptyOption />
                    {salutations.map((option) => (
                      <Select.Item key={option} value={option}>
                        {t(`formsData:formSections:personalData:fields:salutation:options:${option}`)}
                      </Select.Item>
                    ))}
                  </Select>
                )}
              />
            </Layout.Item>
            <Layout.Item default="1/2">
              <FormField
                name="gender"
                type="select"
                labelText={t(`formsData:formSections:personalData:fields:gender:label`)}
                getReadonlyValue={(val: string) =>
                  t(`formsData:formSections:personalData:fields:gender:options:${val}`)
                }
                notion
                render={(fieldProps) => (
                  <Select {...fieldProps}>
                    <EmptyOption />
                    {genderOptions.map((option) => (
                      <Select.Item key={option} value={option}>
                        {t(`formsData:formSections:personalData:fields:gender:options:${option}`)}
                      </Select.Item>
                    ))}
                  </Select>
                )}
              />
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Layout>
          <Layout.Item default="1/3" s="1/1">
            <FormField
              name={`${fieldPrefix}firstName`}
              type="input"
              labelText={t(`formsData:formSections:personalData:fields:firstName:label`)}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          <Layout.Item default="1/3" s="1/1">
            <FormField
              name={`${fieldPrefix}middleNames`}
              type="input"
              labelText={t(`formsData:formSections:personalData:fields:middleNames:label`)}
              notion={false}
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          <Layout.Item default="1/3" s="1/1">
            <FormField
              name={`${fieldPrefix}lastName`}
              type="input"
              labelText={t(`formsData:formSections:personalData:fields:lastName:label`)}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>

      <Fieldset>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${fieldPrefix}dateOfBirth`}
              type="input"
              notion
              labelText={t(`formsData:formSections:personalData:fields:dateOfBirth:label`)}
              render={(fieldProps) => (
                <DatePicker
                  {...fieldProps}
                  dateFormat={DATE_FORMAT}
                  locale="en"
                  onChange={(val: string) => {
                    formikProps.setFieldValue(`${fieldPrefix}dateOfBirth`, val, true);
                  }}
                  onBlur={(val: string) => {
                    formikProps.setFieldValue(`${fieldPrefix}dateOfBirth`, val);
                    formikProps.handleBlur(`${fieldPrefix}dateOfBirth`);
                  }}
                />
              )}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>
    </>
  );
};

interface Props {
  /**
   * Prefix for the name of the fields. It should end with a dot or be an empty string.
   */
  fieldPrefix: string;
  entityType: EntityLegalForm | null;
  formikProps: FormikProps<any>;
}

export const BasePersonalDataFieldset = (props: Props): JSX.Element => {
  const { fieldPrefix, entityType } = props;
  const formikProps = props.formikProps as FormikProps<BasePersonalData>;

  const selectedIssuingCountry = _get(formikProps.values, `${fieldPrefix}identityDocuments[1].issuingCountry`);
  const [isPassportRelevant, setIsPassportRelevant] = React.useState(
    selectedIssuingCountry && selectedIssuingCountry !== CountryCode.Australia
  );

  const isReadOnly = React.useContext(IsReadonlyFormContext);

  React.useEffect(() => {
    if (selectedIssuingCountry !== CountryCode.Australia) {
      formikProps.setFieldValue(`${fieldPrefix}identityDocuments[1].issuingState`, FORM_EMPTY_VALUE, true);
      formikProps.setFieldValue(
        `${fieldPrefix}identityDocuments[1].identityDocumentVerificationNumber`,
        FORM_EMPTY_VALUE,
        true
      );
    }
    const needsPassport = Boolean(selectedIssuingCountry) && selectedIssuingCountry !== CountryCode.Australia;
    setIsPassportRelevant(needsPassport);
  }, [selectedIssuingCountry]);

  const isIndividualBusiness = getIsIndividualBusiness(entityType);

  React.useEffect(() => {
    if (!isIndividualBusiness) {
      formikProps.setFieldValue(`${fieldPrefix}companyData.id`, FORM_EMPTY_VALUE, false);
    }
  }, [entityType]);

  React.useEffect(() => {
    if (isPassportRelevant) {
      const existingPassport: PassportDocument | undefined = _get(
        formikProps.values,
        `${fieldPrefix}identityDocuments[2]`
      );
      formikProps.setFieldValue(
        `${fieldPrefix}identityDocuments[2]`,
        {
          identityDocumentType: IdentificationType.PASSPORT,
          identityDocumentId: existingPassport?.identityDocumentId || FORM_EMPTY_VALUE,
          issuingCountry: existingPassport?.issuingCountry || CountryCode.Australia,
          expiryDate: existingPassport?.expiryDate || FORM_EMPTY_VALUE,
        },
        true
      );
    } else {
      formikProps.setFieldValue(
        `${fieldPrefix}identityDocuments`,
        [
          _get(formikProps.values, `${fieldPrefix}identityDocuments[0]`),
          _get(formikProps.values, `${fieldPrefix}identityDocuments[1]`),
        ],
        true
      );
    }
  }, [isPassportRelevant]);

  const { t } = useTranslation();

  return (
    <>
      <BasePersonalCredentialsFieldset fieldPrefix={fieldPrefix} />
      <Fieldset>
        <Layout>
          <Layout.Item default="1/2">
            <FormField
              name="maritalStatus"
              type="select"
              labelText={t('formsData:formSections:personalData:fields:maritalStatus:label')}
              getReadonlyValue={(val: string) =>
                t(`formsData:formSections:personalData:fields:maritalStatus:options:${val}`)
              }
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {maritalStatusOptions.map((option) => (
                    <Select.Item key={option} value={option}>
                      {t(`formsData:formSections:personalData:fields:maritalStatus:options:${option}`)}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name="numberOfDependentChildren"
              type="input"
              labelText={t('formsData:formSections:personalData:fields:numberOfDependentChildren:label')}
              notion
              render={(fieldProps) => <Input {...fieldProps} {...numberInputProps} type="number" min={0} />}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${fieldPrefix}identityDocuments[0].identityDocumentType`}
              type="select"
              labelText={t(`formsData:formSections:personalData:fields:australianResidencyStatus:label`)}
              getReadonlyValue={(val: string) =>
                t(`formsData:formSections:personalData:fields:australianResidencyStatus:options:${val}`)
              }
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {australianResidencyStatusDocuments.map((option) => (
                    <Select.Item key={option} value={option}>
                      {t(`formsData:formSections:personalData:fields:australianResidencyStatus:options:${option}`)}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
          {isIndividualBusiness ? (
            <Layout.Item default="1/2" s="1/1">
              <FormField
                name="companyData.id"
                type="input"
                labelText={t('formsData:formSections:companyInformation:fields:companyId:label')}
                notion={true}
                render={(fieldProps) => <Input {...fieldProps} />}
              />
            </Layout.Item>
          ) : (
            <></>
          )}
          {!isReadOnly && (
            <Paragraph>{t('formsData:formSections:personalData:fields:identityDocuments:header')}</Paragraph>
          )}
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${fieldPrefix}identityDocuments[1].issuingCountry`}
              type="select"
              labelText={t(
                `formsData:formSections:personalData:fields:identityDocuments:DRIVER_LICENSE:issuingCountry:label`
              )}
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {countryEntries.map(([label, code]) => (
                    <Select.Item key={`${code}${label}`} value={code}>
                      {label}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${fieldPrefix}identityDocuments[1].identityDocumentId`}
              type="input"
              labelText={t(
                `formsData:formSections:personalData:fields:identityDocuments:DRIVER_LICENSE:identityDocumentId:label`
              )}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          {selectedIssuingCountry === CountryCode.Australia && (
            <>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  name={`${fieldPrefix}identityDocuments[1].issuingState`}
                  type="select"
                  labelText={t(
                    `formsData:formSections:personalData:fields:identityDocuments:DRIVER_LICENSE:issuingState:label`
                  )}
                  notion
                  render={(fieldProps) => (
                    <Select {...fieldProps}>
                      <EmptyOption />
                      {stateOptionEntries.map(([code, label]) => (
                        <Select.Item key={code} value={code}>
                          {label}
                        </Select.Item>
                      ))}
                    </Select>
                  )}
                />
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                <FormField
                  name={`${fieldPrefix}identityDocuments[1].identityDocumentVerificationNumber`}
                  type="select"
                  labelText={t(
                    `formsData:formSections:personalData:fields:identityDocuments:DRIVER_LICENSE:identityDocumentVerificationNumber:label`
                  )}
                  notion
                  render={(fieldProps) => <Input {...fieldProps} />}
                />
              </Layout.Item>
            </>
          )}
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${fieldPrefix}identityDocuments[1].expiryDate`}
              type="input"
              labelText={t(
                `formsData:formSections:personalData:fields:identityDocuments:DRIVER_LICENSE:expiryDate:label`
              )}
              notion
              render={(fieldProps) => {
                return (
                  <>
                    <DatePicker
                      {...fieldProps}
                      placeholder={DATE_FORMAT}
                      locale="en"
                      dateFormat={DATE_FORMAT}
                      onChange={(val: string) => {
                        formikProps.setFieldValue(`${fieldPrefix}identityDocuments[1].expiryDate`, val, true);
                      }}
                      onBlur={(val: string) => {
                        formikProps.setFieldValue(`${fieldPrefix}identityDocuments[1].expiryDate`, val);
                        formikProps.handleBlur(`${fieldPrefix}identityDocuments[1].expiryDate`);
                      }}
                    />
                  </>
                );
              }}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>
      {isPassportRelevant && (
        <Fieldset>
          <Layout>
            <Layout.Item default="1/2" s="1/1">
              <FormField
                name={`${fieldPrefix}identityDocuments[2].issuingCountry`}
                type="select"
                labelText={t(
                  `formsData:formSections:personalData:fields:identityDocuments:PASSPORT:issuingCountry:label`
                )}
                notion
                render={(fieldProps) => (
                  <Select {...fieldProps}>
                    <EmptyOption />
                    {countryEntries.map(([label, code]) => (
                      <Select.Item key={`${code}${label}`} value={code}>
                        {label}
                      </Select.Item>
                    ))}
                  </Select>
                )}
              />
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1">
              <FormField
                name={`${fieldPrefix}identityDocuments[2].identityDocumentId`}
                type="input"
                labelText={t(
                  `formsData:formSections:personalData:fields:identityDocuments:PASSPORT:identityDocumentId:label`
                )}
                notion
                render={(fieldProps) => <Input {...fieldProps} />}
              />
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1">
              <FormField
                name={`${fieldPrefix}identityDocuments[2].expiryDate`}
                type="input"
                labelText={t(`formsData:formSections:personalData:fields:identityDocuments:PASSPORT:expiryDate:label`)}
                notion
                render={(fieldProps) => {
                  return (
                    <>
                      <DatePicker
                        {...fieldProps}
                        placeholder={DATE_FORMAT}
                        dateFormat={DATE_FORMAT}
                        locale="en"
                        onChange={(val: string) => {
                          formikProps.setFieldValue(`${fieldPrefix}identityDocuments[2].expiryDate`, val, true);
                        }}
                        onBlur={(val: string) => {
                          formikProps.setFieldValue(`${fieldPrefix}identityDocuments[2].expiryDate`, val);
                          formikProps.handleBlur(`${fieldPrefix}identityDocuments[2].expiryDate`);
                        }}
                      />
                    </>
                  );
                }}
              />
            </Layout.Item>
          </Layout>
        </Fieldset>
      )}
    </>
  );
};
