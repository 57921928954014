import { TransactionActionType } from './transaction.actions';
import { Transaction, TransactionStatus } from './transaction.model';

const sessionInitialState: Transaction = {
  brand: '',
  product: '',
  status: null,
  storefrontCallbackURL: '',
  userTransactions: {},
};

// eslint-disable-next-line default-param-last
export default function (
  state = sessionInitialState,
  action: { type: TransactionActionType; payload: any }
): Transaction {
  switch (action.type) {
    case TransactionActionType.SET_DATA_TRANSACTION:
      return {
        ...state,
        ...action.payload,
      };
    case TransactionActionType.SET_MULTI_APPLICANT_JOURNEY:
      return {
        ...state,
        multiApplicantJourney: action.payload,
      };
    case TransactionActionType.SET_MAIN_STATUS:
      return {
        ...state,
        status: action.payload as TransactionStatus,
      };
    case TransactionActionType.SET_USER_STATUS:
      return {
        ...state,
        userTransactions: {
          [action.payload.userType]: {
            ...state.userTransactions[action.payload.userType],
            status: action.payload.status,
          },
        },
      };
    default:
      return state;
  }
}
