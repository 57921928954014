import { FORM_EMPTY_VALUE } from '../../screens/FormPage/formUtils';
import i18n from '../i18n/i18n';
import yup from './yup-extended';
import { EmploymentStatus } from '../redux/reducers/FormsData/formsData.model';

const requiredMessage = i18n.t('validation:required');
const maxLengthNoMsg = i18n.t('validation:maxLength', { value: 15 });

const maxNumber = 999_999_999_999_999;

const numberValidator = yup.lazy((value) =>
  (value === FORM_EMPTY_VALUE ? yup.string() : yup.number()).min(0, requiredMessage).max(maxNumber, maxLengthNoMsg)
);

const requiredNumberValidator = yup.lazy((value) =>
  (value === FORM_EMPTY_VALUE ? yup.string() : yup.number())
    .required(requiredMessage)
    .min(0, requiredMessage)
    .max(maxNumber, maxLengthNoMsg)
);

export const isNetIncomeAmountRequired = (employmentStatus: EmploymentStatus | null) => {
  if (!employmentStatus) {
    return false;
  }
  return [
    EmploymentStatus.SEASONAL,
    EmploymentStatus.CONTRACTOR,
    EmploymentStatus.PERMANENT,
    EmploymentStatus.PART_TIME,
    EmploymentStatus.CASUAL,
  ].includes(employmentStatus);
};

export const IncomeFormValidationFactory = (employmentStatus: EmploymentStatus | null) =>
  yup.object().shape({
    financialDisclosure: yup
      .object({
        annualInvestedIncome: numberValidator,
        monthlyIncome: yup
          .object({
            netIncomeAmount: isNetIncomeAmountRequired(employmentStatus) ? requiredNumberValidator : numberValidator,
            overtime: numberValidator,
            commissions: numberValidator,
            bonus: numberValidator,
            rental: numberValidator,
            benefits: numberValidator,
            netHouseholdIncomeAmount: numberValidator,
            additionalIncomeAmount: numberValidator,
          })
          .required(requiredMessage),
      })
      .required(requiredMessage),
  });

export const IncomeFormCoApplicantValidationFactory = (employmentStatus: EmploymentStatus | null) =>
  yup.object().shape({
    customerData: yup
      .object({
        financialDisclosure: yup
          .object({
            annualInvestedIncome: numberValidator,
            monthlyIncome: yup
              .object({
                netIncomeAmount: isNetIncomeAmountRequired(employmentStatus)
                  ? requiredNumberValidator
                  : numberValidator,
                overtime: numberValidator,
                commissions: numberValidator,
                bonus: numberValidator,
                rental: numberValidator,
                benefits: numberValidator,
                additionalIncomeAmount: numberValidator,
              })
              .required(requiredMessage),
          })
          .required(requiredMessage),
      })
      .required(requiredMessage),
  });
