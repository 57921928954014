import { HousingSituation } from '@bus/models/dist/enums/new/housing-situation.enum';
import { createSelector, Selector } from 'reselect';
import { AssetsFormValidationFactory } from '../../../validators/AssetsForm.validation';
import { CompanyDataFormValidationFactory } from '../../../validators/CompanyDataForm.validation';
import { ContactInformationValidationFactory } from '../../../validators/ContactInformationForm.validation';
import { EmploymentInformationValidation } from '../../../validators/EmploymentInformationForm.validation';
import { LiabilitiesFormValidationFactory } from '../../../validators/LiabilitiesForm.validation';
import {
  CoApplicantPersonalDataFormValidation,
  PersonalDataFormValidation,
} from '../../../validators/PersonalDataForm.validation';
import { ApplicationState } from '../../ApplicationState';
import { UserType } from '../Session/session.model';
import { getLoggedInUserType } from '../Session/session.selectors';
import {
  Assets,
  CoApplicantAmlData,
  CompanyInformation,
  ContactInformation,
  CustomerRole,
  EmploymentStatus,
  EntityLegalForm,
  FormDataSection,
  Liabilities,
  PartnerType,
  ReferenceContacts,
} from './formsData.model';
import { ReferenceContactsFormValidationFactory } from '../../../validators/ReferenceContactsForm.validation';
import { LandingPageConsents } from '../Transaction/transaction.model';
import { individEntityForms } from './formsData.helpers';
import { TrustAgentType } from '../../dataStorage.model';

import {
  getPrimaryUserLegalEntityType,
  getPrimaryUserPartnerType,
  getPrimaryUserTrustAgentType,
} from '../DataStorageRawData/dataStorageRawData.selectors';

export const getCoApplicantAmlData: (state: ApplicationState) => CoApplicantAmlData = (state) =>
  state.formsData.coApplicantAmlData;

export const getCustomerPartnerType: (state: ApplicationState) => PartnerType | null = (state) => {
  if (getLoggedInUserType(state) === UserType.CO_APPLICANT) {
    return getPrimaryUserPartnerType(state);
  }
  return state.formsData[FormDataSection.personalData].partnerType;
};

export const getLandingPageConsents = (state: ApplicationState): LandingPageConsents => {
  return {
    personalDataAcceptance: state.formsData.dataPrivacyStatement.personalDataAcceptance,
    allowAds: state.formsData.dataPrivacyStatement.allowAds,
  };
};

export const getDataPrivacyStatement = (state: ApplicationState) => state.formsData.dataPrivacyStatement;

export const getEntityLegalForm: (state: ApplicationState) => EntityLegalForm | null = (state) => {
  if (getLoggedInUserType(state) === UserType.CO_APPLICANT) {
    return getPrimaryUserLegalEntityType(state);
  }
  return state.formsData[FormDataSection.personalData].companyData?.entityLegalForm ?? null;
};

export const getCustomerPhoneNumber = (state: ApplicationState) =>
  state.formsData[FormDataSection.contactInformation].contactData?.mobilePhoneNumber || null;

export const getCustomerPhoneCountryCode = (state: ApplicationState) =>
  state.formsData[FormDataSection.contactInformation].contactData?.mobilePhoneCountryCode || null;

export const getCustomerEmail = (state: ApplicationState) =>
  state.formsData[FormDataSection.contactInformation].contactData?.email || null;

export const getIsHousingSituationRent = (state: ApplicationState) =>
  getHousingSituation(state) === HousingSituation.RENT;

export const getIsPrivate = (state: ApplicationState) => getCustomerPartnerType(state) === PartnerType.PRIVATE;

export const getIsBusinessIndividual = (state: ApplicationState) =>
  getCustomerPartnerType(state) === PartnerType.BUSINESS &&
  individEntityForms.includes(getEntityLegalForm(state) as EntityLegalForm);

export const getHousingSituation = (state: ApplicationState) => {
  return state.formsData[FormDataSection.contactInformation].financialDisclosure.housingSituation;
};
export const getIsHousingSituationOwnHouse = (state: ApplicationState) =>
  [HousingSituation.HOUSE_OWNERSHIP, 'MORTGAGED'].includes(getHousingSituation(state));
export const getIsHousingSituationMortgaged = (state: ApplicationState) =>
  getHousingSituation(state) === HousingSituation.HOUSE_OWNERSHIP &&
  state.formsData[FormDataSection.contactInformation].financialDisclosure.hasMortgage;
export const getMainAddress = (state: ApplicationState) =>
  state.formsData[FormDataSection.contactInformation].addresses[0];

export const getEmploymentType = (state: ApplicationState): EmploymentStatus | null =>
  state.formsData[FormDataSection.employmentInformation].employment.employmentStatus;

export const getIsCoApplicantAdded = (state: ApplicationState): boolean =>
  Boolean(state.formsData.coApplicantAmlData?.lastName);

export const getIdentityDocumentType = (state: ApplicationState) =>
  state.formsData[FormDataSection.personalData].identityDocuments[0].identityDocumentType;

export const getTrustAgentType = (state: ApplicationState) => {
  if (getLoggedInUserType(state) === UserType.CO_APPLICANT) {
    return getPrimaryUserTrustAgentType(state);
  }
  return state.formsData[FormDataSection.personalData].customerRelations?.[CustomerRole.TRUST]?.trustAgentType || null;
};

const getIsCompanyDataSectionRelevant = (state: ApplicationState) => {
  const entityLegalForm = getEntityLegalForm(state);
  if (!entityLegalForm) {
    return false;
  }
  if (entityLegalForm === EntityLegalForm.COMPANY && getLoggedInUserType(state) === UserType.PRIMARY) {
    return true;
  }

  if (entityLegalForm === EntityLegalForm.TRUST) {
    if (state.session.userType === UserType.PRIMARY) {
      return getTrustAgentType(state) === TrustAgentType.COMPANY;
    } else if (state.session.userType === UserType.CO_APPLICANT) {
      return getTrustAgentType(state) === TrustAgentType.INDIVIDUAL;
    }
  }

  return false;
};

const getIsReferenceContactsSectionRelevant = (state: ApplicationState) => {
  if (getLoggedInUserType(state) !== UserType.PRIMARY) {
    return false;
  }
  return getCustomerPartnerType(state) === PartnerType.PRIVATE || getIsBusinessIndividual(state);
};
const getIsEmploymentSectionRelevant = (state: ApplicationState) => {
  return getCustomerPartnerType(state) === PartnerType.PRIVATE || getIsBusinessIndividual(state);
};

export const getPrimaryApplicantAddress = (state: ApplicationState) =>
  state.formsData[FormDataSection.contactInformation]?.addresses?.[0];

export const areSectionsActive = createSelector(
  getIsCompanyDataSectionRelevant,
  getIsReferenceContactsSectionRelevant,
  getIsEmploymentSectionRelevant,
  (
    isCompanyDataSectionRelevant: boolean,
    isReferenceContactsSectionRelevant: boolean,
    isEmploymentSectionRelevant: boolean
  ) =>
    <Record<FormDataSection, boolean>>{
      [FormDataSection.personalData]: true,
      [FormDataSection.contactInformation]: true,
      [FormDataSection.companyInformation]: isCompanyDataSectionRelevant,
      [FormDataSection.referenceContacts]: isReferenceContactsSectionRelevant,
      [FormDataSection.employmentInformation]: isEmploymentSectionRelevant,
      [FormDataSection.assets]: true,
      [FormDataSection.liabilities]: true,
    }
);

export const isSectionValid: Record<FormDataSection, (...p: any) => any> = {
  [FormDataSection.personalData]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.personalData],
    getLoggedInUserType,
    (personalData, userType) => {
      if (userType === UserType.CO_APPLICANT) {
        return CoApplicantPersonalDataFormValidation.isValidSync(personalData);
      }
      return PersonalDataFormValidation.isValidSync(personalData);
    }
  ),
  [FormDataSection.contactInformation]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.contactInformation],
    (contactInformation: ContactInformation) => ContactInformationValidationFactory().isValidSync(contactInformation)
  ),
  [FormDataSection.companyInformation]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.companyInformation],
    getEntityLegalForm,
    (companyInformation: CompanyInformation, entityLegalForm: EntityLegalForm | null) =>
      CompanyDataFormValidationFactory(entityLegalForm as EntityLegalForm).isValidSync(companyInformation)
  ),
  [FormDataSection.referenceContacts]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.referenceContacts],
    getPrimaryApplicantAddress,
    (referenceContacts: ReferenceContacts, primaryApplicantAddress) =>
      ReferenceContactsFormValidationFactory(primaryApplicantAddress).isValidSync(referenceContacts)
  ),
  [FormDataSection.employmentInformation]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.employmentInformation],
    (data) => EmploymentInformationValidation.isValidSync(data)
  ),
  [FormDataSection.assets]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.assets],
    getIsHousingSituationOwnHouse,
    (assets: Assets, hasOwnHouse: boolean) =>
      AssetsFormValidationFactory('storedValue', hasOwnHouse).isValidSync(assets)
  ),
  [FormDataSection.liabilities]: createSelector(
    (state: ApplicationState) => state.formsData[FormDataSection.liabilities],
    getIsHousingSituationMortgaged,
    (liabilities: Liabilities, hasMortgage: boolean) =>
      LiabilitiesFormValidationFactory('storedValue', hasMortgage).isValidSync(liabilities)
  ),
};

/** isSectionValid entries assigned to const to keep consistent order,
 *  as it's not guaranteed (well, it almost is...) by Object.entries
 */
const sectionValidityEntries = Object.entries(isSectionValid) as [FormDataSection, Selector<any, boolean>][];
const sectionValiditySectionNameList: FormDataSection[] = sectionValidityEntries.map(([sectionName]) => sectionName);
const sectionValiditySelectorsList = sectionValidityEntries.map(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ([_, isValidSelector]) => isValidSelector
);

export const areAllSectionsValid = createSelector(
  areSectionsActive,
  ...sectionValiditySelectorsList,
  (sectionsActiveMap: Record<FormDataSection, boolean>, ...sectionsValidityList: boolean[]) =>
    sectionsValidityList.every(
      (isValid: boolean, sectionIndex) => isValid || !sectionsActiveMap[sectionValiditySectionNameList[sectionIndex]]
    )
);
