import { ContactInformationCurrentAddress, CustomerRole } from '../redux/reducers/FormsData/formsData.model';
import { BasePersonalCredentialsValidators } from './PersonalDataForm.validation';
import { BaseAddressFieldsValidatorMixin } from './BaseAddressFieldset.validator';
import yup from './yup-extended';
import i18n from '../i18n/i18n';

const requiredMessage = i18n.t('validation:required');
const requiredSelectMessage = i18n.t('validation:requiredSelect');

const numericOnlyMessage = i18n.t('validation:numericOnly');
const invalidMailMessage = i18n.t('validation:invalidMail');

const lettersOnlyMessage = i18n.t('validation:lettersOnly');

const lettersWithSpecialRegEx = /^[.\p{L}][.\p{L} '-]+$/u;

const isAddressSame = (address1: ContactInformationCurrentAddress, address2: ContactInformationCurrentAddress) => {
  return (
    address1.street &&
    address1.street === address2.street &&
    address1.houseNumber &&
    address1.houseNumber === address2.houseNumber &&
    (address1.optionalLine || null) === (address2.optionalLine || null) &&
    address1.city &&
    address1.city === address2.city &&
    address1.zipCode &&
    address1.zipCode === address2.zipCode &&
    address1.state &&
    address1.state === address2.state
  );
};

const referenceContactValidation = (otherAddress: ContactInformationCurrentAddress) =>
  yup.object().shape({
    ...BasePersonalCredentialsValidators,
    firstName: yup
      .string()
      .required(requiredMessage)
      .min(2, i18n.t('validation:minLength', { value: 2 }))
      .max(35, i18n.t('validation:maxLength', { value: 35 }))
      .matches(lettersWithSpecialRegEx, lettersOnlyMessage),
    middleNames: yup
      .string()
      .transform((val) => val || null)
      .nullable()
      .notRequired()
      .min(2, i18n.t('validation:minLength', { value: 2 }))
      .max(35, i18n.t('validation:maxLength', { value: 35 }))
      .matches(lettersWithSpecialRegEx, lettersOnlyMessage),
    lastName: yup
      .string()
      .required(requiredMessage)
      .min(2, i18n.t('validation:minLength', { value: 2 }))
      .max(35, i18n.t('validation:maxLength', { value: 35 }))
      .matches(lettersWithSpecialRegEx, lettersOnlyMessage),
    addressSameAsPrimary: yup.mixed().test('sameAddress', i18n.t('validation:addressSameAsPrimary'), function () {
      return !isAddressSame(otherAddress, this.parent.addresses[0]);
    }),
    role: yup.string(),
    addresses: yup
      .array()
      .required(requiredMessage)
      .of(
        yup
          .object()
          .required(requiredMessage)
          .shape({
            ...BaseAddressFieldsValidatorMixin,
          })
      )
      .required(requiredMessage),
    contactData: yup.object({
      mobilePhoneCountryCode: yup.string().required(requiredSelectMessage),
      mobilePhoneNumber: yup
        .string()
        .required(requiredMessage)
        .min(9, i18n.t('validation:minLength', { value: 9 }))
        .max(11, i18n.t('validation:maxLength', { value: 11 }))
        .matches(/^[0-9+]+$/, numericOnlyMessage),
      email: yup.string().required(requiredMessage).email(invalidMailMessage),
    }),
  });

export const ReferenceContactsFormValidationFactory = (otherAddress: ContactInformationCurrentAddress) =>
  yup.object({
    customerRelations: yup.object({
      [CustomerRole.REFERENCE_CONTACT_1]: referenceContactValidation(otherAddress),
      [CustomerRole.REFERENCE_CONTACT_2]: referenceContactValidation(otherAddress),
      sameAddresses: yup.mixed().test('sameAddresses', i18n.t('validation:addressesSame'), function () {
        return !isAddressSame(
          this.parent[CustomerRole.REFERENCE_CONTACT_1].addresses[0],
          this.parent[CustomerRole.REFERENCE_CONTACT_2].addresses[0]
        );
      }),
    }),
  });
