import _axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError, isRetryableError } from 'axios-retry';
import { CONNECTION_TIMEOUT } from '../../config';

export const axios = _axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
  timeout: CONNECTION_TIMEOUT,
});

axiosRetry(axios, {
  retries: 2,
  retryDelay: (retryCount) => (retryCount === 1 ? 1_000 : 3_000),
  retryCondition: (e) => {
    return (
      isNetworkOrIdempotentRequestError(e) ||
      !!(e.config?.method && e.config.method.toUpperCase() === 'PATCH' && isRetryableError(e))
    );
  },
});
