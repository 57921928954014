import { ApplicationState } from '../../ApplicationState';
import { CustomActionType } from './custom.actions';

export interface CustomState {
  scrive: {
    scriveIdentId: string | null;
    privacyPolicyEsign: {
      url: string | null;
    };
  };
}

const initialState = {
  amlCheck: {
    status: null,
  },
  scrive: {
    scriveIdentId: null,
    privacyPolicyEsign: {
      url: null,
    },
  },
};

export const getScriveIdentId = (state: ApplicationState) => state.custom?.scrive?.scriveIdentId ?? null;

export const getScrivePrivacyPolicyEsignUrl = (state: ApplicationState) =>
  state.custom?.scrive?.privacyPolicyEsign?.url ?? null;

export default function (state = initialState, action: { type: CustomActionType; payload?: any }) {
  switch (action.type) {
    case CustomActionType.SET_SCRIVE_ID: {
      return {
        ...state,
        scrive: {
          ...state.scrive,
          scriveIdentId: action.payload,
        },
      };
    }
    case CustomActionType.SET_PRIVACY_POLICY_ESIGN: {
      return {
        ...state,
        scrive: {
          ...state.scrive,
          privacyPolicyEsign: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
