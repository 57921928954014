import { VehicleData } from './vehicleData.model';
import { VehicleDataAction, VehicleDataActionType } from './vehicleData.actions';

const vehicleDataInitialState: VehicleData = {
  model: {
    description: '',
    colorExterior: '',
    accessories: [
      {
        type: '',
        name: '',
      },
    ],
    vehicleImageUrl: '',
    manufacturer: '',
    transmissionType: '',
    descriptionLong: '',
  },
  tradeState: null,
  retailPriceAmount: 0,
};

// eslint-disable-next-line default-param-last
export default function (state = vehicleDataInitialState, action: VehicleDataAction): VehicleData {
  switch (action.type) {
    case VehicleDataActionType.SET:
      return {
        ...state,
        ...action.payload,
      };
    case VehicleDataActionType.GET:
      return state;
    default:
      return state;
  }
}
