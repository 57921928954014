import { FlauRoute } from '../../../routes';
import { Session } from '../../redux/reducers/Session/session.model';
import { axios } from '../index';
import { DataStorageData } from '../../redux/dataStorage.model';

const { REACT_APP_WEBSITE_BRAND, REACT_APP_API_GATEWAY } = process.env;

export interface StartScriveIdentResponse {
  scriveIdentAccessUrl: string;
  scriveIdentId: string;
}

export enum ScriveStatus {
  new = 'new',
  started = 'started',
  complete = 'complete',
  failed = 'failed',
}

export interface ScriveStatusCheckResponse {
  redirectUrl: string;
  scriveIdentId: string;
  status: ScriveStatus;
}

export function startScriveIdentification(session: Session) {
  const url = `${REACT_APP_API_GATEWAY}/scrive/${REACT_APP_WEBSITE_BRAND}/start-scrive/${session.id}/${session.userId}`;
  return axios
    .post<StartScriveIdentResponse>(
      url,
      {
        redirectUrl: `${window.location.origin}${FlauRoute.legitimationCallback}`,
      },
      {
        headers: {
          Authorization: session.token as string,
        },
      }
    )
    .then((res) => res.data);
}

export function verifyScriveStatus(session: Session, scriveIdentId: string) {
  const url = `${REACT_APP_API_GATEWAY}/scrive/${REACT_APP_WEBSITE_BRAND}/verifications/${session.id}/${session.userId}/ident-transaction/${scriveIdentId}`;
  return axios
    .post<{ data: DataStorageData; scriveStatus: ScriveStatus; redirectUrl: string; scriveIdentId: string }>(url, {
      headers: {
        Authorization: session.token as string,
      },
    })
    .then((res) => res.data);
}

export function requestScrivePrivacyPolicyEsign(session: Session, continuationUrls: { success: string; fail: string }) {
  const url = `${REACT_APP_API_GATEWAY}/scrive/${REACT_APP_WEBSITE_BRAND}/start-policy-esign/${session.id}/${session.userId}`;
  return axios
    .post<DataStorageData>(
      url,
      {
        continuationUrls: continuationUrls,
      },
      {
        headers: {
          Authorization: session.token as string,
        },
      }
    )
    .then((res) => res.data);
}
