import { getBrandName } from '../../config';

export interface LogoSrc {
  logoDefault: string;
  logoSmall: string;
}

const chooseVwBrand = (brand: string): string => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel';
    case 'vw6':
      return 'vw6';
    case 'vwcv':
      return 'vw';
    default:
      return brand;
  }
};

export const getStyles = (brand = 'vw6'): any => {
  return import(
    `@vwfs-bronson/bronson-${brand.includes('vw') ? chooseVwBrand(brand) : brand}/dist/css/style.min.css`
  ).then(() => import('../../styles.scss'));
};

export const getLogosSrc = async (brandName: string): Promise<LogoSrc> => {
  let logoDefault;
  let logoSmall;

  switch (brandName) {
    case 'skoda':
    case 'vw':
      logoDefault = await import(`@vwfs-bronson/bronson-${brandName}/dist/img/logo.png`);
      logoSmall = await import(`@vwfs-bronson/bronson-${brandName}/dist/img/logo-small-screen.png`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
    case 'cupra':
    case 'vw6':
    case 'seat':
    case 'audi':
      logoDefault = await import(`@vwfs-bronson/bronson-${brandName}/dist/img/logo.svg`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      };
    case 'vwfs':
      logoDefault = await import(`@vwfs-bronson/bronson-${chooseVwBrand(brandName)}/dist/img/logo.svg`);
      logoSmall = await import(`@vwfs-bronson/bronson-${chooseVwBrand(brandName)}/dist/img/logo-small-screen.svg`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
    case 'vwcv':
    default:
      logoDefault = await import(`@vwfs-bronson/bronson-${chooseVwBrand(brandName)}/dist/img/logo.png`);
      logoSmall = await import(`@vwfs-bronson/bronson-${chooseVwBrand(brandName)}/dist/img/logo-small-screen.png`);

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
  }
};

export function shouldUseVerticalBar(brandName: string = getBrandName()): boolean {
  return ['vw', 'vwcv', 'vwfs'].includes(brandName);
}
