import React, { useContext } from 'react';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { FormField } from '../../../../../components';
import {
  DatePicker,
  Heading,
  Input,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
  Select,
} from '@vwfs-bronson/bronson-react';
import { Field, useFormikContext } from 'formik';
import { TrustAgentType } from '../../../../../services/redux/dataStorage.model';
import { useTranslation } from 'react-i18next';
import { EmptyOption } from '../../../formUtils';
import { mobileCountryCodeEntries } from '../../../../../services/utils';
import { BaseContactInformationFieldset } from './BaseContactInformationFieldset';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import {
  CustomerRole,
  FormDataSection,
  PersonalData,
} from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { formsDataInitialState } from '../../../../../services/redux/reducers/FormsData/formsData.reducer';
import { DATE_FORMAT } from '../../../../../config';
import { countryCodeOptions } from './BaseAddressFieldset';

const trustAgentTypeOptions = [TrustAgentType.INDIVIDUAL, TrustAgentType.COMPANY];

interface Props {
  namePrefix: string;
}

export const TrusteeFieldset = (props: Props): JSX.Element => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  const formikProps = useFormikContext<PersonalData>();

  React.useEffect(() => {
    if (!formikProps.values.customerRelations?.[CustomerRole.TRUST]) {
      formikProps.setFieldValue(namePrefix.replace(/\.$/, ''), {
        ...(formsDataInitialState[FormDataSection.personalData]?.customerRelations?.[CustomerRole.TRUST] || {}),
      });
    }
  }, [formikProps.values?.customerRelations?.[CustomerRole.TRUST]]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const dateInputs = document.querySelectorAll('.c-datepicker input');
      if (dateInputs?.length) {
        for (const dateInput of dateInputs) {
          dateInput.setAttribute('autocomplete', 'no');
        }
      }
    }, 1_000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const isReadonly = useContext(IsReadonlyFormContext);

  return (
    <>
      <Heading level={5}>{t('formsData:formSections:trustInformation:label')}</Heading>
      {!isReadonly && <Paragraph>{t('formsData:formSections:trustInformation:trustHint')}</Paragraph>}
      <Fieldset>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">
              <FormField
                name={`${namePrefix}name`}
                type="input"
                labelText={t('formsData:formSections:trustInformation:fields:name:label')}
                notion
                render={(fieldProps) => <Input {...fieldProps} />}
              />
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Layout>
          <Layout.Item default="1/3">
            <FormField
              name={`${namePrefix}contactData.mobilePhoneCountryCode`}
              type="select"
              labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneCountryCode:label')}
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {mobileCountryCodeEntries.map(([code, label]) => (
                    <Select.Item key={code} value={label}>
                      {code} {label}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${namePrefix}contactData.mobilePhoneNumber`}
              type="input"
              labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneNumber:label')}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${namePrefix}contactData.email`}
              type="input"
              labelText={t('formsData:formSections:contactInformation:fields:email:label')}
              notion
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${namePrefix}id`}
              type="input"
              labelText={t('formsData:formSections:trustInformation:fields:id:label')}
              notion={true}
              render={(fieldProps) => <Input {...fieldProps} />}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${namePrefix}established`}
              type="select"
              labelText={t('formsData:formSections:companyInformation:fields:established:label')}
              notion
              render={(fieldProps) => (
                <Select {...fieldProps}>
                  <EmptyOption />
                  {countryCodeOptions.map((option) => (
                    <Select.Item key={option.value} value={option.value}>
                      {option.viewValue}
                    </Select.Item>
                  ))}
                </Select>
              )}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">
            <FormField
              name={`${namePrefix}foundationDate`}
              type="input"
              labelText={t('formsData:formSections:companyInformation:fields:foundationDate:label')}
              notion
              render={(fieldProps) => (
                <DatePicker
                  {...fieldProps}
                  dateFormat={DATE_FORMAT}
                  locale="en"
                  onChange={(val: string) => {
                    formikProps.setFieldValue(`${namePrefix}foundationDate`, val, true);
                  }}
                  onBlur={(val: string) => {
                    formikProps.setFieldValue(`${namePrefix}foundationDate`, val);
                    formikProps.handleBlur(`${namePrefix}foundationDate`);
                  }}
                />
              )}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>
      {!isReadonly && <Paragraph>{t('formsData:formSections:trustInformation:officeAddressHint')}</Paragraph>}
      <BaseContactInformationFieldset
        addressNamePrefix={namePrefix}
        contactDataNamePrefix={`${namePrefix}contactData.`}
        formikProps={formikProps}
        isAddressOnly={true}
        noPreviousAddress={true}
      />
      <Fieldset className="u-pt-large u-pb u-border-t">
        <Layout>
          <Layout.Item default="1/1" s="1/1">
            <FormField
              id="trustAgentType"
              type="other"
              labelText={t('formsData:formSections:trustInformation:fields:trustAgentType:label')}
              getReadonlyValue={(val: string) =>
                t(`formsData:formSections:trustInformation:fields:trustAgentType:options:${val}`)
              }
              notion
              name={`${namePrefix}trustAgentType`}
              render={() => (
                <>
                  <RadioButtonGroup labelledby={`${namePrefix}trustAgentType`}>
                    {trustAgentTypeOptions.map((option) => (
                      <Field
                        key={option}
                        as={RadioButton}
                        type="radio"
                        name={`${namePrefix}trustAgentType`}
                        value={option}
                      >
                        {t(`formsData:formSections:trustInformation:fields:trustAgentType:options:${option}`)}
                      </Field>
                    ))}
                  </RadioButtonGroup>
                </>
              )}
            />
          </Layout.Item>
        </Layout>
      </Fieldset>
    </>
  );
};
