export const brandNames: { [key: string]: string } = {
  vw: 'Volkswagen',
  vw6: 'Volkswagen',
  vwfs: 'Volkswagen',
  vwcv: 'Volkswagen',
  skoda: 'ŠKODA',
  seat: 'SEAT',
  audi: 'AUDI',
  cupra: 'CUPRA',
};

export const CONNECTION_TIMEOUT = 60000;

export const getBrandName = (): string =>
  process.env.REACT_APP_WEBSITE_BRAND === 'vw5' || !process.env.REACT_APP_WEBSITE_BRAND
    ? 'vw'
    : process.env.REACT_APP_WEBSITE_BRAND;

export const DATE_FORMAT = 'd-m-Y';
export const DATE_VALIDATION_FORMAT = 'd-M-yyyy';

export const getCupraReturnUrl = (status: 'success' | 'failure' = 'success') => {
  if (status === 'failure') {
    return process.env.REACT_APP_CUPRA_RETURN_URL_FAILURE as string;
  }
  return process.env.REACT_APP_CUPRA_RETURN_URL_SUCCESS as string;
};
