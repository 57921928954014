import { Button, ErrorMessage } from '@vwfs-bronson/bronson-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IsReadonlyFormContext from './isReadonlyFormContext';

interface Props {
  errorMessage?: string | null;
  onClick?: (e: MouseEvent) => void;
}
const ContinueBtn = (props: Props) => {
  const { errorMessage, onClick } = props;

  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);
  if (isReadonly) {
    return null;
  }
  return (
    <>
      {errorMessage ? (
        <ErrorMessage className="mandatory-fields-missing" testId="mandatory-fields-missing">
          {errorMessage}
        </ErrorMessage>
      ) : null}
      <div className="submit-button u-text-center u-mt-xlarge u-mb-xlarge">
        <Button type="submit" className="continue-btn" onClick={onClick}>
          {t('formPage:next')}
        </Button>
      </div>
    </>
  );
};

export default ContinueBtn;
