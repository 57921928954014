import { InfoIcon, Input, Paragraph } from '@vwfs-bronson/bronson-react';
import { Formik, FormikProps } from 'formik';
import React, { createRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnySchema } from 'yup';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { formatCurrency } from '../../../../../services/common/format';
import {
  IncomeFormValidationFactory,
  IncomeFormCoApplicantValidationFactory,
  isNetIncomeAmountRequired,
} from '../../../../../services/validators/IncomeForm.validation';
import { FORM_EMPTY_VALUE, numberInputProps } from '../../../formUtils';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import ContinueBtn from '../../../ContinueBtn';
import { saveIncome } from '../../../../../services/redux/reducers/Expenses/expenses.actions';
import { ExpensesDataSection, Income } from '../../../../../services/redux/reducers/Expenses/expenses.model';
import { EmploymentStatus, PartnerType } from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';
import {
  getCustomerPartnerType,
  getEmploymentType,
} from '../../../../../services/redux/reducers/FormsData/formsData.selectors';

export interface IncomeFormProps {
  expensesData: Income;
  isPrivate: boolean;
  setFormData: (expensesData: Income) => void;
  employmentType: EmploymentStatus | null;
  isCoApplicant: boolean;
}

const fieldsConfig: {
  name: string;
  isRequired: (employmentStatus: EmploymentStatus) => boolean;
  isPrivateOnly?: boolean;
  tooltip?: boolean;
  notCoApplicant?: boolean;
}[] = [
  {
    name: 'financialDisclosure.monthlyIncome.netIncomeAmount',
    isRequired: (employmentStatus: EmploymentStatus) => isNetIncomeAmountRequired(employmentStatus as EmploymentStatus),
  },
  {
    name: 'financialDisclosure.monthlyIncome.overtime',
    isPrivateOnly: true,
    isRequired: () => false,
  },
  {
    name: 'financialDisclosure.monthlyIncome.commissions',
    isPrivateOnly: true,
    isRequired: () => false,
  },
  {
    name: 'financialDisclosure.monthlyIncome.bonus',
    isPrivateOnly: true,
    isRequired: () => false,
  },
  {
    name: 'financialDisclosure.monthlyIncome.rental',
    isRequired: () => false,
    tooltip: true,
  },
  {
    name: 'financialDisclosure.monthlyIncome.benefits',
    isRequired: () => false,
  },
  {
    name: 'financialDisclosure.annualInvestedIncome',
    isRequired: () => false,
    tooltip: true,
  },
  {
    name: 'financialDisclosure.monthlyIncome.netHouseholdIncomeAmount',
    isRequired: () => false,
    notCoApplicant: true,
  },
  {
    name: 'financialDisclosure.monthlyIncome.additionalIncomeAmount',
    isRequired: () => false,
    tooltip: true,
  },
];

export const IncomeFormToConnect: React.FC<IncomeFormProps> = ({
  expensesData,
  isPrivate,
  employmentType,
  isCoApplicant,
  setFormData,
}) => {
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);
  const formikRef = createRef<FormikProps<Income>>();

  const [validationSchema, setValidationSchema] = React.useState<AnySchema | null>(null);
  React.useEffect(() => {
    setValidationSchema(
      isCoApplicant
        ? IncomeFormCoApplicantValidationFactory(employmentType)
        : IncomeFormValidationFactory(employmentType)
    );
  }, [employmentType]);

  const [fieldsToDisplay, setFieldsToDisplay] = React.useState(fieldsConfig);
  React.useEffect(() => {
    setFieldsToDisplay(
      fieldsConfig
        .filter((field) => !isCoApplicant || !field.notCoApplicant)
        .filter((field) => isPrivate || !field.isPrivateOnly)
    );

    if (!isPrivate) {
      fieldsToDisplay
        .filter((field) => field.isPrivateOnly)
        .forEach((field) => {
          formikRef.current?.setFieldValue(field.name, FORM_EMPTY_VALUE, true);
        });
    }
  }, [isPrivate, isCoApplicant]);

  return (
    <>
      {!isReadonly && <Paragraph>{t('formsData:formSections:income:enterDetailsHint')}</Paragraph>}

      <Formik
        enableReinitialize
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={validationSchema}
        initialValues={expensesData}
        onSubmit={(values) => {
          setFormData(values);
        }}
      >
        {() => {
          return (
            <Form noValidate role="form">
              <Fieldset>
                <Layout>
                  {fieldsToDisplay.map((field) => (
                    <Layout.Item default="1/2" s="1/1" key={field.name}>
                      <FormField
                        name={field.name}
                        type="input"
                        labelText={t(
                          `formsData:formSections:income:fields:${field.name.split('.').slice(-1)[0]}:label`
                        )}
                        getReadonlyValue={formatCurrency}
                        infoIcon={
                          field.tooltip ? (
                            <InfoIcon icon="semantic-info">
                              {t(`formsData:formSections:income:fields:${field.name.split('.').slice(-1)[0]}:tooltip`)}
                            </InfoIcon>
                          ) : null
                        }
                        notion={field.isRequired(employmentType as EmploymentStatus) ?? false}
                        render={(fieldProps) => (
                          <>
                            <Input
                              {...fieldProps}
                              {...numberInputProps}
                              type="number"
                              min={0}
                              addonText={t('app:currencySign')}
                            />
                          </>
                        )}
                      />
                    </Layout.Item>
                  ))}
                </Layout>
              </Fieldset>
              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: ApplicationState): Partial<IncomeFormProps> => ({
  expensesData: state.expensesData[ExpensesDataSection.income],
  isPrivate: getCustomerPartnerType(state) === PartnerType.PRIVATE,
  employmentType: getEmploymentType(state),
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<IncomeFormProps> => ({
  setFormData: (payload: Income) => {
    dispatch(saveIncome(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IncomeFormToConnect);
