import { Session } from '../../redux/reducers/Session/session.model';
import { axios } from '../index';
import { UploadFileBody } from '../../redux/reducers/Upload/upload.model';

const { REACT_APP_WEBSITE_BRAND, REACT_APP_API_GATEWAY } = process.env;

export function uploadFileToMediaStorage(file: UploadFileBody, session: Session) {
  const url = `${REACT_APP_API_GATEWAY}/files/${REACT_APP_WEBSITE_BRAND}/upload/${session.id}/${session.userId}`;
  return axios
    .post(url, file, {
      headers: {
        Authorization: session.token as string,
      },
    })
    .then((res) => res.data);
}

export function deleteFileFromMediaStorage(fileId: string, session: Session) {
  const url = `${REACT_APP_API_GATEWAY}/files/${REACT_APP_WEBSITE_BRAND}/delete-file/${session.id}/${session.userId}/${fileId}`;
  return axios.delete(url, {
    headers: {
      Authorization: session.token as string,
    },
  });
}

export function getAllFilesFromMediaStorage(session: Session) {
  const url = `${REACT_APP_API_GATEWAY}/files/${REACT_APP_WEBSITE_BRAND}/get-all-files/${session.id}/${session.userId}`;
  return axios
    .get(url, {
      headers: { Authorization: session.token as string },
    })
    .then((res) => res.data);
}
