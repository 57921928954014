import { CoApplicantData, DataStorageData } from '../../dataStorage.model';
import { UserType } from '../Session/session.model';
import { Transaction, TransactionStatus } from './transaction.model';
import { CustomerRole } from '../FormsData/formsData.model';
import { FORM_EMPTY_VALUE } from '../../../../screens/FormPage/formUtils';

export const getTransactionDataFromDataStorage = (dataStorage: DataStorageData): Transaction => {
  const userTransactions = {
    [UserType.PRIMARY]: dataStorage.customerData.transaction,
  };
  for (const relation of Object.values(dataStorage.customerData?.customerRelations || [])) {
    if (relation.role === CustomerRole.CO_APPLICANT) {
      userTransactions[CustomerRole.CO_APPLICANT] = (relation as CoApplicantData).transaction;
    }
  }
  return {
    brand: dataStorage?.transaction.brand || FORM_EMPTY_VALUE,
    product: dataStorage?.transaction.product || FORM_EMPTY_VALUE,
    status: dataStorage?.transaction.status || (FORM_EMPTY_VALUE as TransactionStatus),
    storefrontCallbackURL: dataStorage?.transaction.storefrontCallbackURL || FORM_EMPTY_VALUE,
    userTransactions: userTransactions,
    multiApplicantJourney: dataStorage?.transaction?.multiApplicantJourney,
  };
};
