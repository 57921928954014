import { Dispatch } from 'redux';
import { ApplicationState } from '../../ApplicationState';
import { getSession } from '../Session/session.selectors';
import { DataLoadErrorType, setDataLoadError, setIsDataLoading } from '../UI/UI.reducer';
import { Session } from '../Session/session.model';
import { SetDataActionCreator } from '../../utils';
import { MediaStorageFile, UploadFileBody } from './upload.model';
import {
  deleteFileFromMediaStorage,
  getAllFilesFromMediaStorage,
  uploadFileToMediaStorage,
} from '../../../api/mediaStorage/mediaStorage.service';

export enum UploadActionType {
  SAVE_FILE_METADATA = 'upload/SAVE_FILE_METADATA',
  DELETE_FILE_METADATA = 'upload/DELETE_FILE_METADATA',
  SAVE_FILES = 'upload/SAVE_FILES',
}

const setFileMetadata: SetDataActionCreator<UploadActionType.SAVE_FILE_METADATA, MediaStorageFile> = (payload) => {
  return { type: UploadActionType.SAVE_FILE_METADATA, payload };
};

const removeFileMetaData: SetDataActionCreator<UploadActionType.DELETE_FILE_METADATA, any> = (payload) => {
  return { type: UploadActionType.DELETE_FILE_METADATA, payload };
};

export const setUploadedFiles: SetDataActionCreator<UploadActionType.SAVE_FILES, MediaStorageFile[]> = (payload) => {
  return { type: UploadActionType.SAVE_FILES, payload };
};

export const saveFile =
  (fileBody: UploadFileBody): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    const session = getSession(state);
    dispatch(setIsDataLoading(true));

    return uploadFileToMediaStorage(fileBody, session as Session)
      .then((res) => {
        const { file } = res;
        const fileResponse: MediaStorageFile = {
          document: file.tags[0],
          ...file,
        };
        dispatch(setFileMetadata(fileResponse));
      })
      .catch((e) => dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e })))
      .finally(() => dispatch(setIsDataLoading(false)));
  };

export const removeFile =
  (id: string): any =>
  (dispatch: Dispatch, getState: () => ApplicationState) => {
    const state = getState();
    const session = getSession(state);
    dispatch(setIsDataLoading(true));

    return deleteFileFromMediaStorage(id, session as Session)
      .then(() => dispatch(removeFileMetaData(id)))
      .catch((e) => dispatch(setDataLoadError({ type: DataLoadErrorType.SAVE_ERROR, error: e })))
      .finally(() => dispatch(setIsDataLoading(false)));
  };

export const setAllFiles = (): any => (dispatch: Dispatch, getState: () => ApplicationState) => {
  const state = getState();
  const session = getSession(state);
  dispatch(setIsDataLoading(true));

  return getAllFilesFromMediaStorage(session as Session)
    .then((res) => dispatch(setUploadedFiles(res.files)))
    .catch((e) => dispatch(setDataLoadError({ type: DataLoadErrorType.LOAD_ERROR, error: e })))
    .finally(() => dispatch(setIsDataLoading(false)));
};
