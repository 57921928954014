import { useContext } from 'react';
import * as React from 'react';

import { DescriptionList, FormField as BronsonReactFormField, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { Field, FieldProps, FormikProps } from 'formik';
import get from 'lodash/get';
import { FormFieldIdPrefixContext } from '../../screens/FormPage/formUtils';
import IsReadonlyFormContext from '../../screens/FormPage/isReadonlyFormContext';

interface ComponentProps {
  render: (props: FieldProps) => React.ReactNode;
  name: string;
  labelElement?: string;
  labelText?: string;
  readonlyLabelText?: string;
  getReadonlyValue?: (value: any) => string;
  hint?: string;
  infoIcon?: React.ReactElement | null;
  notion: boolean;
  type: string;
  className?: string;
  id?: string;
}

const FormField: React.FC<ComponentProps> = function ({
  render,
  name,
  labelElement,
  labelText,
  readonlyLabelText,
  getReadonlyValue,
  hint,
  infoIcon,
  notion,
  type,
  className,
  id,
}) {
  const isReadonly = useContext(IsReadonlyFormContext);
  const idPrefix = useContext(FormFieldIdPrefixContext);

  return (
    <Field name={name}>
      {({ field, form }: { field: FieldProps; form: FormikProps<any> }) => {
        const val = (field as any)?.value;
        const hasVal = !!val || typeof val === 'number';

        return (
          <>
            {isReadonly && (
              <>
                {hasVal ? (
                  <DescriptionList.Group termText={readonlyLabelText ?? labelText} testId={(field as any)?.name}>
                    <DescriptionList.Detail>{getReadonlyValue?.(val) ?? val}</DescriptionList.Detail>
                  </DescriptionList.Group>
                ) : (
                  <></>
                )}
              </>
            )}
            {!isReadonly && (
              <BronsonReactFormField
                className={className}
                id={id}
                labelElement={labelElement || 'label'}
                labelText={labelText}
                labelForId={`${idPrefix}${name}`}
                type={type || 'input'}
                errorMessage={
                  get(form.errors, name) && get(form.touched, name) ? (
                    <ErrorMessage testId={`error-${name}`}>
                      {get(form.touched, name) && get(form.errors, name)}
                    </ErrorMessage>
                  ) : (
                    ''
                  )
                }
                hint={hint}
                notion={notion}
                infoIcon={infoIcon}
              >
                {render({
                  ...field,
                  id: `${idPrefix}${name}`,
                  testId: `input-${name}`,
                  error: !!(get(form.touched, name) && get(form.errors, name)) || null,
                } as FieldProps)}
              </BronsonReactFormField>
            )}
          </>
        );
      }}
    </Field>
  );
};

export default FormField;
