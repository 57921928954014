import { ApplicationState } from '../../ApplicationState';
import { CustomerRole, EntityLegalForm, PartnerType } from '../FormsData/formsData.model';
import { OdsFinancialVerificationChoice, TrustAgentType } from '../../dataStorage.model';

export const getOdsFinancialVerificationChoice = (state: ApplicationState): OdsFinancialVerificationChoice | null => {
  return state.dataStorageRawData?.customerData?.odsFinancialVerificationChoice || null;
};
export const getPrimaryUserPartnerType = (state: ApplicationState): PartnerType | null => {
  return state.dataStorageRawData?.customerData?.partnerType || null;
};
export const getPrimaryUserLegalEntityType = (state: ApplicationState): EntityLegalForm | null => {
  return state.dataStorageRawData?.customerData?.companyData?.entityLegalForm || null;
};
export const getPrimaryUserTrustAgentType = (state: ApplicationState): TrustAgentType | null => {
  return state.dataStorageRawData?.customerData?.customerRelations?.[CustomerRole.TRUST]?.trustAgentType || null;
};

export const getPrimaryUserEmailAddress = (state: ApplicationState): string | null => {
  return state.dataStorageRawData?.customerData?.contactData?.email || null;
};
