import { Spinner } from '@vwfs-bronson/bronson-react';
import React, { ReactElement } from 'react';
import {
  EntityLegalForm,
  HousingSituationAu,
  PartnerType,
  FormDataSection,
} from '../../services/redux/reducers/FormsData/formsData.model';
import CompanyDataForm from './FormsData/forms/CompanyData/CompanyDataForm';
import AboutYouForm from './FormsData/forms/PersonalData/PersonalDataForm';
import ContactInformationForm from './FormsData/forms/ContactInformation/ContactInformationForm';
import ReferenceContactsForm from './FormsData/forms//ReferenceContacts/ReferenceContactsForm';

import { ExpensesDataSection } from '../../services/redux/reducers/Expenses/expenses.model';
import IncomeForm from './Expenses/forms/Income/IncomeForm';
import ExpensesForm from './Expenses/forms/Expenses/ExpensesForm';
import BillsAndLivingExpensesForm from './Expenses/forms/BillsAndLivingExpenses/BillsAndLivingExpensesForm';

interface FormStepSection {
  key: FormDataSection;
  form: ReactElement;
  isDisabled: ({
    partnerType,
    entityLegalForm,
    housingSituation,
  }: {
    partnerType?: PartnerType;
    entityLegalForm?: EntityLegalForm;
    housingSituation: HousingSituationAu;
  }) => boolean;
}
interface ExpensesStepSection {
  key: ExpensesDataSection;
  form: ReactElement;
}

const EmploymentInformationFormLazy = React.lazy(
  () => import('./FormsData/forms/EmploymentInformation/EmploymentInformationForm')
);
const AssetsFormLazy = React.lazy(() => import('./FormsData/forms/Assets/AssetsForm'));
const LiabilitiesFormLazy = React.lazy(() => import('./FormsData/forms/Liabilities/LiabilitiesForm'));

const SuspendedLazyForm: React.FC<{ Component: React.FC }> = ({ Component }) => {
  const FormComponent = Component;
  return (
    <React.Suspense fallback={<Spinner center={true} />}>
      <FormComponent />
    </React.Suspense>
  );
};

export const formDataSections: FormStepSection[] = [
  {
    key: FormDataSection.personalData,
    form: <AboutYouForm />,
    isDisabled: () => false,
  },
  {
    key: FormDataSection.contactInformation,
    form: <ContactInformationForm />,
    isDisabled: () => false,
  },
  {
    key: FormDataSection.referenceContacts,
    form: <ReferenceContactsForm />,
    isDisabled: () => false,
  },
  {
    key: FormDataSection.companyInformation,
    form: <CompanyDataForm />,
    isDisabled: ({ partnerType, entityLegalForm }) => {
      if (!partnerType) {
        return true;
      }
      return partnerType == PartnerType.BUSINESS && !entityLegalForm;
    },
  },
  {
    key: FormDataSection.employmentInformation,
    form: <SuspendedLazyForm Component={EmploymentInformationFormLazy} />,
    isDisabled: () => false,
  },
  {
    key: FormDataSection.assets,
    form: <SuspendedLazyForm Component={AssetsFormLazy} />,
    isDisabled: ({ housingSituation }) => !housingSituation,
  },
  {
    key: FormDataSection.liabilities,
    form: <SuspendedLazyForm Component={LiabilitiesFormLazy} />,
    isDisabled: ({ housingSituation }) => !housingSituation,
  },
];

export const expensesDataSections: ExpensesStepSection[] = [
  {
    key: ExpensesDataSection.income,
    form: <IncomeForm />,
  },
  {
    key: ExpensesDataSection.billsAndLivingExpenses,
    form: <BillsAndLivingExpensesForm />,
  },
  {
    key: ExpensesDataSection.expenses,
    form: <ExpensesForm />,
  },
];
