import { Input, Paragraph } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { analytics } from '../../../../../services/analytics/analytics';
import { formatCurrency } from '../../../../../services/common/format';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';
import { BillsAndLivingExpensesFormValidation } from '../../../../../services/validators/BillsAndLivingExpensesForm.validation';
import ContinueBtn from '../../../ContinueBtn';
import { numberInputProps } from '../../../formUtils';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import {
  BillsAndLivingExpenses,
  ExpensesDataSection,
} from '../../../../../services/redux/reducers/Expenses/expenses.model';
import { saveBillsAndLivingExpenses } from '../../../../../services/redux/reducers/Expenses/expenses.actions';

const fieldsConfig: { name: string }[] = [
  {
    name: 'financialDisclosure.monthlyExpenses.groceries',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.transportation',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.clothing',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.privateHealthcareAmount',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.insuranceAmount',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.phoneInternet',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.vacation',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.sportActivities',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.entertainmentLeisure',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.alcoholTobaccoGambling',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.additionalBillsLivingExpenseAmount',
  },
  {
    name: 'financialDisclosure.monthlyExpenses.additionalExpenseAmount',
  },
];

interface Props {
  formData: BillsAndLivingExpenses;
  setFormData: (formData: BillsAndLivingExpenses) => void;
  isCoAplicant: boolean;
}

export const BillsAndLivingExpensesFormToConnect = ({ formData, setFormData, isCoAplicant }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);

  return (
    <>
      {!isReadonly && (
        <Paragraph>
          {isCoAplicant
            ? t('coApplicantData:formSections:billsAndLivingExpenses:enterDetailsHint')
            : t('formsData:formSections:billsAndLivingExpenses:enterDetailsHint')}
        </Paragraph>
      )}

      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={BillsAndLivingExpensesFormValidation}
        initialValues={formData}
        onSubmit={(values) => {
          setFormData(values);
        }}
      >
        {() => {
          return (
            <Form noValidate role="form">
              <Fieldset>
                <Layout>
                  {fieldsConfig.map((field) => (
                    <Layout.Item default="1/2" s="1/1" key={field.name}>
                      <FormField
                        name={field.name}
                        type="input"
                        labelText={t(
                          `formsData:formSections:billsAndLivingExpenses:fields:${
                            field.name.split('.').slice(-1)[0]
                          }:label`
                        )}
                        getReadonlyValue={formatCurrency}
                        notion
                        render={(fieldProps) => (
                          <>
                            <Input
                              {...fieldProps}
                              {...numberInputProps}
                              type="number"
                              min={0}
                              addonText={t('app:currencySign')}
                            />
                          </>
                        )}
                      />
                    </Layout.Item>
                  ))}
                </Layout>
              </Fieldset>
              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  formData: state.expensesData[ExpensesDataSection.billsAndLivingExpenses],
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormData: (payload: BillsAndLivingExpenses) => {
    dispatch(saveBillsAndLivingExpenses(payload));
    analytics.trackFormSectionOnContinue(ExpensesDataSection.billsAndLivingExpenses);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillsAndLivingExpensesFormToConnect);
