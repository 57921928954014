import { DataStorageData } from '../../dataStorage.model';

enum DataStorageRawDataAction {
  SET_DATA_STORAGE_RAW_DATA = 'dataStorageRawData//SET_DATA_STORAGE_RAW_DATA',
}

export const setDataStorageRawData = (payload: DeepPartial<DataStorageData>) => {
  return { type: DataStorageRawDataAction.SET_DATA_STORAGE_RAW_DATA, payload };
};

const initialState = {};

export default function (
  state = initialState,
  action: { type: DataStorageRawDataAction; payload: DeepPartial<DataStorageData> }
): DeepPartial<DataStorageData> {
  switch (action.type) {
    case DataStorageRawDataAction.SET_DATA_STORAGE_RAW_DATA:
      return action.payload;
    default:
      return state;
  }
}
