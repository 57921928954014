export interface Income {
  financialDisclosure: {
    annualInvestedIncome: number;
    monthlyIncome: {
      netIncomeAmount: number;
      overtime: number;
      commissions: number;
      bonus: number;
      rental: number;
      benefits: number;
      netHouseholdIncomeAmount: number;
      additionalIncomeAmount: number;
    };
  };
}

export interface Expenses {
  financialDisclosure: {
    monthlyExpenses: {
      rentAmount: number;
      educationFee: number;
      alimonyAmount: number;
      remoteLivingExpenses: number;
    };
  };
}

export interface BillsAndLivingExpenses {
  financialDisclosure: {
    monthlyExpenses: {
      groceries: number;
      transportation: number;
      clothing: number;
      privateHealthcareAmount: number;
      insuranceAmount: number;
      phoneInternet: number;
      vacation: number;
      sportActivities: number;
      entertainmentLeisure: number;
      alcoholTobaccoGambling: number;
      additionalBillsLivingExpenseAmount: number;
      additionalExpenseAmount: number;
    };
  };
}

export enum ExpensesDataSection {
  income = 'income',
  expenses = 'expenses',
  billsAndLivingExpenses = 'billsAndLivingExpenses',
}

export interface ExpensesData {
  [ExpensesDataSection.income]: Income;
  [ExpensesDataSection.expenses]: Expenses;
  [ExpensesDataSection.billsAndLivingExpenses]: BillsAndLivingExpenses;
}

export const expensesSections: ExpensesDataSection[] = [
  ExpensesDataSection.income,
  ExpensesDataSection.expenses,
  ExpensesDataSection.billsAndLivingExpenses,
];
