import { Session } from '../../redux/reducers/Session/session.model';
import { ExpensesDataSection } from '../../redux/reducers/Expenses/expenses.model';
import { axios } from '../index';

const { REACT_APP_WEBSITE_BRAND, REACT_APP_API_GATEWAY } = process.env;

export function saveExpensesDataToDataStorage(
  data: any,
  session: Session,
  section: ExpensesDataSection
): Promise<void> {
  const url = `${REACT_APP_API_GATEWAY}/journey-data/${REACT_APP_WEBSITE_BRAND}/${session.id}/${session.userId}/${section}`;
  return axios.patch(url, data, {
    headers: {
      Authorization: session.token as string,
    },
  });
}
