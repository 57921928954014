import React, { useContext } from 'react';
import { DescriptionList } from '@vwfs-bronson/bronson-react';
import { Form } from 'formik';
import IsReadonlyFormContext from '../../screens/FormPage/isReadonlyFormContext';

interface Props {
  children: React.ReactNode;
  [key: string]: any;
}
export const ReadonlyAwareForm: React.FC<Props> = ({ children, ...props }) => {
  return useContext(IsReadonlyFormContext) ? (
    <DescriptionList grid>{children}</DescriptionList>
  ) : (
    <Form {...props}>{children}</Form>
  );
};
