export const getBrowserResolutionBreakpointString = (width: number) => {
  if (width <= 480) {
    return 'xs';
  } else if (width > 480 && width <= 720) {
    return 's';
  } else if (width > 720 && width <= 960) {
    return 'm';
  } else if (width > 960 && width <= 1280) {
    return 'l';
  } else if (width > 1280 && width <= 1600) {
    return 'xl';
  } else {
    return 'xxl';
  }
};
