import { PhoneCountryCode } from '@bus/models/dist/enums/general/phone-country-code.enum';
import { ErrorMessage, Heading, Input, Paragraph, Select } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { saveReferenceContacts } from '../../../../../services/redux/reducers/FormsData/formsData.actions';
import {
  ContactInformationCurrentAddress,
  CustomerRole,
  FormDataSection,
  ReferenceContacts,
  Salutation,
} from '../../../../../services/redux/reducers/FormsData/formsData.model';
import { getPrimaryApplicantAddress } from '../../../../../services/redux/reducers/FormsData/formsData.selectors';
import { ReferenceContactsFormValidationFactory } from '../../../../../services/validators/ReferenceContactsForm.validation';
import ContinueBtn from '../../../ContinueBtn';
import { EmptyOption } from '../../../formUtils';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import BaseAddressFieldset from '../Mixins/BaseAddressFieldset';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';

const salutations = [Salutation.MR, Salutation.MRS, Salutation.MISS, Salutation.MS];
const mobileCountryCodeEntries = Object.entries(PhoneCountryCode);

interface Props {
  formData: ReferenceContacts;
  primaryApplicantAddress: ContactInformationCurrentAddress;
  setFormData: (formData: ReferenceContacts) => void;
}

const ReferenceContactsForm: (props: Props) => JSX.Element = (props) => {
  const { formData, primaryApplicantAddress, setFormData } = props;
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);

  const [validationSchema, setValidationSchema] = React.useState(
    ReferenceContactsFormValidationFactory(primaryApplicantAddress)
  );

  React.useEffect(() => {
    setValidationSchema(ReferenceContactsFormValidationFactory(primaryApplicantAddress));
  }, [primaryApplicantAddress]);

  return (
    <>
      {!isReadonly && <Paragraph>{t('formsData:formSections:referenceContacts:formHint1')}</Paragraph>}
      <Formik
        enableReinitialize
        initialValues={formData}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setFormData(values);
        }}
      >
        {(formikProps) => {
          return (
            <Form noValidate>
              {[CustomerRole.REFERENCE_CONTACT_1, CustomerRole.REFERENCE_CONTACT_2].map((role) => (
                <>
                  <Heading level={6} className="u-pt">
                    {t(
                      `formsData:formSections:referenceContacts:${
                        role === CustomerRole.REFERENCE_CONTACT_1
                          ? 'referenceContact1Heading'
                          : 'referenceContact2Heading'
                      }`
                    )}
                  </Heading>
                  <Fieldset>
                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="1/2">
                          <FormField
                            name={`customerRelations.${role}.salutation`}
                            type="select"
                            labelText={t('formsData:formSections:personalData:fields:salutation:label')}
                            getReadonlyValue={(val: string) =>
                              t(`formsData:formSections:personalData:fields:salutation:options:${val}`)
                            }
                            notion
                            render={(fieldProps) => (
                              <Select {...fieldProps}>
                                <EmptyOption />
                                {salutations.map((option) => (
                                  <Select.Item key={option} value={option}>
                                    {t(`formsData:formSections:personalData:fields:salutation:options:${option}`)}
                                  </Select.Item>
                                ))}
                              </Select>
                            )}
                          />
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                    <Layout>
                      <Layout.Item default="1/3" s="1/1">
                        <FormField
                          name={`customerRelations.${role}.firstName`}
                          type="input"
                          labelText={t('formsData:formSections:personalData:fields:firstName:label')}
                          notion
                          render={(fieldProps) => <Input {...fieldProps} />}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/3" s="1/1">
                        <FormField
                          name={`customerRelations.${role}.middleNames`}
                          type="input"
                          labelText={t('formsData:formSections:personalData:fields:middleNames:label')}
                          notion={false}
                          render={(fieldProps) => <Input {...fieldProps} />}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/3" s="1/1">
                        <FormField
                          name={`customerRelations.${role}.lastName`}
                          type="input"
                          labelText={t('formsData:formSections:personalData:fields:lastName:label')}
                          notion
                          render={(fieldProps) => <Input {...fieldProps} />}
                        />
                      </Layout.Item>
                    </Layout>
                  </Fieldset>

                  <BaseAddressFieldset namePrefix={`customerRelations.${role}.addresses[0].`} />
                  {(formikProps.errors?.customerRelations as any)?.sameAddresses && (
                    <ErrorMessage>{t('validation:addressesSame')}</ErrorMessage>
                  )}
                  {(formikProps.errors?.customerRelations as any)?.[role]?.addressSameAsPrimary && (
                    <ErrorMessage>{t('validation:addressSameAsPrimary')}</ErrorMessage>
                  )}

                  <Fieldset>
                    <Layout>
                      <Layout.Item default="1/3">
                        <FormField
                          name={`customerRelations.${role}.contactData.mobilePhoneCountryCode`}
                          type="select"
                          labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneCountryCode:label')}
                          notion
                          render={(fieldProps) => (
                            <Select {...fieldProps}>
                              <EmptyOption />
                              {mobileCountryCodeEntries.map(([code, label]) => (
                                <Select.Item key={code} value={label}>
                                  {code} {label}
                                </Select.Item>
                              ))}
                            </Select>
                          )}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/2" s="1/1">
                        <FormField
                          name={`customerRelations.${role}.contactData.mobilePhoneNumber`}
                          type="input"
                          labelText={t('formsData:formSections:contactInformation:fields:mobilePhoneNumber:label')}
                          notion
                          render={(fieldProps) => <Input {...fieldProps} />}
                        />
                      </Layout.Item>
                      <Layout.Item default="1/2" s="1/1">
                        <FormField
                          name={`customerRelations.${role}.contactData.email`}
                          type="input"
                          labelText={t('formsData:formSections:contactInformation:fields:email:label')}
                          notion
                          render={(fieldProps) => <Input {...fieldProps} />}
                        />
                      </Layout.Item>
                    </Layout>
                  </Fieldset>
                </>
              ))}

              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: ApplicationState): Partial<Props> => ({
  formData: state.formsData[FormDataSection.referenceContacts],
  primaryApplicantAddress: getPrimaryApplicantAddress(state),
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
  setFormData: (payload: ReferenceContacts) => dispatch(saveReferenceContacts(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceContactsForm);
