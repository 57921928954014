import { Input, Paragraph } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormField } from '../../../../../components';
import {
  ReadonlyAwareFieldset as Fieldset,
  ReadonlyAwareForm as Form,
  ReadonlyAwareLayout as Layout,
} from '../../../../../components/ReadonlyAwareForm';
import { formatCurrency } from '../../../../../services/common/format';
import { ExpensesFormValidationFactory } from '../../../../../services/validators/ExpensesForm.validation';
import ContinueBtn from '../../../ContinueBtn';
import { numberInputProps } from '../../../formUtils';
import IsReadonlyFormContext from '../../../isReadonlyFormContext';
import { Expenses, ExpensesDataSection } from '../../../../../services/redux/reducers/Expenses/expenses.model';
import { ApplicationState } from '../../../../../services/redux/ApplicationState';
import { getIsHousingSituationRent } from '../../../../../services/redux/reducers/FormsData/formsData.selectors';
import { saveExpenses } from '../../../../../services/redux/reducers/Expenses/expenses.actions';

const fieldsConfig: { name: string; isRequiredWhenRent: boolean }[] = [
  {
    name: 'financialDisclosure.monthlyExpenses.rentAmount',
    isRequiredWhenRent: true,
  },
  {
    name: 'financialDisclosure.monthlyExpenses.educationFee',
    isRequiredWhenRent: false,
  },
  {
    name: 'financialDisclosure.monthlyExpenses.alimonyAmount',
    isRequiredWhenRent: false,
  },
  {
    name: 'financialDisclosure.monthlyExpenses.remoteLivingExpenses',
    isRequiredWhenRent: false,
  },
];

interface Props {
  formData: Expenses;
  isHousingSituationRent: boolean;
  setFormData: (formData: Expenses) => void;
  isCoAplicant: boolean;
}

export const ExpensesFormToConnect: React.FC<Props> = ({
  formData,
  isHousingSituationRent,
  setFormData,
  isCoAplicant,
}) => {
  const { t } = useTranslation();
  const isReadonly = useContext(IsReadonlyFormContext);

  const [validationSchema, setValidationSchema] = React.useState(ExpensesFormValidationFactory(true));
  React.useEffect(() => {
    setValidationSchema(ExpensesFormValidationFactory(isHousingSituationRent));
  }, [isHousingSituationRent]);

  return (
    <>
      {!isReadonly && (
        <Paragraph>
          {isCoAplicant
            ? t('coApplicantData:formSections:expenses:enterDetailsHint')
            : t('formsData:formSections:expenses:enterDetailsHint')}
        </Paragraph>
      )}

      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={validationSchema}
        initialValues={formData}
        onSubmit={(values) => {
          setFormData(values);
        }}
      >
        {() => {
          return (
            <Form noValidate role="form">
              <Fieldset>
                <Layout>
                  {fieldsConfig.map((field) => (
                    <Layout.Item default="1/2" s="1/1" key={field.name}>
                      <FormField
                        name={field.name}
                        type="input"
                        labelText={t(
                          `formsData:formSections:expenses:fields:${field.name.split('.').slice(-1)[0]}:label`
                        )}
                        getReadonlyValue={formatCurrency}
                        notion={field.isRequiredWhenRent && isHousingSituationRent}
                        render={(fieldProps) => (
                          <>
                            <Input
                              {...fieldProps}
                              {...numberInputProps}
                              type="number"
                              min={0}
                              addonText={t('app:currencySign')}
                            />
                          </>
                        )}
                      />
                    </Layout.Item>
                  ))}
                </Layout>
              </Fieldset>
              <ContinueBtn />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  formData: state.expensesData[ExpensesDataSection.expenses],
  isHousingSituationRent: getIsHousingSituationRent(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormData: (payload: Expenses) => {
    dispatch(saveExpenses(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesFormToConnect);
