import { VehicleData } from './vehicleData.model';
import { SetDataActionCreator } from '../../utils';

export enum VehicleDataActionType {
  SET = 'vehicleData/SET',
  GET = 'vehicleData/GET',
}

interface SetVehicleDataAction {
  type: VehicleDataActionType.SET;
  payload: VehicleData;
}
interface GetVehicleDataAction {
  type: VehicleDataActionType.GET;
  payload: VehicleData;
}

export const setVehicleData: SetDataActionCreator<VehicleDataActionType.SET, VehicleData> = (payload) => {
  return { type: VehicleDataActionType.SET, payload };
};

export type VehicleDataAction = SetVehicleDataAction | GetVehicleDataAction;
