import { CountryCode } from '@bus/models/dist/enums/general/country-code.enum';
import { DATE_FORMAT } from '../../config';
import i18n from '../i18n/i18n';
import {
  AustralianStateKey,
  CustomerRole,
  EntityLegalForm,
  IdentificationType,
  PartnerType,
  Salutation,
} from '../redux/reducers/FormsData/formsData.model';
import yup from './yup-extended';
import { taxIdentificationFieldValidators } from './CompanyDataForm.validation';

import { getIsIndividualBusiness } from '../redux/reducers/FormsData/formsData.helpers';
import { BaseAddressFieldsValidatorMixin } from './BaseAddressFieldset.validator';
import { DriversLicenceDocument, IdentityDocuments, TrustAgentType } from '../redux/dataStorage.model';
import { contactDataFieldsValidation } from './ContactInformationForm.validation';

const requiredMessage = i18n.t('validation:required');
const requiredSelectMessage = i18n.t('validation:requiredSelect');
const lettersOnlyMessage = i18n.t('validation:lettersOnly');

const lettersRegEx = /^[.\p{L}][.\p{L} '-]+$/u;
const lettersNoSpecialRegEx = /^[a-zA-Z](?:[a-zA-Z '-]*[a-zA-Z'])?$/u;
const lettersAndNumbersRegEx = /^[0-9\p{L}]+[.0-9\p{L} '-]*$/u;
const identityDocumentIdRegEx = /^[0-9a-zA-Z]*$/u;
const numericRegex = /^[0-9]+$/u;

const stateToDocumentVerificationNumberRules: Record<
  AustralianStateKey,
  { min: number; max: number; numericOnly?: boolean }
> = {
  ACT: {
    min: 10,
    max: 10,
  },
  NSW: {
    min: 10,
    max: 10,
    numericOnly: true,
  },
  NT: {
    min: 6,
    max: 8,
    numericOnly: true,
  },
  QLD: {
    min: 10,
    max: 10,
  },
  SA: {
    min: 9,
    max: 9,
  },
  TAS: {
    min: 9,
    max: 9,
  },
  VIC: {
    min: 8,
    max: 8,
  },
  WA: {
    min: 8,
    max: 10,
  },
};

export const foundationDateValidator = yup
  .string()
  .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
  .noFutureDate()
  .required(requiredMessage);

const australianDocs = [
  yup
    .object({
      identityDocumentType: yup.string().required(requiredSelectMessage),
    })
    .required(requiredMessage),
  yup.lazy((driverLicense: DriversLicenceDocument) => {
    return yup.object().shape({
      identityDocumentId: yup
        .string()
        .required(requiredMessage)
        .matches(identityDocumentIdRegEx, lettersOnlyMessage)
        .min(6, i18n.t('validation:minLength', { value: 6 }))
        .max(10, i18n.t('validation:maxLength', { value: 10 })),
      identityDocumentType: yup.string().required(requiredSelectMessage),
      expiryDate: yup
        .string()
        .required(requiredMessage)
        .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
        .futureDate(DATE_FORMAT, i18n.t('validation:futureExpiryDate')),
      issuingCountry: yup.mixed<CountryCode>().when('identityDocumentType', {
        is: (value: IdentificationType) =>
          [IdentificationType.PASSPORT, IdentificationType.DRIVER_LICENSE].includes(value),
        then: () =>
          yup.string().required(requiredSelectMessage).oneOf(Object.values(CountryCode), requiredSelectMessage),
        otherwise: (s) => s.notRequired(),
      }),
      issuingState: yup.mixed<AustralianStateKey>().when('issuingCountry', {
        is: (issuingCountry: CountryCode) => issuingCountry === CountryCode.Australia,
        then: () => yup.string().required(requiredSelectMessage),
        otherwise: (s) => s.notRequired(),
      }),
      identityDocumentVerificationNumber: yup.mixed().when('issuingCountry', {
        is: (issuingCountry: CountryCode) => issuingCountry === CountryCode.Australia,
        then: () => {
          const state = driverLicense.issuingState;
          const stateRules = stateToDocumentVerificationNumberRules[state!] || {
            min: 6,
            max: 15,
          };
          return yup
            .string()
            .required(requiredMessage)
            .min(stateRules.min, i18n.t('validation:minLength', { value: stateRules.min }))
            .max(stateRules.max, i18n.t('validation:maxLength', { value: stateRules.max }))
            .matches(stateRules.numericOnly ? numericRegex : lettersAndNumbersRegEx, lettersOnlyMessage);
        },
        otherwise: (s) => s.notRequired(),
      }),
    });
  }),
];
const foreignDocs = [
  ...australianDocs,
  yup.object().shape({
    identityDocumentId: yup.string().required(requiredMessage).matches(lettersAndNumbersRegEx, lettersOnlyMessage),
    identityDocumentType: yup.string().required(requiredSelectMessage),
    expiryDate: yup
      .string()
      .required(requiredMessage)
      .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
      .futureDate(DATE_FORMAT, i18n.t('validation:futureExpiryDate')),
    issuingCountry: yup.mixed<CountryCode>().when('identityDocumentType', {
      is: (value: IdentificationType) =>
        [IdentificationType.PASSPORT, IdentificationType.DRIVER_LICENSE].includes(value),
      then: () => yup.string().required(requiredSelectMessage).oneOf(Object.values(CountryCode), requiredSelectMessage),
      otherwise: (s) => s.notRequired(),
    }),
  }),
];

export const BasePersonalCredentialsValidators = {
  salutation: yup
    .mixed<Salutation>()
    .required(requiredSelectMessage)
    .oneOf(Object.values(Salutation), requiredSelectMessage),
  firstName: yup
    .string()
    .required(requiredMessage)
    .min(2, i18n.t('validation:minLength', { value: 2 }))
    .max(20, i18n.t('validation:maxLength', { value: 20 }))
    .matches(lettersNoSpecialRegEx, lettersOnlyMessage),
  middleNames: yup
    .string()
    .transform((val) => val || null)
    .nullable()
    .notRequired()
    .min(2, i18n.t('validation:minLength', { value: 2 }))
    .max(20, i18n.t('validation:maxLength', { value: 20 }))
    .matches(lettersNoSpecialRegEx, lettersOnlyMessage),
  lastName: yup
    .string()
    .required(requiredMessage)
    .min(2, i18n.t('validation:minLength', { value: 2 }))
    .max(20, i18n.t('validation:maxLength', { value: 20 }))
    .matches(lettersNoSpecialRegEx, lettersOnlyMessage),
};

export const BasePersonalDataValidators = {
  ...BasePersonalCredentialsValidators,
  gender: yup.string().required(requiredSelectMessage),
  maritalStatus: yup.string().required(requiredSelectMessage),
  numberOfDependentChildren: yup
    .number()
    .required(requiredMessage)
    .min(0, requiredMessage)
    .max(99, i18n.t('validation:maxLength', { value: 2 })),
  dateOfBirth: yup
    .string()
    .required(requiredMessage)
    .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
    .maxAge(75, i18n.t('validation:maxAge', { value: 75 }))
    .fullAge(18, i18n.t('validation:fullAge', { value: 18 })),
  identityDocuments: yup.lazy((identityDocuments: IdentityDocuments) => {
    if (identityDocuments[1].issuingCountry && identityDocuments[1].issuingCountry !== CountryCode.Australia) {
      return yup.tuple(foreignDocs as any);
    }
    return yup.tuple(australianDocs as any);
  }),
};

export const CoApplicantPersonalDataFormValidation = yup.object().shape(BasePersonalDataValidators);

export const TrustFieldsValidation = {
  role: yup.string(),
  trustAgentType: yup.string().oneOf(Object.keys(TrustAgentType), requiredMessage).required(requiredMessage),
  name: yup
    .string()
    .forbiddenCharacters(lettersOnlyMessage)
    .matches(lettersRegEx, lettersOnlyMessage)
    .required(i18n.t('validation:required')),
  established: yup.string().required(requiredSelectMessage),
  foundationDate: foundationDateValidator,
  ...taxIdentificationFieldValidators,
  contactData: yup.object().shape(contactDataFieldsValidation),
  addresses: yup
    .array()
    .of(
      yup
        .object()
        .nullable()
        .default(null)
        .shape({
          ...BaseAddressFieldsValidatorMixin,
          residentSinceDate: yup
            .string()
            .dateFormat(DATE_FORMAT, i18n.t('validation:invalidDateFormat'))
            .noFutureDate()
            .test(
              'required',
              requiredMessage,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              function checkFirst(value: string | undefined, ..._: any) {
                return !this.path.includes('addresses[0]') || !!value;
              }
            ),
        })
    )
    .required(requiredMessage),
};

export const PersonalDataFormValidation = yup.object().shape({
  ...BasePersonalDataValidators,
  tradeIn: yup.string().oneOf(['yes', 'no'], requiredMessage).required(requiredMessage),
  partnerType: yup.string().required(requiredMessage),
  customerRelations: yup.object().when('companyData.entityLegalForm', {
    is: EntityLegalForm.TRUST,
    then: () =>
      yup
        .object()
        .shape({
          [CustomerRole.TRUST]: yup.object().shape(TrustFieldsValidation).required(),
        })
        .required(),
    otherwise: () => yup.mixed().nullable(),
  }),
  companyData: yup.object().when('partnerType', {
    is: PartnerType.BUSINESS,
    then: () =>
      yup.object().when('companyData.entityLegalForm', {
        is: (entityLegalForm: EntityLegalForm) => {
          return getIsIndividualBusiness(entityLegalForm);
        },
        then: (schema) =>
          schema.shape({
            entityLegalForm: yup
              .mixed<EntityLegalForm>()
              .required(requiredSelectMessage)
              .oneOf(Object.values(EntityLegalForm), requiredSelectMessage),
            ...taxIdentificationFieldValidators,
          }),
        otherwise: (schema) =>
          schema.shape({
            entityLegalForm: yup
              .mixed<EntityLegalForm>()
              .required(requiredSelectMessage)
              .oneOf(Object.values(EntityLegalForm), requiredSelectMessage),
          }),
      }),
    otherwise: () => yup.mixed().nullable(),
  }),
});
